import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../../../shared/redux.consts";
import {
  MobileVerificationNumberState,
  MobileVerificationNumberStore,
} from "../../../../types/mobile-verification";
import { fetchMobileVerificationNumber } from "./verification-number.thunk";

const initialMobileNumberState: MobileVerificationNumberState = {
  status: FetchStatus.IDLE,
};

export const mobileVerificationNumberSlice = createSlice({
  name: "mobileVerificationNumber",
  initialState: initialMobileNumberState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMobileVerificationNumber.pending, (state, action) => {
        state.mobile = action.meta.arg.mobile;
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchMobileVerificationNumber.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.error = undefined;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "mobile number post failed";
        }
      })
      .addCase(fetchMobileVerificationNumber.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export default mobileVerificationNumberSlice.reducer;

export const selectMobileVerificationNumberState = (
  store: MobileVerificationNumberStore
) => store.mobileVerificationNumberState;
