import React from "react";
import { Action, Container, LogoWisr } from "@wisr/react-ui";
import { Link } from "gatsby";
import globalStyle from "./layout.global.scss";
import localStyle from "./layout.scss";
import iframeStyle from "./iframe.scss";
export const IframeContent: React.FC = () => {
  return (
    <div className="body">
      <div className="spacer"></div>
      <Container>
        <div className="iframe-content">
          <style jsx>{globalStyle}</style>
          <style jsx>{localStyle}</style>
          <style jsx>{iframeStyle}</style>
          <div className="header-wrapper">
            <div className="logo-wrapper">
              <LogoWisr />
            </div>

            <h1>Error</h1>
          </div>

          <Action size="small">
            <h2>
              Click{" "}
              <Link
                to={window.self.location.href}
                title="Web Application"
                target="blank"
              >
                here
              </Link>{" "}
              to access WebApp safely.
            </h2>
          </Action>
        </div>
      </Container>
    </div>
  );
};
