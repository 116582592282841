import { CreditProfile } from "./credit-profile";

export type CreditProfileActions =
  | CreditProfileGetAction
  | CreditProfileGetSuccessAction;

export const CreditProfileGetActionType = "profile/credit/get" as const;
export type CreditProfileGetAction = ReturnType<typeof creditProfileGetAction>;
export function creditProfileGetAction() {
  return {
    type: CreditProfileGetActionType,
  };
}
export const CreditProfileGetSuccessActionType =
  "profile/credit/get/success" as const;
export type CreditProfileGetSuccessAction = ReturnType<
  typeof creditProfileGetSuccessAction
>;
export function creditProfileGetSuccessAction(profile: CreditProfile) {
  return {
    type: CreditProfileGetSuccessActionType,
    profile,
  };
}
