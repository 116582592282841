export type QuotesActions = QuotesGetAction | QuotesGetSuccessAction;

export const QuotesGetActionType = "quote/get" as const;
export type QuotesGetAction = ReturnType<typeof quotesGetAction>;
export function quotesGetAction() {
  return {
    type: QuotesGetActionType,
  };
}
export const QuotesGetSuccessActionType = "quote/get/success" as const;
export type QuotesGetSuccessAction = ReturnType<typeof quotesGetSuccessAction>;
export function quotesGetSuccessAction(quotes: DashQuoteBFF.LoanQuoteDTO[]) {
  return {
    type: QuotesGetSuccessActionType,
    quotes,
  };
}
