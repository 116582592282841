import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import { RateOfferState, RateOfferStore } from "../../types/rate-offer";
import { fetchRateOffer } from "./rate-offer.thunk";

const initialRateOfferState: RateOfferState = {
  status: FetchStatus.IDLE,
  rateOffers: [],
};

export const rateOfferSlice = createSlice({
  name: "rateOffer",
  initialState: initialRateOfferState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRateOffer.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchRateOffer.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.rateOffers = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "rate offer get failed";
        }
      })
      .addCase(fetchRateOffer.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export default rateOfferSlice.reducer;

export const selectRateOfferState = (store: RateOfferStore) =>
  store.rateOfferState;
