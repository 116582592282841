import { HistoryPayment } from "./history-borrowing-widget";

export const MockHistoryPayments: HistoryPayment[] = [
  {
    loanId: 7750,
    effectiveDate: "2020-03-01T00:00:00",
    processingDate: "2020-03-01T00:00:00",
    transactionCode: 121212,
    transactionDescription: "Pink Owl round up",
    debitAmount: 20,
    creditAmount: 50,
    runningBalance: 2000,
    arrears: 100,
    description: "Pink Owl round up",
    addedDate: "2020-03-01T00:00:00",
  },
  {
    loanId: 7750,
    effectiveDate: "2020-03-01T00:00:00",
    processingDate: "2020-03-01T00:00:00",
    transactionCode: 123,
    transactionDescription: "Pink Owl round up",
    debitAmount: 150,
    creditAmount: 100,
    runningBalance: 2000,
    arrears: 100,
    description: "",
    addedDate: "2020-03-01T00:00:00",
  },
  {
    loanId: 7750,
    effectiveDate: "2020-02-01T00:00:00",
    processingDate: "2020-02-01T00:00:00",
    transactionCode: 9900,
    transactionDescription: "Pink Owl round up",
    debitAmount: 75,
    creditAmount: 75,
    runningBalance: 2000,
    arrears: 100,
    description: "Pink Owl round up",
    addedDate: "2020-02-01T00:00:00",
  },
  {
    loanId: 7750,
    effectiveDate: "2020-01-01T00:00:00",
    processingDate: "2020-01-01T00:00:00",
    transactionCode: 9000,
    transactionDescription: "Pink Owl round up",
    debitAmount: 100,
    creditAmount: 100,
    runningBalance: 2000,
    arrears: 100,
    description: "Pink Owl round up",
    addedDate: "2020-01-01T00:00:00",
  },
];

export const MockHistoryState = {
  123: MockHistoryPayments,
};

export const defaultHistoryPayment: HistoryPayment = {
  loanId: 0,
  effectiveDate: "",
  processingDate: "",
  transactionCode: 0,
  transactionDescription: "",
  debitAmount: 0,
  creditAmount: 0,
  runningBalance: 0,
  arrears: 0,
  description: "",
  addedDate: "",
};
