import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppInsightsErrorType } from "../../../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../../../shared/insights/app-insights.utils";
import { CREDIT_API } from "../../../../shared/url.consts";
import { SendMobileNumber } from "../../../../types/mobile-verification";

export const sendMobileNumber = async ({
  mobile,
  wisrCustomerId,
}: SendMobileNumber) => {
  return axios.post<
    | boolean
    | {
        success: boolean;
        error: string | undefined;
      }
  >(`${CREDIT_API}/CustomerAccount/RequestMobileVerification`, {
    wisrCustomerId: wisrCustomerId,
    mobileNumber: mobile,
  });
};

export const sendMobileNumberResponse = async ({
  mobile,
  wisrCustomerId,
}: SendMobileNumber) => {
  try {
    const response = await sendMobileNumber({ mobile, wisrCustomerId });

    if (response.status === 200) {
      return true;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.MOBILE_VERIFICATION
      );
      return false;
    }
  } catch (err: any) {
    sendAppInsightsServerError(
      `error sending mobile number: ${err}`,
      AppInsightsErrorType.MOBILE_VERIFICATION
    );
    throw new Error(err.response.data.errorMessage);
  }
};

export const fetchMobileVerificationNumber = createAsyncThunk(
  "mobile-verification-number/post",
  sendMobileNumberResponse
);
