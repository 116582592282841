import {
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { Epic, ofType } from "redux-observable";
import { concat, from, Observable, of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import axios from "../../../shared/axios.utils";
import { PORTFOLIO_API } from "../../../shared/url.consts";
import { AppAction } from "../../../store";
import { HistoryPayment } from "./history-borrowing-widget";
import {
  HistoryBorrowingGetActionType,
  historyBorrowingGetSuccessAction,
} from "./history-borrowing-widget.actions";
import { sortHistoryRepayments } from "./history-borrowing-widget.utils";

export const GETLOANHISTORY_BORROWING_LOADING =
  "GETLOANHISTORY_BORROWING_LOADING";

export const getHistoryBorrowingWidget: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(HistoryBorrowingGetActionType),
    concatMap((action) =>
      concat(
        of(loadingStartAction(GETLOANHISTORY_BORROWING_LOADING)),
        getHistoryBorrowing(action.loanId, action.duration).pipe(
          map((history) =>
            historyBorrowingGetSuccessAction(
              sortHistoryRepayments(history),
              action.loanId
            )
          )
        ),
        of(loadingSuccessAction(GETLOANHISTORY_BORROWING_LOADING))
      ).pipe(
        catchError((e) =>
          of(loadingFailAction(GETLOANHISTORY_BORROWING_LOADING, e))
        )
      )
    )
  );

function getHistoryBorrowing(
  loanId: number,
  duration: number
): Observable<HistoryPayment[]> {
  return from(
    axios.get<HistoryPayment[]>(
      `${PORTFOLIO_API}/Customer/loans/transactions?loanId=${loanId}&months=${duration}`
    )
  ).pipe(map((res) => res.data));
}
