import { Aggregator } from "./aggregator";

export type AggregatorAction =
  | AggregatorGetAction
  | AggregatorGetSuccessAction
  | AggregatorSetAction;

export const AggregatorGetActionType = "aggregator/get" as const;
export type AggregatorGetAction = ReturnType<typeof aggregatorGetAction>;
export function aggregatorGetAction(aid: string) {
  return {
    type: AggregatorGetActionType,
    aggregatorId: aid,
  };
}

export const AggregatorGetSuccessActionType = "aggregator/get-success" as const;
export type AggregatorGetSuccessAction = ReturnType<
  typeof aggregatorGetSuccessAction
>;

export function aggregatorGetSuccessAction(aggregator: Aggregator) {
  return {
    type: AggregatorGetSuccessActionType,
    aggregator,
  };
}

export const AggregatorSetActionType = "aggregator/set" as const;
export type AggregatorSetAction = ReturnType<typeof aggregatorSetAction>;
export function aggregatorSetAction(bid: string) {
  return {
    type: AggregatorSetActionType,
    bid: bid,
  };
}
