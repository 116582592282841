import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { DASH_API } from "../../shared/url.consts";
import { RateEstimatePrefillResponse } from "../../types/rate-estimate-prefill";

export const returnRateEstimatePrefill = async () => {
  return axios.get<RateEstimatePrefillResponse>(
    `${DASH_API}/rateestimate/prefill`
  );
};

export const returnRateEstimatePrefillResponse = async () => {
  try {
    const response = await returnRateEstimatePrefill();
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.RATE_ESTIMATE_PREFILL
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching rate estimate prefill: ${err}`,
      AppInsightsErrorType.RATE_ESTIMATE_PREFILL
    );
  }
};

export const fetchRateEstimatePrefill = createAsyncThunk(
  "rate-estimate-prefill/get",
  returnRateEstimatePrefillResponse
);
