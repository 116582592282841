import {
  CreditUserProfile,
  CreditUserProfileResponse,
  CreditUserStatusState,
} from "./credit-user";
import {
  CreditUserActionTypes,
  CreditUserGetSuccessAction,
  CreditUserPostAction,
  CreditUserStatusAction,
  CreditUserStatusActionTypes,
  CreditUserUpdateAction,
} from "./credit-user.actions";

export const creditUserReducer = (
  state: CreditUserProfileResponse = {} as CreditUserProfileResponse,
  action:
    | CreditUserGetSuccessAction
    | CreditUserPostAction
    | CreditUserUpdateAction
): CreditUserProfile | any => {
  switch (action.type) {
    case CreditUserActionTypes.GET_SUCCESS:
      return action.creditUser;

    default:
      return state;
  }
};

const creditUserStatusInitialState: CreditUserStatusState = {
  status: null,
};
export const creditUserStatusReducer = (
  state: CreditUserStatusState = creditUserStatusInitialState,
  action: CreditUserStatusAction
): CreditUserStatusState => {
  switch (action.type) {
    case CreditUserStatusActionTypes.POST_SUCCESS:
    case CreditUserStatusActionTypes.POST_FAILED:
    case CreditUserStatusActionTypes.UPDATE_SUCCESS:
    case CreditUserStatusActionTypes.UPDATE_FAILED:
      return {
        ...state,
        status: action.status,
      };
    case CreditUserStatusActionTypes.CLEAR:
      return {
        ...state,
        status: null,
      };
    default:
      return state;
  }
};
