import { IEventTelemetry } from "@microsoft/applicationinsights-web";

export const LOGIN_ERROR = "loginError";
export const SERVER_ERROR = "serverError";
export const USER_ERROR = "userError";

export const LOGIN_ERROR_TYPE = "Unexpected login error";

export const EXCEPTION_ERROR = "wisrException";
export const EXCEPTION_ERROR_EVENT: IEventTelemetry = { name: EXCEPTION_ERROR };

export const USER_NOT_FOUND = "User not found";
export const USER_PROFILE_ERROR = "User profile error";
export const USER_DETAILS_ERROR = "Error fetching user details";
export const MAX_RETRIES_REACHED = "Maximum login retries reached";

export enum AppInsightsErrorType {
  MOBILE_VERIFICATION = "Mobile verification error",
  PRODUCT_OFFER = "Product offer error",
  RATE_OFFER = "Rate offer error",
  LOAN_OFFER = "Loan offer error",
  GET_APPLICATION = "Get application error",
  APPLICATION_LOGIN = "Application login error",
  GET_LOAN_APPLICATION = "Get dashboard application error",
  PUT_LOAN_APPLICATION = "Put dashboard application error",
  POST_LOAN_APPLICATION = "Post dashboard application error",
  LOAN_APPLICATION_STATUS = "Get dashboard application status error",
  ESTIMATE_ACCEPT = "Post estimate accept error",
  RATE_ESTIMATE = "Get rate estimate error",
  ESTIMATE_REFERRAL = "Post partner referral error",
  HOME_PRODUCTS = "Get home products error",
  APPLICATION_TERMS = "Get application terms error",
  RATE_ESTIMATE_TERMS = "Get rate estimate terms error",
  RATE_ESTIMATE_PREFILL = "Get rate estimate prefill error",
  POST_RATE_ESTIMATE = "Post rate estimate error",
  CUSTOMER_LOAN_PARAMETERS = "Get customer loan parameters error",
  BROKER_DETAILS = "Get broker details error",
}
