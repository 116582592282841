import { HistoryPayment } from "./history-borrowing-widget";

export type HistoryBorrowingAction =
  | HistoryBorrowingGetAction
  | HistoryBorrowingGetSuccessAction;

export const HistoryBorrowingGetActionType = "history/get" as const;
export type HistoryBorrowingGetAction = ReturnType<
  typeof historyBorrowingGetAction
>;
export function historyBorrowingGetAction(loanId: number, duration: number) {
  return {
    type: HistoryBorrowingGetActionType,
    loanId,
    duration,
  };
}
export const HistoryBorrowingGetSuccessActionType =
  "history/get/success" as const;
export type HistoryBorrowingGetSuccessAction = ReturnType<
  typeof historyBorrowingGetSuccessAction
>;
export function historyBorrowingGetSuccessAction(
  history: HistoryPayment[],
  loanId: number
) {
  return {
    type: HistoryBorrowingGetSuccessActionType,
    history,
    loanId,
  };
}
