import {
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import Axios from "axios";
import { Epic, ofType } from "redux-observable";
import { concat, from, Observable, of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";

import { QUOTE_BFF } from "../shared/url.consts";
import { Aggregator } from "./aggregator";
import {
  AggregatorGetActionType,
  aggregatorGetSuccessAction,
} from "./aggregator.actions";

export const GET_AGGREGATOR_LOADING = "GET_AGGREGATOR_LOADING";
export const getAggregatorEpic: Epic = (actions) =>
  actions.pipe(
    ofType(AggregatorGetActionType),
    concatMap((action) =>
      concat(
        of(loadingStartAction(GET_AGGREGATOR_LOADING)),
        getAggregatorById(action.aggregatorId).pipe(
          concatMap((aggregator) => [
            aggregatorGetSuccessAction(aggregator),
            loadingSuccessAction(GET_AGGREGATOR_LOADING),
          ])
        )
      )
    ),
    catchError(() =>
      of(loadingFailAction(GET_AGGREGATOR_LOADING, "Unexpected Error"))
    )
  );

function getAggregatorById(aid: string): Observable<Aggregator> {
  return from(
    Axios.get<Pick<Aggregator, "imageUrl" | "name">>(
      `${QUOTE_BFF}/aggregator/${aid}`
    )
  ).pipe(map((rsp) => ({ id: aid, ...rsp.data })));
}
