import { Button } from "@wisr/react-ui";
import { nanoid } from "nanoid";
import React from "react";
import loginAuthService from "../../auth/login/login-auth.service";
import { appInsights } from "../insights/app-insights";
import {
  EXCEPTION_ERROR_EVENT,
  USER_DETAILS_ERROR,
  USER_ERROR,
  USER_PROFILE_ERROR,
} from "../insights/app-insights.consts";
import { createAppInsightsEvent } from "../insights/app-insights.utils";
import { ErrorBoundaryPopup } from "./error-boundary-popup";

interface IProps {
  children?: React.ReactChild;
}

interface IState {
  error?: Error;
  errorInfo?: React.ErrorInfo;
  uuid: string;
  content?: React.ReactNode;
  actions?: React.ReactNode[];
}

export class ErrorBoundary extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { uuid: nanoid(10) };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    let type = "";
    console.log(error.message);
    if (error.name === "LoginError") {
      if (error.message === USER_DETAILS_ERROR) {
        type = USER_PROFILE_ERROR;
        const content = (
          <>
            <p>
              There was an issue fetching your details. Please try again later.
            </p>
          </>
        );
        this.setState({
          content,
        });
      } else {
        type = "Maxiumum login retries";
        const content = (
          <>
            <p>
              There was an issue logging you in. Please log out and try again.
            </p>
          </>
        );
        const actions = [
          <Button key={"logout"}>
            <button onClick={() => loginAuthService.signoutRedirect()}>
              Log out
            </button>
          </Button>,
        ];
        this.setState({
          content,
          actions,
        });
      }
    } else {
      type = "Unexpected Error";
      const content = (
        <>
          <p>We&apos;re sorry, something went wrong here.</p>
          <p>
            Please report this to{" "}
            <a
              href="mailto:contact@wisr.com.au"
              className="link contact-support"
            >
              contact@wisr.com.au
            </a>{" "}
            and pass this reference:
          </p>
          <p>
            <code>{error}</code>
          </p>
        </>
      );
      this.setState({
        content,
      });
    }
    appInsights.trackEvent(
      EXCEPTION_ERROR_EVENT,
      createAppInsightsEvent({
        category: USER_ERROR,
        info: `${error.stack}${errorInfo.componentStack}`,
        type,
        uuid: this.state.uuid,
      })
    );
  }

  render() {
    return this.state.error ? (
      <ErrorBoundaryPopup
        content={this.state.content}
        actions={this.state.actions}
      />
    ) : (
      this.props.children
    );
  }
}
