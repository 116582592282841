import {
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { AxiosResponse } from "axios";
import { Epic, ofType } from "redux-observable";
import { concat, from, Observable, of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import axios from "../../shared/axios.utils";
import { CREDIT_API } from "../../shared/url.consts";
import { AppAction } from "../../store";
import { CreditProfile } from "./credit-profile";
import {
  CreditProfileGetActionType,
  creditProfileGetSuccessAction,
} from "./credit-profile.actions";

export const GETPROFILE_CREDIT_LOADING = "GETPROFILE_CREDIT_LOADING";

export const getCreditProfile: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(CreditProfileGetActionType),
    concatMap(() =>
      concat(
        of(loadingStartAction(GETPROFILE_CREDIT_LOADING)),
        getCreditProfileApi().pipe(
          map((profile) => creditProfileGetSuccessAction(profile))
        ),
        of(loadingSuccessAction(GETPROFILE_CREDIT_LOADING))
      ).pipe(
        catchError((e) => of(loadingFailAction(GETPROFILE_CREDIT_LOADING, e)))
      )
    )
  );

function getCreditProfileApi(): Observable<CreditProfile> {
  return from(axios.get<CreditProfile>(`${CREDIT_API}/CustomerProfile`)).pipe(
    map((res: AxiosResponse) => res.data)
  );
}
