import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { DASH_API } from "../../shared/url.consts";
import { ApplicationHeaderRequest } from "../../types/application-header";

export const returnApplicationHeader = async (wisrApplicationId: string) => {
  return axios.get(`${DASH_API}/LoanApplication/${wisrApplicationId}/header`);
};

export const returnApplicationHeaderResponse = async ({
  wisrApplicationId,
  email,
  firstName,
}: ApplicationHeaderRequest) => {
  try {
    const response = await returnApplicationHeader(wisrApplicationId);
    if (response.status === 200) {
      return { ...response.data, email, firstName };
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.GET_APPLICATION
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching application header: ${err}`,
      AppInsightsErrorType.GET_APPLICATION
    );
  }
};

export const fetchApplicationHeader = createAsyncThunk(
  "application-header/get",
  returnApplicationHeaderResponse
);
