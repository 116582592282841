import { Aggregator } from "./aggregator";
import {
  AggregatorAction,
  AggregatorGetSuccessActionType,
  AggregatorSetActionType,
} from "./aggregator.actions";

export function aggregatorReducer(
  state: AggregatorState = {},
  action: AggregatorAction
) {
  switch (action.type) {
    case AggregatorGetSuccessActionType:
      return {
        ...state,
        aggregator: action.aggregator,
      };
    case AggregatorSetActionType:
      return {
        ...state,
        bid: action.bid,
      };

    default:
      return state;
  }
}

export interface AggregatorState {
  aggregator?: Aggregator;
  bid?: string;
}
