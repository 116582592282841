import { CreditProfile } from "./credit-profile";
import {
  CreditProfileActions,
  CreditProfileGetSuccessActionType,
} from "./credit-profile.actions";

export interface CreditProfileState {
  profile?: CreditProfile;
}

export const creditProfileReducer = (
  state: CreditProfileState = {},
  actions: CreditProfileActions
) => {
  switch (actions.type) {
    case CreditProfileGetSuccessActionType:
      return {
        profile: actions.profile,
      };

    default:
      return state;
  }
};
