/* istanbul ignore file */
export const CREDIT_API = `${process.env.GATSBY_DASHBFF}/api/creditbff` || "";
export const LOANS_API = `${process.env.GATSBY_DASHBFF}/api/loans/v1` || "";
export const PORTFOLIO_API =
  `${process.env.GATSBY_DASHBFF}/api/portfolio/v1` || "";
export const APPLICATIONS_API =
  `${process.env.GATSBY_DASHBFF}/api/loans-applications/v1` || "";
export const CUSTOMER_API = `${process.env.GATSBY_DASHBFF}/api` || "";
export const DASH_HUB = `${process.env.GATSBY_DASHBFF}/hubs` || "";
export const QUOTE_API = `${process.env.GATSBY_DASHBFF}/api/quote` || "";
export const BRAND_WEBSITE = process.env.GATSBY_BRAND_WEBSITE || "";
export const DASH_API = `${process.env.GATSBY_DASHBFF}/api/v1` || "";
export const EQUIFAX_CORRECTIONS =
  "https://www.equifax.com.au/personal/corrections-portal";
export const EXPERIAN_CORRECTIONS =
  "https://www.experian.com.au/correction-process";
export const QUOTE_FORM = `${process.env.GATSBY_BRAND_WEBSITE}/apply/quote`;
export const LOAN_OFFER_PAGE = `${process.env.GATSBY_BRAND_WEBSITE}/apply/estimate`;
export const WISR_DOMAIN = `${process.env.GATSBY_COOKIE_DOMAIN}` || "";
export const QUOTE_SERVICE = `${process.env.GATSBY_QUOTEAPI}` || "";
export const OCR_API = `${process.env.GATSBY_DASHBFF}/api/v1/CustomerVerification`;
export const PACE_API = `${process.env.GATSBY_PACE_API}` || "";
export const QUOTE_BFF = `${process.env.QUOTE_BFF}` || "";
export const LEGACY_APPLICATION_APP = `${process.env.GATSBY_LEGACY_APPLICATION_APP}`;
export const GATEWAY_API = `${process.env.GATSBY_GATEWAY_API}/gateway`;
