import { ScoreCreditWidgetState } from "../../../types/score-credit-widget";
import {
  ScoreCreditAction,
  ScoreCreditFormSubmitActionType,
  ScoreCreditGetSuccessActionType,
  ScoreCreditHomeGetSuccessActionType,
} from "./score-credit-widget.actions";

export const scoreCreditWidgetReducer = (
  state: ScoreCreditWidgetState = {},
  actions: ScoreCreditAction
): ScoreCreditWidgetState => {
  switch (actions.type) {
    case ScoreCreditGetSuccessActionType:
      return {
        ...state,
        creditScoreSummaryList: actions.creditScoreSummaryList,
        yearlyCreditHistoryList: actions.yearlyCreditHistoryList?.reverse(),
        halfYearlyCreditHistoryList:
          actions.halfYearlyCreditHistoryList?.reverse(),
      };
    case ScoreCreditHomeGetSuccessActionType:
      return {
        creditScoreSummaryList:
          actions.creditScoreDetails?.creditScoreSummaryList,
        yearlyCreditHistoryList:
          actions.creditScoreDetails?.yearlyCreditHistoryList?.reverse(),
        halfYearlyCreditHistoryList:
          actions.creditScoreDetails?.halfYearlyCreditHistoryList?.reverse(),
        isMobileVerified: actions.isMobileVerified,
        isCreditScoreFormSubmitted: actions.isCreditScoreFormSubmitted,
        contributingFactors: actions.contributingFactors ?? [],
        rateEstimateOffers: actions.rateEstimateOffers ?? undefined,
      };
    case ScoreCreditFormSubmitActionType:
      return {
        ...state,
        formSubmitted: true,
      };
    default:
      return state;
  }
};
