import { isDefined } from "@wisr/common";
import React from "react";
import { Helmet } from "react-helmet";
import appleIcon from "../assets/images/apple-touch-icon.png";
import faviconSmall from "../assets/images/favicon-16x16.png";
import faviconBig from "../assets/images/favicon-32x32.png";
import safariIcon from "../assets/images/safari-pinned-tab.svg";

interface Props {
  title?: string;
  description?: string;
  image?: string;
}
export const HelmetSeoComponent: React.FunctionComponent<Props> = (props) => {
  const siteName = " | Wisr";
  return (
    <Helmet>
      <title key="title">
        {props.title ? props.title + siteName : siteName}
      </title>

      {isDefined(props.title) && [
        <meta key="meta:title" name="title" content={props.title + siteName} />,
        <meta
          key="og:title"
          property="og:title"
          content={props.title + siteName}
        />,
        <meta
          key="twitter:title"
          name="twitter:title"
          content={props.title + siteName}
        />,
      ]}
      {isDefined(props.description) && [
        <meta
          key="meta:description"
          name="description"
          content={props.description}
        />,
        <meta
          key="og:description"
          property="og:description"
          content={props.description}
        />,
        <meta
          key="twitter:description"
          name="twitter:description"
          content={props.description}
        />,
      ]}
      {isDefined(props.image) && [
        <meta key="og:image" property="og:image" content={props.image} />,
        <meta key="twitter:image" name="twitter:image" content={props.image} />,
      ]}
      <link rel="apple-touch-icon" sizes="180x180" href={appleIcon} />
      <link rel="icon" type="image/png" sizes="32x32" href={faviconBig} />
      <link rel="icon" type="image/png" sizes="16x16" href={faviconSmall} />
      <link rel="mask-icon" href={safariIcon} color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="theme-color" content="#00aba9" />
      {props.children}
    </Helmet>
  );
};
