import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  LoanParametersState,
  LoanParametersStore,
} from "../../types/loan-parameters";
import { fetchLoanParameters } from "./loan-parameters.thunk";

const initialState: LoanParametersState = {
  loanParameters: {
    rateEstimate: {
      limits: {
        secure: {
          min: 0,
          max: 0,
        },
        unsecure: {
          min: 0,
          max: 0,
        },
      },
      securePurposes: [],
      securedAssetMaxAge: 0,
    },
    loanPurpose: [],
  },
  status: FetchStatus.IDLE,
  error: "",
};

export const loanParametersSlice = createSlice({
  name: "loanParameters",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLoanParameters.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchLoanParameters.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.loanParameters = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "data is undefined";
        }
      })
      .addCase(fetchLoanParameters.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export default loanParametersSlice.reducer;

export const selectLoanParameters = (store: LoanParametersStore) =>
  store.loanParametersState.loanParameters;

export const selectLoanParametersStatus = (store: LoanParametersStore) =>
  store.loanParametersState.status;
