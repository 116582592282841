export enum IdentityTypes {
  DRIVERS_LICENCE = "DriversLicence",
  MEDICARE_CARD = "MedicareCard",
  PASSPORT = "Passport",
}

export const idOptions = [
  {
    value: IdentityTypes.DRIVERS_LICENCE,
    label: "Drivers licence",
    placeholder: "Licence number",
  },
  {
    value: IdentityTypes.MEDICARE_CARD,
    label: "Medicare",
    placeholder: "Medicare number",
  },
  {
    value: IdentityTypes.PASSPORT,
    label: "Australian Passport",
    placeholder: "Passport number",
  },
];

export const PASSPORT_COUNTRY = "AUS";

export const AustralianState = {
  ACT: "ACT",
  NSW: "NSW",
  NT: "NT",
  QLD: "QLD",
  SA: "SA",
  TAS: "TAS",
  VIC: "VIC",
  WA: "WA",
};

export const australianStates = [
  { label: "ACT", value: AustralianState.ACT },
  { label: "NSW", value: AustralianState.NSW },
  { label: "NT", value: AustralianState.NT },
  { label: "QLD", value: AustralianState.QLD },
  { label: "SA", value: AustralianState.SA },
  { label: "TAS", value: AustralianState.TAS },
  { label: "VIC", value: AustralianState.VIC },
  { label: "WA", value: AustralianState.WA },
];

export const medicareTypes = [
  {
    value: "green",
    label: "Green",
    placeholder: "Green",
  },
  {
    value: "blue",
    label: "Blue",
    placeholder: "Blue",
  },
  {
    value: "yellow",
    label: "Yellow",
    placeholder: "Yellow",
  },
];
