/* istanbul ignore file - external file */

import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { APP_INSIGHTS_INSTRUMENTATION_KEY } from "../analytics/analytics.consts";

export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: APP_INSIGHTS_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
  },
});
