import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  CustomerLoanParametersStore,
  LoanParametersState,
} from "../../types/loan-parameters";
import { fetchCustomerLoanParameters } from "./customer-loan-parameters.thunk";

const initialState: LoanParametersState = {
  loanParameters: {
    rateEstimate: {
      limits: {
        secure: {
          min: 0,
          max: 0,
        },
        unsecure: {
          min: 0,
          max: 0,
        },
      },
      securePurposes: [],
      securedAssetMaxAge: 0,
    },
    loanPurpose: [],
  },
  status: FetchStatus.IDLE,
  error: "",
};

export const customerLoanParametersSlice = createSlice({
  name: "customerLoanParameters",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCustomerLoanParameters.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchCustomerLoanParameters.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.loanParameters = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "data is undefined";
        }
      })
      .addCase(fetchCustomerLoanParameters.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export default customerLoanParametersSlice.reducer;

export const selectCustomerLoanParametersState = (
  store: CustomerLoanParametersStore
) => store.customerLoanParametersState;
