import { Application } from "./application";
import { applicationFormDefaults } from "./application-form.constants";

export type ApplicationFormAction =
  | ApplicationGetAction
  | ApplicationGetSuccessAction
  | ApplicationGetContinueTokenAction
  | ApplicationGetContinueTokenSucessAction
  | ApplicationSaveAction
  | ApplicationSubmitAction
  | ApplicationClearStateAction
  | ApplicationUpdateNSRAction;

export const ApplicationGetActionType = "application-form/get" as const;
export type ApplicationGetAction = ReturnType<typeof applicationGetAction>;
/* istanbul ignore next */
export function applicationGetAction(continueToken: string) {
  return {
    type: ApplicationGetActionType,
    continueToken,
  };
}

export const ApplicationGetSuccessActionType =
  "application/get-success" as const;
export type ApplicationGetSuccessAction = ReturnType<
  typeof applicationGetSuccessAction
>;
export function applicationGetSuccessAction(
  application: typeof applicationFormDefaults,
  applicationRef: string,
  quoteRefId: string,
  rateCardVersion: string,
  rateCardVersionSecured: string,
  creditScore?: number
) {
  return {
    type: ApplicationGetSuccessActionType,
    application,
    applicationRef,
    quoteRefId,
    creditScore,
    rateCardVersion,
    rateCardVersionSecured,
  };
}

export const ApplicationGetContinueTokenActionType =
  "application-continue-token/get" as const;
export type ApplicationGetContinueTokenAction = ReturnType<
  typeof applicationGetContinueTokenAction
>;
export function applicationGetContinueTokenAction(token: string) {
  return {
    type: ApplicationGetContinueTokenActionType,
    token,
  };
}

export const ApplicationGetContinueTokenSuccessActionType =
  "application-continue-token/get-success" as const;
export type ApplicationGetContinueTokenSucessAction = ReturnType<
  typeof applicationGetContinueTokenSuccessAction
>;
export function applicationGetContinueTokenSuccessAction(
  continueToken: string
) {
  return {
    type: ApplicationGetContinueTokenSuccessActionType,
    continueToken,
  };
}

export const ApplicationSaveActionType = "application/save-progress" as const;
export type ApplicationSaveAction = ReturnType<typeof applicationSaveAction>;
export function applicationSaveAction(
  application: Application,
  editing?: boolean,
  term?: string
) {
  return {
    type: ApplicationSaveActionType,
    application,
    complete: false,
    editing,
    term,
  };
}

export const ApplicationSubmitActionType = "application/submit" as const;
export type ApplicationSubmitAction = ReturnType<
  typeof applicationSubmitAction
>;
export function applicationSubmitAction(
  application: Application,
  complete = false
) {
  return {
    type: ApplicationSubmitActionType,
    application,
    complete,
  };
}

export const ApplicationUpdateNSRActionType = "application/nsr" as const;
export type ApplicationUpdateNSRAction = ReturnType<
  typeof applicationUpdateNSRAction
>;
export function applicationUpdateNSRAction(
  nsrOutcome: string,
  submissionContinueToken: string
) {
  return {
    type: ApplicationUpdateNSRActionType,
    nsrOutcome,
    submissionContinueToken,
  };
}

export const ApplicationClearStateActionType =
  "application/clear-state" as const;
export type ApplicationClearStateAction = ReturnType<
  typeof applicationClearAction
>;
export function applicationClearAction(
  editing?: boolean,
  term?: string,
  redirect?: boolean
) {
  return {
    type: ApplicationClearStateActionType,
    editing,
    term,
    redirect,
  };
}
