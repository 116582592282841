import { CheckboxOption, Icon } from "@wisr/react-ui";
import React from "react";
import frown from "../assets/images/frown.svg";
import grimace from "../assets/images/grimace.svg";
import meh from "../assets/images/meh.svg";
import pleased from "../assets/images/pleased.svg";
import smile from "../assets/images/smile.svg";
import zipped from "../assets/images/zipped.svg";
import {
  FinancialMood,
  FinancialMoodFormData,
  FinancialMoodResponse,
  FinancialMoodState,
} from "../types/financial-mood";

export const MOCK_FINANCIAL_MOOD_RESPONSE: FinancialMoodResponse = {
  financialMood: "Good",
  moneyMoments: ["Vehicle", "Invest", "Payday"],
  versionNumber: 2,
};

export const CLOSED_FINANCIAL_MOOD_STATE: FinancialMoodState = {
  financialMoodResponse: MOCK_FINANCIAL_MOOD_RESPONSE,
  isModalOpen: false,
};

export const OPENED_FINANCIAL_MOOD_STATE: FinancialMoodState = {
  financialMoodResponse: MOCK_FINANCIAL_MOOD_RESPONSE,
  isModalOpen: true,
};

export const EMPTY_FINANCIAL_MOOD_STATE: FinancialMoodState = {
  financialMoodResponse: {
    financialMood: undefined as unknown as FinancialMood,
    moneyMoments: [],
    versionNumber: 0,
  },
  isModalOpen: false,
};

export const INITIAL_FINANCIAL_MOOD_STATE: FinancialMoodState = {
  financialMoodResponse: null,
  isModalOpen: false,
};

export const EMPTY_FINANCIAL_MOOD_FORM_DATA: FinancialMoodFormData = {
  financialMood: undefined as unknown as FinancialMood,
  moneyMoments: [],
};

export const MONEY_MOMENT_OPTIONS: CheckboxOption[] = [
  {
    label: (
      <span className="money-moment">
        <Icon name="misc-chart-decline" size="md" legacy={true}></Icon>
        Reduce my debts
      </span>
    ),
    value: "Consolidation",
  },
  {
    label: (
      <span className="money-moment">
        <Icon name="action-home" size="md" legacy={true}></Icon>
        Save for a deposit
      </span>
    ),
    value: "Home",
  },
  {
    label: (
      <span className="money-moment">
        <Icon name="misc-car" size="md" legacy={true}></Icon>
        Buy a new ride
      </span>
    ),
    value: "Vehicle",
  },
  {
    label: (
      <span className="money-moment">
        <Icon name="misc-credit-card" size="md" legacy={true}></Icon>
        Pay off my credit card
      </span>
    ),
    value: "CreditCard",
  },
  {
    label: (
      <span className="money-moment">
        <Icon name="misc-flower-outline" size="md" legacy={true}></Icon>
        Invest my money
      </span>
    ),
    value: "Invest",
  },
  {
    label: (
      <span className="money-moment">
        <Icon name="action-briefcase" size="md" legacy={true}></Icon>
        Take a holiday
      </span>
    ),
    value: "Holiday",
  },
  {
    label: (
      <span className="money-moment">
        <Icon name="misc-wallet" size="md" legacy={true}></Icon>
        Make it to next payday
      </span>
    ),
    value: "Payday",
  },
  {
    label: (
      <span className="money-moment">
        <Icon name="misc-paintbrush" size="md" legacy={true}></Icon>
        Renovate my home
      </span>
    ),
    value: "Renovation",
  },
  {
    label: (
      <span className="money-moment">
        <Icon name="misc-ring" size="md" legacy={true}></Icon>
        Pay for a wedding
      </span>
    ),
    value: "Wedding",
  },
  {
    label: (
      <span className="money-moment">
        <Icon name="action-star" size="md" legacy={true}></Icon>
        Something else
      </span>
    ),
    value: "Other",
  },
];

export const FINANCIAL_MOOD_OPTIONS: CheckboxOption[] = [
  {
    label: (
      <>
        <img className="financial-mood" src={frown} />
        Just don&apos;t even
      </>
    ),
    value: "DontEven",
  },
  {
    label: (
      <>
        <img className="financial-mood" src={grimace} />A bit meh
      </>
    ),
    value: "Meh",
  },
  {
    label: (
      <>
        <img className="financial-mood" src={meh} />
        I&apos;m doing okay
      </>
    ),
    value: "Good",
  },
  {
    label: (
      <>
        <img className="financial-mood" src={smile} />
        Better than most
      </>
    ),
    value: "Cruisin",
  },
  {
    label: (
      <>
        <img className="financial-mood" src={pleased} />
        I&apos;m crushing it
      </>
    ),
    value: "Crushin",
  },
  {
    label: (
      <>
        <img className="financial-mood" src={zipped} />
        I&apos;d rather not say
      </>
    ),
    value: "NotSay",
  },
];
