import { Icon } from "@wisr/react-ui";
import React from "react";
import { StatusStates } from "../status/status";
import statusStyle from "../status/status.scss";
import style from "./banner.scss";

export interface BannerProps {
  banner: StatusStates;
}

export const Banner: React.FC<BannerProps> = ({ children, banner }) => {
  const [closed, setClosed] = React.useState(false);

  if (closed) {
    return null;
  }

  return (
    <aside role="dialog" className={`banner ${banner}`}>
      <style jsx>{statusStyle}</style>
      <style jsx>{style}</style>
      <div className="icon">
        <Icon name={`status-${banner}`} size="md" legacy={true} />
      </div>
      {children}
      <button type="button" className="button" onClick={() => setClosed(true)}>
        <Icon size="sm" name="status-cross" legacy={true} />
      </button>
    </aside>
  );
};
