import {
  loadDataThen,
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { navigate } from "gatsby";
import { Epic, ofType } from "redux-observable";
import { concat, of } from "rxjs";
import {
  catchError,
  concatMap,
  map,
  mapTo,
  mergeMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import { redirect } from "../shared/browser.utils";
import { LEGACY_APPLICATION_APP, LOAN_OFFER_PAGE } from "../shared/url.consts";
import { AppAction, AppStore } from "../store";
import {
  applicationClearAction,
  ApplicationClearStateActionType,
  ApplicationGetActionType,
  ApplicationGetContinueTokenActionType,
  applicationGetContinueTokenSuccessAction,
  applicationGetSuccessAction,
  ApplicationSaveAction,
  ApplicationSaveActionType,
  ApplicationSubmitAction,
  ApplicationSubmitActionType,
  applicationUpdateNSRAction,
} from "./application-form.actions";
import {
  getApplicationByToken,
  getContinueToken,
  saveApplication,
} from "./application-form.service";
import { mapToApplicationRequest } from "./application.utils";

export const GET_APPLICATION_LOADING = "GET_APPLICATION_LOADING";
export const getApplicationEpic: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(ApplicationGetActionType),
    loadDataThen(GET_APPLICATION_LOADING, (action) =>
      getApplicationByToken(action.continueToken).pipe(
        map(
          ({
            application,
            applicationRef,
            quoteRefId,
            creditScore,
            rateCardVersion,
            rateCardVersionSecured,
          }) =>
            applicationGetSuccessAction(
              application,
              applicationRef,
              quoteRefId,
              rateCardVersion,
              rateCardVersionSecured,
              creditScore
            )
        )
      )
    )
  );

export const getApplicationContinueTokenEpic: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(ApplicationGetContinueTokenActionType),
    concatMap((action) =>
      concat(
        of(loadingStartAction(GET_APPLICATION_LOADING)),
        getContinueToken(action.token).pipe(
          tap(({ continueToken }) =>
            window.sessionStorage.setItem("ContinueToken", continueToken)
          ),
          concatMap(({ continueToken }) => [
            applicationGetContinueTokenSuccessAction(continueToken),
            loadingSuccessAction(GET_APPLICATION_LOADING),
          ])
        )
      )
    ),
    catchError(() => {
      return of(loadingFailAction(GET_APPLICATION_LOADING, "Unexpected Error"));
    })
  );

export const SAVE_APPLICATION_LOADING = "SAVE_APPLICATION_LOADING";
export const saveApplicationEpic: Epic<AppAction, AppAction, AppStore> = (
  actions,
  store
) =>
  actions.pipe(
    ofType(ApplicationSaveActionType),
    withLatestFrom(store),
    concatMap(([action, state]) =>
      concat(
        of(loadingStartAction(SAVE_APPLICATION_LOADING)),
        of([action, state]).pipe(
          map(() => generateApplicationRequest(action, state)),
          concatMap(saveApplication),
          mapTo(
            action.editing
              ? applicationClearAction(action.editing, action.term, true)
              : loadingSuccessAction(SAVE_APPLICATION_LOADING)
          )
        )
      ).pipe(
        catchError(() => {
          return of(
            loadingFailAction(
              SAVE_APPLICATION_LOADING,
              "Saving was unsuccessful, please try again."
            )
          );
        })
      )
    )
  );
export const submitApplicationEpic: Epic<AppAction, AppAction, AppStore> = (
  actions,
  store
) =>
  actions.pipe(
    ofType(ApplicationSubmitActionType),
    withLatestFrom(store),
    concatMap(([action, state]) =>
      concat(
        of(loadingStartAction(SAVE_APPLICATION_LOADING)),
        mergeMap(saveApplication)(
          of(generateApplicationRequest(action, state, true))
        ).pipe(
          tap((res) =>
            action.complete
              ? res.NSROutcome !== "FailedNsr"
                ? redirect(
                    `${LEGACY_APPLICATION_APP}/home/ApplicationCompleted?t=${state.applicationFormState.continueToken}`
                  )
                : undefined
              : navigate("/application/bank-statements")
          ),
          concatMap((res) =>
            res.NSROutcome !== "FailedNsr"
              ? of(loadingSuccessAction(SAVE_APPLICATION_LOADING))
              : concat(
                  of(
                    applicationUpdateNSRAction(
                      res.NSROutcome,
                      res.continueToken
                    )
                  ),
                  of(loadingSuccessAction(SAVE_APPLICATION_LOADING))
                )
          ),
          catchError((e) => {
            if (e.response.status === 400) {
              const [message, reasons] = (
                e.response.data.Message as string
              ).split(/<ul>/);
              return of(
                loadingFailAction(
                  SAVE_APPLICATION_LOADING,
                  message.replace(/<.*?>/gi, ""),
                  {
                    reasons: reasons
                      .split(/<li>/)
                      .map((r) => r.replace(/<.*?>/gi, "").trim())
                      .filter((r) => !!r),
                  }
                )
              );
            }

            return of(
              loadingFailAction(SAVE_APPLICATION_LOADING, "Unexpected error")
            );
          })
        )
      )
    )
  );

export const clearApplicationEpic: Epic<AppAction, AppAction, AppStore> = (
  actions,
  store
) =>
  actions.pipe(
    ofType(ApplicationClearStateActionType),
    withLatestFrom(store),
    concatMap(([action, state]) =>
      concat(
        of([action, state]).pipe(
          tap(() => {
            if (action.redirect) {
              if (action.editing && action.term) {
                redirect(
                  `${LOAN_OFFER_PAGE}?qid=${state.applicationFormState.quoteRefId}&editing=${action.editing}&term=${action.term}`
                );
              } else {
                redirect(
                  `${LOAN_OFFER_PAGE}?qid=${state.applicationFormState.quoteRefId}`
                );
              }
            }
          }),
          mapTo(loadingSuccessAction(SAVE_APPLICATION_LOADING))
        )
      )
    )
  );

function generateApplicationRequest(
  action: ApplicationSaveAction | ApplicationSubmitAction,
  state: AppStore,
  validateRequest = false
) {
  if (
    !state.applicationFormState.application ||
    !state.applicationFormState.applicationRef ||
    !state.applicationFormState.continueToken
  ) {
    throw Error(`Missing required data to map to ApplicationRequest
      app: ${!!state.applicationFormState.application}
      ref: ${!!state.applicationFormState.applicationRef}
      token: ${!!state.applicationFormState.continueToken}
    `);
  }

  return mapToApplicationRequest(
    action.application,
    state.applicationFormState.continueToken,
    state.applicationFormState.applicationRef,
    state.applicationFormState.rateCardVersion,
    state.applicationFormState.rateCardVersionSecured,
    !validateRequest,
    !!action.complete
  );
}
