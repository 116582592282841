import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { DASH_API } from "../../shared/url.consts";
import { LoanParametersResponse } from "../../types/loan-parameters";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";

export const returnCustomerParameters = async () => {
  return axios.get<LoanParametersResponse>(
    `${DASH_API}/loanApplication/customer/parameters`
  );
};

export const returnCustomerParametersResponse = async () => {
  try {
    const response = await returnCustomerParameters();
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.CUSTOMER_LOAN_PARAMETERS
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching customer loan parameters: ${err}`,
      AppInsightsErrorType.CUSTOMER_LOAN_PARAMETERS
    );
  }
};

export const fetchCustomerLoanParameters = createAsyncThunk(
  "customer-loan-parameters/get",
  returnCustomerParametersResponse
);
