/* istanbul ignore file */

import {
  OIDC_REDIRECT,
  IDP,
  OIDC_SCOPE,
  CLIENT_ID,
} from "./shared/oidc.consts";
import { BRAND_WEBSITE } from "./shared/url.consts";

export const IDENTITY_CONFIG = {
  authority: IDP,
  client_id: CLIENT_ID.DASHBOARD,
  redirect_uri: OIDC_REDIRECT + "/login/",
  response_type: "code",
  post_logout_redirect_uri: BRAND_WEBSITE,
  scope: OIDC_SCOPE,
  silent_redirect_uri: OIDC_REDIRECT + "/silent-refresh/",
  automaticSilentRenew: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
};

export const METADATA_OIDC = {
  issuer: IDP,
  jwks_uri: IDP + "/.well-known/openid-configuration/jwks",
  authorization_endpoint: IDP + "/connect/authorize",
  token_endpoint: IDP + "/connect/token",
  userinfo_endpoint: IDP + "/connect/userinfo",
  end_session_endpoint: IDP + "/connect/endsession",
  check_session_iframe: IDP + "/connect/checksession",
  revocation_endpoint: IDP + "/connect/revocation",
  introspection_endpoint: IDP + "/connect/introspect",
  device_authorization_endpoint: IDP + "/connect/deviceauthorization",
  frontchannel_logout_supported: true,
  frontchannel_logout_session_supported: true,
  backchannel_logout_supported: true,
  backchannel_logout_session_supported: true,
  scopes_supported: [
    "openid",
    "profile",
    "Credit_GetScore",
    "wisrcreditbff.creditprofile",
    "IdentityServerApi",
    "eligibility_request",
    "wisr.dash.api",
    "portfolio",
    "wisr.loans",
    "wisr.loans.applications.api",
    "UpdateLoanAmount",
    "offline_access",
    "email",
    "wisr.appapi",
    "wisr.payments",
    "wisr.banking",
    "quote",
    "LoanServicing",
  ],

  claims_supported: [
    "sub",
    "updated_at",
    "locale",
    "zoneinfo",
    "birthdate",
    "gender",
    "picture",
    "profile",
    "preferred_username",
    "nickname",
    "middle_name",
    "given_name",
    "family_name",
    "name",
    "website",
    "email_verified",
    "email",
    "location",
    "role",
  ],
  grant_types_supported: [
    "authorization_code",
    "client_credentials",
    "refresh_token",
    "implicit",
    "password",
    "urn:ietf:params:oauth:grant-type:device_code",
  ],
  response_types_supported: [
    "code",
    "token",
    "id_token",
    "id_token token",
    "code id_token",
    "code token",
    "code id_token token",
  ],
  response_modes_supported: ["form_post", "query", "fragment"],
  token_endpoint_auth_methods_supported: [
    "client_secret_basic",
    "client_secret_post",
  ],
  subject_types_supported: ["public"],
  id_token_signing_alg_values_supported: ["RS256"],
  code_challenge_methods_supported: ["plain", "S256"],
};
