import {
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { Epic, ofType } from "redux-observable";
import { concat, from, Observable, of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import axios from "../../../shared/axios.utils";
import { DASH_API } from "../../../shared/url.consts";
import { AppAction } from "../../../store";
import {
  LoanBorrowingGetActionType,
  loanBorrowingGetSuccessAction,
} from "./personal-loan-borrowing-widget.actions";
import { LoansHome } from "../../../types/personal-loan";

export const GETLOAN_BORROWING_LOADING = "GETLOAN_BORROWING_LOADING";

export const getLoanBorrowingWidget: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(LoanBorrowingGetActionType),
    concatMap(() =>
      concat(
        of(loadingStartAction(GETLOAN_BORROWING_LOADING)),
        getLoanBorrowing().pipe(
          map((loans) => loanBorrowingGetSuccessAction(loans))
        ),
        of(loadingSuccessAction(GETLOAN_BORROWING_LOADING))
      ).pipe(
        catchError((e) => of(loadingFailAction(GETLOAN_BORROWING_LOADING, e)))
      )
    )
  );

function getLoanBorrowing(): Observable<LoansHome> {
  return from(axios.get<LoansHome>(`${DASH_API}/loans/home`)).pipe(
    map((res) => res.data)
  );
}
