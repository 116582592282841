import { PersonalLoansState } from "../../../types/personal-loan";
import {
  LoanBorrowingAction,
  LoanBorrowingGetSuccessActionType,
} from "./personal-loan-borrowing-widget.actions";

export const loanBorrowingWidgetReducer = (
  state: PersonalLoansState = {},
  actions: LoanBorrowingAction
) => {
  switch (actions.type) {
    case LoanBorrowingGetSuccessActionType:
      return {
        loans: actions.loans,
      };

    default:
      return state;
  }
};
