import { OcrState } from "../types/ocr";
import { OcrActions, OcrActionTypes } from "./ocr.actions";
import { EMPTY_OCR_STATE } from "./ocr.constants";

export const ocrReducer = (
  state: OcrState = EMPTY_OCR_STATE,
  action: OcrActions
): OcrState => {
  switch (action.type) {
    case OcrActionTypes.GET_SUCCESS:
      return {
        ...state,
        customerData: action.customerData,
        result: action.result,
      };
    case OcrActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        transactionId: action.transactionId,
        mobile: action.mobile,
        wisrCustomerId: action.wisrCustomerId,
      };
    case OcrActionTypes.CLEAR:
      return EMPTY_OCR_STATE;

    case OcrActionTypes.CREATE_FAILED:
    case OcrActionTypes.GET_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case OcrActionTypes.INCREMENT_ATTEMPT:
      return {
        ...state,
        transactionAttemptCount: state.transactionAttemptCount + 1,
      };

    case OcrActionTypes.SAVE_TRANSACTION_ID:
      return {
        ...state,
        transactionId: action.transactionId,
      };

    default:
      return state;
  }
};
