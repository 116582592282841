import axios from "axios";
import { DASH_API } from "../../shared/url.consts";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoanApplicationStatusResponse } from "../../types/loan-application-status";

export const returnLoanApplicationStatus = async (
  wisrApplicationId: string
) => {
  return axios.get<LoanApplicationStatusResponse>(
    `${DASH_API}/loanApplication/${wisrApplicationId}/status`
  );
};

export const returnLoanApplicationStatusResponse = async (
  wisrApplicationId: string
) => {
  try {
    const response = await returnLoanApplicationStatus(wisrApplicationId);
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.LOAN_APPLICATION_STATUS
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching loan application status: ${err}`,
      AppInsightsErrorType.LOAN_APPLICATION_STATUS
    );
  }
};

export const fetchLoanApplicationStatus = createAsyncThunk(
  "loan-application-status/get",
  returnLoanApplicationStatusResponse
);
