import { useSelector } from "react-redux";
import { AppStore } from "../../../store";
import {
  IncompleteLoanApplication,
  PersonalLoan,
} from "../../../types/personal-loan";
import { getApplicationFromActive } from "../../../application/application.utils";
import { isBrowser } from "@wisr/common";

export const useGetLoans = () => {
  return useSelector<AppStore, PersonalLoan[] | undefined>(
    (store) => store.loanBorrowingWidgetState.loans?.settledLoanData
  );
};

export const useGetLoansCount = () => {
  return useSelector<AppStore, number>(
    (store) => store.loanBorrowingWidgetState.loans?.settledLoanData.length ?? 0
  );
};

export const useGetLoanById = (loanId: number | undefined) => {
  return useSelector<AppStore, PersonalLoan | undefined>((store) =>
    store.loanBorrowingWidgetState.loans?.settledLoanData.find(
      (loan) => loan.loanId === loanId
    )
  );
};

export const useGetIncompleteApplications = () => {
  return useSelector<AppStore, IncompleteLoanApplication[]>(
    (store) =>
      store.loanBorrowingWidgetState.loans?.inCompleteLoanApplications ?? []
  );
};

export const useGetIncompleteApplicationIdByQuoteId = (quoteId: string) => {
  return useSelector<AppStore, string | undefined>(
    (store) =>
      store.loanBorrowingWidgetState.loans?.inCompleteLoanApplications.find(
        (loanApplication) =>
          loanApplication.quoteId?.toLowerCase() === quoteId.toLowerCase()
      )?.wisrApplicationId ?? undefined
  );
};

export const useGetWisrApplicationId = /* istanbul ignore next */ () => {
  const loanId = isBrowser() ? window.sessionStorage.getItem("loanId") : null;

  const applications = useGetIncompleteApplications();
  const applicationDetails = getApplicationFromActive(applications, loanId);
  const applicationIdFromQuoteId = useGetIncompleteApplicationIdByQuoteId(
    applicationDetails?.quoteId ?? ""
  );

  return applicationIdFromQuoteId ?? "";
};
