export type Debt = {
  type: string;
  company: string;
  balance: string;
  repaymentAmount: string;
  frequency: string;
  isJoint: string[];
  consolidate: string[];
  investment: string[];
  cardLimit: string;
  closeCard: string[];
};

export type FinancialDebtId = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export function getFieldKeys(id: FinancialDebtId) {
  /**
   * @todo the version of TS we are using does not support literal types
   * If it did, we would be able to use `as const` and it would resolve the types correctly.
   * When we move to a newer version of TS we should make this change
   * */
  const debtTypeKey = `financialDebtType_${id}` as
    | "financialDebtType_1"
    | "financialDebtType_2"
    | "financialDebtType_3"
    | "financialDebtType_4"
    | "financialDebtType_5"
    | "financialDebtType_6"
    | "financialDebtType_7"
    | "financialDebtType_8"
    | "financialDebtType_9"
    | "financialDebtType_10";
  const financeCompanyKey = `financialDebtFinanceCompany_${id}` as
    | "financialDebtFinanceCompany_1"
    | "financialDebtFinanceCompany_2"
    | "financialDebtFinanceCompany_3"
    | "financialDebtFinanceCompany_4"
    | "financialDebtFinanceCompany_5"
    | "financialDebtFinanceCompany_6"
    | "financialDebtFinanceCompany_7"
    | "financialDebtFinanceCompany_8"
    | "financialDebtFinanceCompany_9"
    | "financialDebtFinanceCompany_10";
  const balanceKey = `financialDebtBalance_${id}` as
    | "financialDebtBalance_1"
    | "financialDebtBalance_2"
    | "financialDebtBalance_3"
    | "financialDebtBalance_4"
    | "financialDebtBalance_5"
    | "financialDebtBalance_6"
    | "financialDebtBalance_7"
    | "financialDebtBalance_8"
    | "financialDebtBalance_9"
    | "financialDebtBalance_10";
  const repaymentAmountKey = `financialDebtRepaymentAmount_${id}` as
    | "financialDebtRepaymentAmount_1"
    | "financialDebtRepaymentAmount_2"
    | "financialDebtRepaymentAmount_3"
    | "financialDebtRepaymentAmount_4"
    | "financialDebtRepaymentAmount_5"
    | "financialDebtRepaymentAmount_6"
    | "financialDebtRepaymentAmount_7"
    | "financialDebtRepaymentAmount_8"
    | "financialDebtRepaymentAmount_9"
    | "financialDebtRepaymentAmount_10";
  const repaymentFrequencyKey = `financialDebtRepaymentFrequency_${id}` as
    | "financialDebtRepaymentFrequency_1"
    | "financialDebtRepaymentFrequency_2"
    | "financialDebtRepaymentFrequency_3"
    | "financialDebtRepaymentFrequency_4"
    | "financialDebtRepaymentFrequency_5"
    | "financialDebtRepaymentFrequency_6"
    | "financialDebtRepaymentFrequency_7"
    | "financialDebtRepaymentFrequency_8"
    | "financialDebtRepaymentFrequency_9"
    | "financialDebtRepaymentFrequency_10";
  const isJointLoanKey = `financialDebtIsJointLoan_${id}` as
    | "financialDebtIsJointLoan_1"
    | "financialDebtIsJointLoan_2"
    | "financialDebtIsJointLoan_3"
    | "financialDebtIsJointLoan_4"
    | "financialDebtIsJointLoan_5"
    | "financialDebtIsJointLoan_6"
    | "financialDebtIsJointLoan_7"
    | "financialDebtIsJointLoan_8"
    | "financialDebtIsJointLoan_9"
    | "financialDebtIsJointLoan_10";
  const consolidateKey = `financialDebtConsolidate_${id}` as
    | "financialDebtConsolidate_1"
    | "financialDebtConsolidate_2"
    | "financialDebtConsolidate_3"
    | "financialDebtConsolidate_4"
    | "financialDebtConsolidate_5"
    | "financialDebtConsolidate_6"
    | "financialDebtConsolidate_7"
    | "financialDebtConsolidate_8"
    | "financialDebtConsolidate_9"
    | "financialDebtConsolidate_10";
  const investmentKey = `financialDebtIsInvestment_${id}` as
    | "financialDebtIsInvestment_2"
    | "financialDebtIsInvestment_3"
    | "financialDebtIsInvestment_4"
    | "financialDebtIsInvestment_5"
    | "financialDebtIsInvestment_6"
    | "financialDebtIsInvestment_7"
    | "financialDebtIsInvestment_8"
    | "financialDebtIsInvestment_9"
    | "financialDebtIsInvestment_10";

  const cardLimitKey = `financialDebtCreditCardLimit_${id}` as
    | "financialDebtCreditCardLimit_1"
    | "financialDebtCreditCardLimit_2"
    | "financialDebtCreditCardLimit_3"
    | "financialDebtCreditCardLimit_4"
    | "financialDebtCreditCardLimit_5"
    | "financialDebtCreditCardLimit_6"
    | "financialDebtCreditCardLimit_7"
    | "financialDebtCreditCardLimit_8"
    | "financialDebtCreditCardLimit_9"
    | "financialDebtCreditCardLimit_10";
  const closeCardKey = `financialDebtCloseThisCard_${id}` as
    | "financialDebtCloseThisCard_1"
    | "financialDebtCloseThisCard_2"
    | "financialDebtCloseThisCard_3"
    | "financialDebtCloseThisCard_4"
    | "financialDebtCloseThisCard_5"
    | "financialDebtCloseThisCard_6"
    | "financialDebtCloseThisCard_7"
    | "financialDebtCloseThisCard_8"
    | "financialDebtCloseThisCard_9"
    | "financialDebtCloseThisCard_10";
  return {
    debtTypeKey,
    financeCompanyKey,
    balanceKey,
    repaymentAmountKey,
    repaymentFrequencyKey,
    isJointLoanKey,
    consolidateKey,
    investmentKey,
    cardLimitKey,
    closeCardKey,
  };
}
