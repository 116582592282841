export const activePages = (activePage?: string) => {
  if (!activePage) {
    return {
      home: false,
      credit: false,
      loans: false,
      profile: false,
    };
  }
  const isActiveHome = activePage === "/";
  const isActiveCredit = activePage.includes("credit");
  const isActiveBorrowing = activePage.includes("borrow");
  const isActiveProfile = activePage.includes("account");
  return {
    home: isActiveHome,
    credit: isActiveCredit,
    loans: isActiveBorrowing,
    profile: isActiveProfile,
  };
};
