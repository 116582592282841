import { FinancialMoodState } from "../types/financial-mood";
import {
  FinancialMoodActionTypes,
  FinancialMoodGetSuccessAction,
  FinancialMoodToggleModalAction,
  FinancialMoodUpdateSuccessAction,
} from "./financial-mood.actions";
import { INITIAL_FINANCIAL_MOOD_STATE } from "./financial-mood.constants";

export const financialMoodReducer = (
  state: FinancialMoodState = INITIAL_FINANCIAL_MOOD_STATE,
  action:
    | FinancialMoodGetSuccessAction
    | FinancialMoodUpdateSuccessAction
    | FinancialMoodToggleModalAction
): FinancialMoodState => {
  switch (action.type) {
    case FinancialMoodActionTypes.GET_SUCCESS:
      return action.financialMood;

    case FinancialMoodActionTypes.UPDATE_SUCCESS:
      return { ...state, financialMoodResponse: action.financialMood };

    case FinancialMoodActionTypes.TOGGLE_MODAL:
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      };

    default:
      return state;
  }
};
