import { store } from "../../store";
import { AppInsightsError } from "../../types/analytics";
import { AMPLITUDE_APPLICATION } from "../analytics/analytics.consts";
import { EXCEPTION_ERROR_EVENT, SERVER_ERROR } from "./app-insights.consts";
import { appInsights } from "./app-insights";

export const createAppInsightsEvent = (eventProperty: AppInsightsError) => {
  const state = store.getState();
  /* istanbul ignore next - document and location are global */
  return {
    ...eventProperty,
    page: document?.title,
    url: location?.pathname,
    userId: state.userState.profile?.sub,
    application: AMPLITUDE_APPLICATION,
  };
};

export const sendAppInsightsServerError = (
  errorInfo: string,
  errorType: string
) => {
  const appInsightsError: AppInsightsError = {
    category: SERVER_ERROR,
    info: errorInfo,
    type: errorType,
  };
  appInsights.trackEvent(
    EXCEPTION_ERROR_EVENT,
    createAppInsightsEvent(appInsightsError)
  );
};
