import { ContentCardState } from "../types/content-card";
import {
  ContentCardsAction,
  UPDATE_CONTENT_CARDS,
} from "./content-card.actions";
import { sortContentCards } from "./content-card.utils";

export const contentCardReducer = (
  state: ContentCardState = { contentCards: [] },
  action: ContentCardsAction
) => {
  switch (action.type) {
    case UPDATE_CONTENT_CARDS: {
      const sortedContentCards = sortContentCards([...action.contentCards]);
      return {
        contentCards: sortedContentCards,
      };
    }
    default:
      return state;
  }
};
