import { Action, Icon } from "@wisr/react-ui";
import { Link, navigate } from "gatsby";
import React from "react";
import { useGetLoansCount } from "../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.hooks";
import breadcrumbStyle from "./breadcrumbs.scss";

interface BreadcrumbProps {
  activePage?: string;
}

export const BreadcrumbComponent: React.FC<BreadcrumbProps> = ({
  activePage,
}) => {
  const loanCount = useGetLoansCount();
  const path = activePage
    ? activePage.split("/").filter((val) => val !== "")
    : [];
  let returnText = "";
  let returnLink: string | undefined = undefined;
  // Use a word bank
  switch (path[0]) {
    case "credit":
      returnText = " to scores";
      returnLink = "/credit";
      break;
    case "borrowing":
      returnText = " to loans";
      returnLink = "/borrowing";
      break;
    default:
      break;
  }

  if (returnLink === "/borrowing" && loanCount < 2) {
    return null;
  }
  return (
    <div className="breadcrumbs">
      <style jsx>{breadcrumbStyle}</style>
      {
        // If current is one page away from home
        path.length > 0 &&
          (path.length === 1 ? (
            <div className="show-mobile">
              <Action size="small">
                <Link to="/">
                  <Icon name="navigation-arrow-left" size="sm" legacy={true} />
                  Home
                </Link>
              </Action>
            </div>
          ) : (
            <Action size="small">
              <Link
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault();
                  returnLink ? navigate(returnLink) : navigate(-1);
                }}
                to={returnLink ? returnLink : "#"}
              >
                <Icon name="navigation-arrow-left" size="sm" legacy={true} />
                Back{returnText}
              </Link>
            </Action>
          ))
      }
    </div>
  );
};
