import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { DASH_API } from "../../shared/url.consts";
import { RateOffer } from "../../types/rate-offer";

export const returnRateOffer = async () => {
  return axios.get<RateOffer[]>(`${DASH_API}/Customer/RateOffers`);
};

export const returnRateOfferResponse = async () => {
  try {
    const response = await returnRateOffer();
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.RATE_OFFER
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching rate offers: ${err}`,
      AppInsightsErrorType.RATE_OFFER
    );
  }
};

export const fetchRateOffer = createAsyncThunk(
  "rate-offer/get",
  returnRateOfferResponse
);
