import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  ApplicationHeaderState,
  ApplicationHeaderStore,
} from "../../types/application-header";
import { fetchApplicationHeader } from "./application-header.thunk";

const initialApplicationHeaderState: ApplicationHeaderState = {
  status: FetchStatus.IDLE,
  applicationHeader: null,
};

export const applicationHeaderSlice = createSlice({
  name: "applicationHeader",
  initialState: initialApplicationHeaderState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchApplicationHeader.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchApplicationHeader.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.applicationHeader = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "application header get failed";
        }
      })
      .addCase(fetchApplicationHeader.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export default applicationHeaderSlice.reducer;

export const selectApplicationHeaderState = (store: ApplicationHeaderStore) =>
  store.applicationHeaderState;
