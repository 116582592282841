import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import { LoanOfferState, LoanOfferStore } from "../../types/loan-offer";
import { fetchLoanOffer } from "./loan-offer.thunk";

const initialLoanOfferState: LoanOfferState = {
  status: FetchStatus.IDLE,
  loanOffers: [],
};

export const loanOfferSlice = createSlice({
  name: "loanOffer",
  initialState: initialLoanOfferState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLoanOffer.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchLoanOffer.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.loanOffers.push(action.payload);
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "loan offer get failed";
        }
      })
      .addCase(fetchLoanOffer.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export default loanOfferSlice.reducer;

export const selectLoanOfferState = (store: LoanOfferStore) =>
  store.loanOfferState;
