import { isBrowser } from "@wisr/common";
import { Loader, Wrapper } from "@wisr/react-ui";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Layout } from "../layout/layout.component";
import { MinimalLayoutComponent } from "../layout/minimal/minimal-layout.component";
import { ErrorBoundary } from "../shared/error-boundary/error-boundary";
import { FEATUREFLAG_KEY } from "../shared/flag.consts";
import { GatsbyPageProps } from "../types/gatsby";
import { userGetSuccessAction } from "../user/user.actions";
import {
  getUser,
  isLoggedIn,
  registerRedirect,
  signInRedirect,
  storeUser,
  signinSilent,
  getLdUser,
} from "./auth.utils";
import loginAuthService from "./login/login-auth.service";
import { navigate } from "gatsby";
import { AppInsightsError } from "../types/analytics";
import {
  EXCEPTION_ERROR_EVENT,
  LOGIN_ERROR,
  LOGIN_ERROR_TYPE,
} from "../shared/insights/app-insights.consts";
import { appInsights } from "../shared/insights/app-insights";
import { createAppInsightsEvent } from "../shared/insights/app-insights.utils";

interface AuthProps extends GatsbyPageProps {
  custom404?: React.ReactNode;
}
export const AuthComponent: React.FC<AuthProps> = ({
  children,
  location,
  custom404,
  path,
}) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const dispatch = useDispatch();

  const fixedPath = path?.replace(/\//g, "") || "";

  const isMinimalLayout = [
    "creditcreate-profile",
    "creditverification",
    "creditverification-success",
  ].includes(fixedPath);

  const isNoLayout =
    !!custom404 ||
    !path ||
    ["404", "silent-refresh", "login"].includes(fixedPath);

  const sendError = (loginError: any) => {
    const appInsightsError: AppInsightsError = {
      category: LOGIN_ERROR,
      info: loginError,
      type: LOGIN_ERROR_TYPE,
    };
    appInsights.trackEvent(
      EXCEPTION_ERROR_EVENT,
      createAppInsightsEvent(appInsightsError)
    );
  };

  React.useEffect(() => {
    if (isBrowser()) {
      if (fixedPath === "register") {
        /**
         * Dont care if the user is logged in or not, they will
         * be taken to /login/ anyways if they are
         */
        registerRedirect();
      } else if (fixedPath !== "login") {
        isLoggedIn()
          .then((user) => {
            if (!user) {
              const url = new URL(window.location.href);
              const searchParams = new URLSearchParams(url.search);
              const refreshToken = searchParams.get("token");
              const authTime = searchParams.get("authtime");
              const customerId = searchParams.get("customerid");

              if (
                refreshToken != null &&
                authTime != null &&
                customerId != null
              ) {
                storeUser(refreshToken, authTime, customerId, "refresh_token");
                signinSilent()
                  .then(() => {
                    loginAuthService.getUser()?.then(() => {
                      navigate("/login");
                    });
                  })
                  .catch(() => {
                    sendError("signinSilent error");
                    signInRedirect();
                  });
              } else if (fixedPath === "application") {
                return;
              } else {
                signInRedirect();
              }
            } else {
              const curUser = getUser();
              if (curUser?.info) {
                dispatch(userGetSuccessAction(curUser));
                setLoggedIn(true);
              } else {
                signInRedirect();
              }
            }
          })
          .catch(() => signInRedirect());
      }
    }
  }, [fixedPath, dispatch]);

  React.useEffect(() => {
    if (location?.state?.login) {
      setLoggedIn(true);
    }
  }, [location?.state]);

  if (loggedIn && location && location?.pathname.startsWith("/quote")) {
    return <>{children}</>;
  }

  return !isNoLayout ? (
    loggedIn ? (
      isMinimalLayout ? (
        <MinimalLayoutComponent>{children}</MinimalLayoutComponent>
      ) : (
        <Layout activePage={path}>{children}</Layout>
      )
    ) : (
      <ErrorBoundary>
        <Wrapper>
          <Loader loading />
        </Wrapper>
      </ErrorBoundary>
    )
  ) : (
    <ErrorBoundary>
      <>{children}</>
    </ErrorBoundary>
  );
};

export const AuthWithLD = withLDProvider({
  clientSideID: FEATUREFLAG_KEY,
  user: getLdUser(),
})(AuthComponent);
