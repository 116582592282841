import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import { fetchEstimateAccept } from "./estimate-accept.thunk";
import {
  EstimateAcceptState,
  EstimateAcceptStore,
} from "../../types/estimate-accept";

const initialEstimateAcceptState: EstimateAcceptState = {
  status: FetchStatus.IDLE,
};

export const estimateAcceptSlice = createSlice({
  name: "estimateAccept",
  initialState: initialEstimateAcceptState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchEstimateAccept.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchEstimateAccept.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.estimateAccept = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "estimate accept post failed";
        }
      })
      .addCase(fetchEstimateAccept.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export default estimateAcceptSlice.reducer;

export const selectEstimateAcceptState = (store: EstimateAcceptStore) =>
  store.estimateAcceptState;
