import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { DASH_API } from "../../shared/url.consts";
import { TermsResponse } from "../../types/terms";

export const returnRateEstimateTerms = async () => {
  return axios.get<TermsResponse>(`${DASH_API}/rateestimate/terms-consent`);
};

export const returnRateEstimateTermsResponse = async () => {
  try {
    const response = await returnRateEstimateTerms();
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.RATE_ESTIMATE_TERMS
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching rate estimate terms: ${err}`,
      AppInsightsErrorType.RATE_ESTIMATE_TERMS
    );
  }
};

export const fetchRateEstimateTerms = createAsyncThunk(
  "rate-estimate-terms/get",
  returnRateEstimateTermsResponse
);
