import { ClientId } from "../../types/auth";

export const ClientIdActionTypes = {
  UPDATE: "user/client-id/update",
} as const;

export type ClientIdAction = ReturnType<typeof clientIdUpdateAction>;

export type ClientIdUpdateAction = ReturnType<typeof clientIdUpdateAction>;
export function clientIdUpdateAction(clientId: ClientId) {
  return {
    type: ClientIdActionTypes.UPDATE,
    clientId,
  };
}
