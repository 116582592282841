import {
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { AxiosResponse } from "axios";
import { Epic, ofType } from "redux-observable";
import { concat, from, Observable, of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import axios from "../../shared/axios.utils";
import { CUSTOMER_API } from "../../shared/url.consts";
import { AppAction } from "../../store";
import { WisrProfile } from "./wisr-profile";
import {
  WisrProfileGetActionType,
  wisrProfileGetSuccessAction,
} from "./wisr-profile.actions";

export const GETRPROFILE_WISR_LOADING = "GETRPROFILE_WISR_LOADING";

export const getWisrProfile: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(WisrProfileGetActionType),
    concatMap(() =>
      concat(
        of(loadingStartAction(GETRPROFILE_WISR_LOADING)),
        getWisrProfileApi().pipe(
          map((profile) => wisrProfileGetSuccessAction(profile))
        ),
        of(loadingSuccessAction(GETRPROFILE_WISR_LOADING))
      ).pipe(
        catchError((e) => of(loadingFailAction(GETRPROFILE_WISR_LOADING, e)))
      )
    )
  );

function getWisrProfileApi(): Observable<WisrProfile> {
  return from(axios.get<WisrProfile>(`${CUSTOMER_API}/customers`)).pipe(
    map((res: AxiosResponse) => res.data)
  );
}
