const config = {
  SHOW_DEBUG_TOOLS: process.env.GATSBY_SHOW_DEBUG_TOOLS === "true",
  CREDIT_SENSE_KEY_V1: process.env.GATSBY_CREDIT_SENSE_KEY_V1,
  CREDIT_SENSE_KEY_V2: process.env.GATSBY_CREDIT_SENSE_KEY_V2,
  APP_FORM_LAUNCHDARKLY_CLIENTID:
    process.env.GATSBY_APP_FORM_LAUNCHDARKLY_CLIENTID,
};
export function getConfig() {
  return config;
}

export const isDebugMode = () => {
  return config.SHOW_DEBUG_TOOLS;
};
