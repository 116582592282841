import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  HomeProductsState,
  HomeProductsStore,
} from "../../types/home-products";
import { fetchHomeProducts } from "./home-products.thunk";

const initialHomeProductsState: HomeProductsState = {
  status: FetchStatus.IDLE,
};

export const homeProductsSlice = createSlice({
  name: "homeProducts",
  initialState: initialHomeProductsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchHomeProducts.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchHomeProducts.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.homeProducts = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "home products get failed";
        }
      })
      .addCase(fetchHomeProducts.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export const selectHomeProductsState = (store: HomeProductsStore) =>
  store.homeProductsState;

export default homeProductsSlice.reducer;
