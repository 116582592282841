/* istanbul ignore file - Why do we ignore this? */
import { isBrowser } from "@wisr/common";
import { Log, User, UserManager, WebStorageStateStore } from "oidc-client";
import { IDENTITY_CONFIG, METADATA_OIDC } from "../../oidc-settings";
import { isDebugMode } from "../../shared/config.utils";
import { CLIENT_ID } from "../../shared/oidc.consts";
import { store } from "../../store";
import { userStateExpiredAction } from "../../user/user.actions";

class AuthService {
  public pendingRenewal: null | Promise<any> = null;
  private currentUserManager: null | UserManager = this.setUserManager();

  constructor() {
    Log.logger = console;
    Log.level = isDebugMode() ? Log.DEBUG : Log.ERROR;
  }

  setUserManager() {
    if (isBrowser()) {
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      const clientId = searchParams.get("token")
        ? CLIENT_ID.APP
        : CLIENT_ID.DASHBOARD;
      return new UserManager({
        userStore: new WebStorageStateStore({ store: window.sessionStorage }),
        ...{ ...IDENTITY_CONFIG, client_id: clientId },
        metadata: {
          ...METADATA_OIDC,
        },
      });
    } else {
      return null;
    }
  }

  public get userManager() {
    this.currentUserManager?.events.addUserUnloaded(() => {
      // Clear the local user object
      sessionStorage.clear();
    });
    this.currentUserManager?.events.addAccessTokenExpired(() => {
      // Dispatch a call anyways in case we may need to handle it
      store.dispatch(userStateExpiredAction());
      this.renewToken();
    });
    return this.currentUserManager;
  }

  querySessionStatus() {
    return this.userManager?.querySessionStatus() || null;
  }

  async signinRedirectCallback() {
    return this.userManager?.signinRedirectCallback() || null;
  }

  getUser() {
    return this.userManager?.getUser() || null;
  }

  signinSilent(redirect?: string) {
    if (redirect) {
      const redirectUrl = `${redirect}${window.location.search}`;
      sessionStorage.setItem("redirectUrl", redirectUrl);
    }

    return this.userManager?.signinSilent() || null;
  }

  async signinSilentCallback() {
    return this.userManager?.signinSilentCallback() || null;
  }

  signinRedirect(redirect?: string) {
    if (redirect) {
      const redirectUrl = `${redirect}${window.location.search}`;
      sessionStorage.setItem("redirectUrl", redirectUrl);
    } else if (window.location.search) {
      const redirectUrl = `${window.location.origin}${window.location.search}`;
      sessionStorage.setItem("redirectUrl", redirectUrl);
    }

    if (isBrowser()) {
      return this.userManager?.signinRedirect() || null;
    } else {
      return "";
    }
  }

  revokeAccessToken() {
    return this.userManager?.revokeAccessToken() || null;
  }

  async registerRedirect() {
    // Append any query params
    const query = window.location.search.replace("?", "&");

    // Since there is no native register, lets use the wisrcredit one
    const signin = await this.userManager?.createSigninRequest();
    const uri = new URL(signin?.url || "");
    const returnUrl = encodeURIComponent(uri.pathname + uri.search);

    const registerUri = `${IDENTITY_CONFIG.authority}/Account/register?returnUrl=${returnUrl}${query}`;

    window.location.href = registerUri;
  }

  async openForgotPasswordPage() {
    const signin = await this.userManager?.createSigninRequest();
    const uri = new URL(signin?.url || "");
    const returnUrl = encodeURIComponent(uri.pathname + uri.search);

    const registerUri = `${IDENTITY_CONFIG.authority}/Account/ForgotPassword?returnUrl=${returnUrl}`;

    Object.assign(document.createElement("a"), {
      target: "_blank",
      rel: "noopener noreferrer",
      href: registerUri,
    }).click();
  }

  renewToken() {
    if (this.pendingRenewal === null) {
      this.pendingRenewal =
        this.userManager?.signinSilent().then(
          (res: User) => {
            this.pendingRenewal = null;
            return res;
          },
          (err: any) => {
            this.pendingRenewal = null;
            throw err;
          }
        ) || null;
    }

    return this.pendingRenewal;
  }

  removeUser() {
    return this.userManager?.removeUser() || null;
  }

  signoutRedirect() {
    return this.userManager?.signoutRedirect() || null;
  }

  clearStaleState() {
    return this.userManager?.clearStaleState() || null;
  }

  storeUser(oidcUser: User) {
    return this.userManager?.storeUser(oidcUser) || null;
  }
}

export default new AuthService();
