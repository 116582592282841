import {
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { AxiosResponse } from "axios";
import { Epic, ofType } from "redux-observable";
import { concat, from, Observable, of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import axios from "../../shared/axios.utils";
import { DASH_API } from "../../shared/url.consts";
import { AppAction } from "../../store";
import {
  RequestPayoutPostActionType,
  requestPayoutPostSuccessAction,
} from "./request-payout-form.actions";
import { RequestPayoutData } from "./request-payout-form.format";

export const REQUESTPAYOUT_POST_LOADING = "REQUESTPAYOUT_POST_LOADING";

export const postRequestPayoutForm: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(RequestPayoutPostActionType),
    concatMap((action) =>
      concat(
        of(loadingStartAction(REQUESTPAYOUT_POST_LOADING)),
        getHistoryCreditBorrowing(action.data).pipe(
          map(() => requestPayoutPostSuccessAction())
        ),
        of(loadingSuccessAction(REQUESTPAYOUT_POST_LOADING))
      ).pipe(
        catchError((e) => of(loadingFailAction(REQUESTPAYOUT_POST_LOADING, e)))
      )
    )
  );

function getHistoryCreditBorrowing(data: RequestPayoutData): Observable<void> {
  return from(axios.post(`${DASH_API}/Portfolio/payoutletter`, data)).pipe(
    map((res: AxiosResponse) => res.data)
  );
}
