import { User } from "oidc-client";

export const CurrentResidentialAddress = "CurrentResidentialAddress";

export interface UserMetaData extends User {
  hasCreditAccount: boolean;
  loanId: number;
  loanCount: number;
  applicationCount: number;
  partnerReferrer?: string;
  expired: boolean;
  info:
    | {
        firstName: string | null;
        lastName: string | null;
      }
    | undefined;
}

export interface CustomerAddress {
  personAddressId?: string;
  unit: string;
  streetNumber: string;
  streetName: string;
  streetType: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
  typeOfAddress: typeof CurrentResidentialAddress;
  livingArrangements: string;
  startDate: string;
  endDate: string | null;
}

export interface ProfileUpdateRequest {
  wisrCustomerId: string;
  versionNumber: number;
}
