import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  fetchLoanApplication,
  saveLoanApplication,
  submitLoanApplication,
} from "./loan-application.thunk";
import {
  LoanApplicationState,
  LoanApplicationStore,
} from "../../types/loan-application";
import { mapToLoanApplicationForm } from "./loan-application.utils";

const initialLoanApplicationState: LoanApplicationState = {
  getStatus: FetchStatus.IDLE,
  putStatus: FetchStatus.IDLE,
  postStatus: FetchStatus.IDLE,
};

export const unexpectedErrorMessage = (error?: string) => {
  return [
    {
      fieldName: "unexpectedError",
      errorMessage: [
        `An unexpected error has occured while trying to submit your application.${
          error ? ` ${error}` : ""
        }`,
      ],
    },
  ];
};

export const loanApplicationSlice = createSlice({
  name: "loanApplication",
  initialState: initialLoanApplicationState,
  reducers: {
    clearLoanApplicationErrors: (state) => {
      return {
        ...state,
        error: undefined,
      };
    },
    clearLoanApplicationData: (state) => {
      return {
        ...state,
        getStatus: FetchStatus.IDLE,
        loanApplication: undefined,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLoanApplication.pending, (state) => {
        state.getStatus = FetchStatus.LOADING;
      })
      .addCase(fetchLoanApplication.fulfilled, (state, action) => {
        if (action.payload) {
          state.getStatus = FetchStatus.SUCCESS;
          state.loanApplication = mapToLoanApplicationForm(action.payload);
        } else {
          state.getStatus = FetchStatus.FAILED;
          state.error = "loan application get failed";
        }
      })
      .addCase(fetchLoanApplication.rejected, (state, action) => {
        state.getStatus = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      })
      .addCase(saveLoanApplication.pending, (state) => {
        state.putStatus = FetchStatus.LOADING;
      })
      .addCase(saveLoanApplication.fulfilled, (state, action) => {
        if (action.payload) {
          state.putStatus = FetchStatus.SUCCESS;
        } else {
          state.putStatus = FetchStatus.FAILED;
          state.error = "loan application save failed";
        }
      })
      .addCase(saveLoanApplication.rejected, (state, action) => {
        state.putStatus = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      })
      .addCase(submitLoanApplication.pending, (state) => {
        state.postStatus = FetchStatus.LOADING;
      })
      .addCase(submitLoanApplication.fulfilled, (state, action) => {
        if (action.payload) {
          if (action.payload.errorFields) {
            state.postStatus = FetchStatus.FAILED;
            state.error = action.payload.errorFields;
          } else if (action.payload.wisrApplicationId) {
            state.postStatus = FetchStatus.SUCCESS;
            state.wisrApplicationId = action.payload.wisrApplicationId;
          } else {
            state.postStatus = FetchStatus.FAILED;
            state.error = unexpectedErrorMessage();
          }
        } else {
          state.postStatus = FetchStatus.FAILED;
          state.error = unexpectedErrorMessage();
        }
      })
      .addCase(submitLoanApplication.rejected, (state, action) => {
        state.postStatus = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = unexpectedErrorMessage(action.error.message);
        } else {
          state.error = unexpectedErrorMessage();
        }
      });
  },
});

export const { clearLoanApplicationErrors, clearLoanApplicationData } =
  loanApplicationSlice.actions;
export const selectLoanApplicationState = (store: LoanApplicationStore) =>
  store.loanApplicationState;

export default loanApplicationSlice.reducer;
