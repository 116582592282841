import { Wrapper } from "@wisr/react-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { HeaderNavigationComponent } from "../../navigation/header/header-navigation.component";
import { Banner } from "../../shared/banner/banner";
import { HelmetSeoComponent } from "../helmet-seo.component";
import style from "./minimal-layout.scss";

export const MinimalLayoutComponent: React.FunctionComponent = ({
  children,
}) => {
  const [domReady, setDomReady] = useState(false);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const { dashboardBanner } = useFlags();

  React.useEffect(() => {
    if (headerRef.current) {
      // Triggers re-render
      setDomReady(true);
    }
  }, [headerRef]);

  return (
    <Wrapper>
      <style jsx>{style}</style>
      <div className="minimal-layout">
        <HelmetSeoComponent
          title="Get your scores"
          description="The onestop dashboard for all your Wisr services"
        />
        {dashboardBanner && <Banner banner="info">{dashboardBanner}</Banner>}
        <div className="header" ref={headerRef}>
          {domReady && headerRef.current && (
            <HeaderNavigationComponent
              minimal
              headerElement={headerRef.current}
            />
          )}
        </div>
        {children}
      </div>
    </Wrapper>
  );
};
