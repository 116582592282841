/* istanbul ignore file - For Debugging test only */
import React from "react";
import packageInfo from "../../package.json";

const version = packageInfo.version;

console.info(`Version: ${version}`);
export const VersionComponent: React.FC = () => (
  <div>
    <style jsx>{`
      div {
        background: hotpink;
        padding: 0.5rem;
        position: fixed;
        bottom: 0;
        z-index: 99;
      }
    `}</style>
    Version: {version}
  </div>
);
