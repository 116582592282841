import { Maybe } from "../../../generated/graphql-types";
import { ScoreProviders } from "../../types/credit";
import { CreditInsightsPageResponse } from "./credit-insights";

export type InsightsCreditPageAction =
  | InsightsCreditPageGetAction
  | InsightsCreditPageGetSuccessAction;

export const InsightsCreditPageGetActionType = "insights/credit/get" as const;
export type InsightsCreditPageGetAction = ReturnType<
  typeof insightsCreditPageGetAction
>;
export function insightsCreditPageGetAction(
  provider: Maybe<ScoreProviders> | undefined
) {
  return {
    type: InsightsCreditPageGetActionType,
    provider,
  };
}
export const InsightsCreditPageGetSuccessActionType =
  "insights/credit/get/success" as const;
export type InsightsCreditPageGetSuccessAction = ReturnType<
  typeof insightsCreditPageGetSuccessAction
>;
export function insightsCreditPageGetSuccessAction(
  insights: CreditInsightsPageResponse,
  provider: ScoreProviders | null | undefined
) {
  return {
    type: InsightsCreditPageGetSuccessActionType,
    insights,
    provider,
  };
}
