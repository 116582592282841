import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../../../shared/redux.consts";
import {
  MobileVerificationCodeState,
  MobileVerificationCodeStore,
} from "../../../../types/mobile-verification";
import { fetchMobileVerificationCode } from "./verification-code.thunk";

const initialVerificationCodeState: MobileVerificationCodeState = {
  isVerified: false,
  status: FetchStatus.IDLE,
};

export const mobileVerificationCodeSlice = createSlice({
  name: "mobileVerificationCode",
  initialState: initialVerificationCodeState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMobileVerificationCode.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchMobileVerificationCode.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.status = FetchStatus.SUCCESS;
          state.isVerified = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.isVerified = false;
          state.error = "error fetching verification result";
        }
      })
      .addCase(fetchMobileVerificationCode.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export default mobileVerificationCodeSlice.reducer;

export const selectMobileVerificationCodeState = (
  store: MobileVerificationCodeStore
) => store.mobileVerificationCodeState;
