import {
  CreditScoreResponse,
  CreditScoresHome,
} from "../../../types/score-credit-widget";

export type ScoreCreditAction =
  | ScoreCreditGetAction
  | ScoreCreditGetSuccessAction
  | ScoreCreditHomeGetSuccessAction
  | ScoreCreditFormSubmitAction;

export const ScoreCreditGetActionType = "scores/get" as const;
export type ScoreCreditGetAction = ReturnType<typeof scoreCreditGetAction>;
export function scoreCreditGetAction() {
  return {
    type: ScoreCreditGetActionType,
  };
}

export const ScoreCreditGetSuccessActionType = "scores/get/success" as const;
export type ScoreCreditGetSuccessAction = ReturnType<
  typeof scoreCreditGetSuccessAction
>;
export function scoreCreditGetSuccessAction(
  creditScoreDetails: CreditScoreResponse
) {
  return {
    type: ScoreCreditGetSuccessActionType,
    ...creditScoreDetails,
  };
}

export const ScoreCreditHomeGetSuccessActionType =
  "scores-home/get/success" as const;
export type ScoreCreditHomeGetSuccessAction = ReturnType<
  typeof scoreCreditHomeGetSuccessAction
>;
export function scoreCreditHomeGetSuccessAction(
  creditScoresHome: CreditScoresHome
) {
  return {
    type: ScoreCreditHomeGetSuccessActionType,
    ...creditScoresHome,
  };
}

export const ScoreCreditFormSubmitActionType = "scores/form/submit" as const;
export type ScoreCreditFormSubmitAction = ReturnType<
  typeof scoreCreditFormSubmitAction
>;
export function scoreCreditFormSubmitAction() {
  return {
    type: ScoreCreditFormSubmitActionType,
  };
}
