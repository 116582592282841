import { Modal, Wrapper } from "@wisr/react-ui";
import logo from "../../assets/images/wisr-logo.svg";
import React from "react";
import localStyle from "./error-boundary-popup.scss";

interface Props {
  onClose?: () => void;
  content?: string | React.ReactNode;
  actions?: React.ReactNode[];
}

export const ErrorBoundaryPopup: React.FunctionComponent<Props> = (props) => {
  const {
    onClose = () => {
      return;
    },
    content,
    actions,
  } = props;
  return (
    <Wrapper>
      <Modal open={true} onClose={onClose} selfClose={false} actions={actions}>
        <style jsx>{localStyle}</style>
        <div className="popup error-popup">
          <div className="oops-content grid">
            <h2>Oops, something went wrong.</h2>
            <div className="grid-8">{content}</div>
            <div className="grid-4">
              <div className="image">
                <img alt="Wisr Owl" src={logo} width="214" height="100" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Wrapper>
  );
};
