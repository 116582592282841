import React from "react";
import { BRAND_WEBSITE } from "../../shared/url.consts";

export const CreditProfileFormDefaults = {
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  mobile: "",
  verificationCode: "",
  dateOfBirth: "",
  residentialMovedIn: "",
  residentialYearsAt: "",
  residentialMonthsAt: "",
  previousMovedIn: "",
  previousYearsAt: "",
  previousMonthsAt: "",
  residentialLivingSituation: "",
  previousLivingSituation: "",
  relationshipStatus: "",
  residentialAddress: "",
  identityType1: "",
  medicareNumber: "",
  medicareColour: "",
  medicareExpiryDate: "",
  medicareExpiryDateGreen: "",
  medicareReferenceNumber: "",
  passportNumber: "",
  passportCountry: "",
  driversLicense: "",
  licenceState: "",
  licenceCardNumber: "",
  numFinancialDependents: "",
  residentialUnitNumber: "",
  residentialStreetNumber: "",
  residentialStreetName: "",
  residentialStreetType: "",
  residentialSuburb: "",
  residentialState: "",
  residentialPostcode: "",
};

export const SERVER_ERROR_MESSAGE: {
  [key: string]: string;
} = {
  SAMEDETAILS: "Customer with similar details has been found",
};

export const FORM_ERROR_MESSAGE: {
  [key: string]: string;
} = {
  SAMEDETAILS:
    "It looks like you already have an account with us. Contact us if you think this is a mistake or it wasn't you: contact@wisr.com.au",
  DEFAULT: "Error trying to submit form. Please try again or contact support",
};

export const SAME_DETAILS_MESSAGE = (
  <>
    It looks like you already have an account with us. Please{" "}
    <a
      /* eslint-disable react/jsx-no-target-blank*/
      href={`${BRAND_WEBSITE}/contact-us?subject=Credit%20Scores`}
      target="_blank"
      rel="noopener nofollow"
    >
      Contact Us
    </a>{" "}
    with the ID details (Drivers Licence/ Passport/ Medicare numbers) you are
    trying if you think this is a mistake or wasn&apos;t you.
  </>
);
