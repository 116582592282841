import { applicationFormDefaults } from "./application-form.constants";

export declare type Application = typeof applicationFormDefaults;

export enum Frequency {
  Annual = "Annual",
  Monthly = "Monthly",
  Fortnightly = "Fortnightly",
  Weekly = "Weekly",
}

export type Status = {
  kind: "error" | "info" | "warning" | "success";
  message: string;
};
export type SectionErrors = {
  [key: string]: Status[] | undefined;
};

export type ResidencyType = {
  label: string;
  value: string;
};
