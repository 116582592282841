export const brandYellowColour = "#F5DE66",
  labelColour = "#617280";

export const HISTORY_MONTH_LIMIT = 12;

export enum LOAN_TRANSACTIONS_CODES {
  DISHONOUR = 9920,
  FEE_REVERSAL = 210060,
}
export interface BorrowHistoryResponse {
  history: HistoryPayment[];
}

export interface HistoryPayment {
  loanId: number;
  effectiveDate: string;
  processingDate: string;
  transactionCode: number;
  transactionDescription: string;
  debitAmount: number;
  creditAmount: number;
  runningBalance: number;
  arrears: number;
  description: string;
  addedDate: string;
}

export interface BorrowHistoryResponseState {
  [key: number]: HistoryPayment[];
}
