import { HubConnectionBuilder } from "@microsoft/signalr";
import { isBrowser } from "@wisr/common";
import { getUser } from "../../auth/auth.utils";
import { AppInsightsError } from "../../types/analytics";
import { appInsights } from "../insights/app-insights";
import {
  EXCEPTION_ERROR_EVENT,
  LOGIN_ERROR_TYPE,
  SERVER_ERROR,
  USER_ERROR,
} from "../insights/app-insights.consts";
import { createAppInsightsEvent } from "../insights/app-insights.utils";
import { DASH_HUB } from "../url.consts";

export const getCreditScoresConnection = (count: number) => {
  if (count && count > 5) {
    const appInsightsError: AppInsightsError = {
      category: USER_ERROR,
      info: "User not found for SignalR authentication",
      type: LOGIN_ERROR_TYPE,
    };
    appInsights.trackEvent(
      EXCEPTION_ERROR_EVENT,
      createAppInsightsEvent(appInsightsError)
    );
    return;
  }

  if (!getUser || !isBrowser()) {
    return;
  }

  const token = getUser()?.access_token;
  if (token) {
    const scoreConnection = new HubConnectionBuilder()
      .withUrl(`${DASH_HUB}/customer-profile?access_token=${token}`, {
        accessTokenFactory: /* istanbul ignore next */ () => token,
      })
      .withAutomaticReconnect()
      .build();
    scoreConnection.start().catch((error) => {
      const appInsightsError: AppInsightsError = {
        category: SERVER_ERROR,
        info: error,
        type: "SignalR connection not established",
      };
      appInsights.trackEvent(
        EXCEPTION_ERROR_EVENT,
        createAppInsightsEvent(appInsightsError)
      );
    });

    return scoreConnection;
  } else {
    setTimeout(() => {
      return getCreditScoresConnection(count + 1);
    }, GET_USER_DELAY);
  }
};

const GET_USER_DELAY = 1000;
