import {
  calcFromDate,
  dateToMovedIn,
  isDefined,
  movedInToDateString,
  UseFormTools,
} from "@wisr/common";
import { AddressApiResponse } from "@wisr/react-ui";
import dayjs from "dayjs";
import CustomParse from "dayjs/plugin/customParseFormat";
import isLeapYear from "dayjs/plugin/isLeapYear";
import { StreetType } from "../../../generated/graphql-types";
import axios from "../../shared/axios.utils";
import { isDebugMode } from "../../shared/config.utils";
import { appInsights } from "../../shared/insights/app-insights";
import {
  EXCEPTION_ERROR_EVENT,
  SERVER_ERROR,
  USER_ERROR,
} from "../../shared/insights/app-insights.consts";
import { createAppInsightsEvent } from "../../shared/insights/app-insights.utils";
import { CUSTOMER_API } from "../../shared/url.consts";
import { AppInsightsError } from "../../types/analytics";
import { OcrState } from "../../types/ocr";
import {
  CreditUserProfile,
  CreditUserProfileUpdate,
} from "../../user/credit/credit-user";
import {
  CurrentResidentialAddress,
  CustomerAddress,
  UserMetaData,
} from "../../user/user";
import {
  CreditProfileForm,
  FormResponseError,
} from "../../types/credit-profile-form";
import {
  CreditProfileFormDefaults,
  FORM_ERROR_MESSAGE,
  SAME_DETAILS_MESSAGE,
  SERVER_ERROR_MESSAGE,
} from "./credit-profile-form.constants";
import {
  IdentityTypes,
  medicareTypes,
  PASSPORT_COUNTRY,
} from "./credit-profile-form.options";

dayjs.extend(isLeapYear);
dayjs.extend(CustomParse);

interface ListOption {
  label: string;
  value: string;
}
export interface ParsedAddressType {
  unitNumber: string | null;
  number: string | null;
  street: string | null;
  streetType: string | null;
  suburb: string | null;
  state: string | null;
  postcode: string | null;
  numberFirst: string | null;
  numberLast: string | null;
  streetSuffix: string | null;
  streetLine: string | null;
  buildingName: string | null;
  streetSuffixFull: string | null;
  streetTypeFull: string | null;
  unitType: string | null;
  unitTypeFull: string | null;
  levelType: string | null;
  levelTypeFull: string | null;
  levelNumber: string | null;
}

export const logError = (
  type: string,
  category: typeof USER_ERROR | typeof SERVER_ERROR,
  error: any
) => {
  let info;
  if (error.data && error.data.detail) {
    info = `${error.data.status}: ${error.data.detail}`;
  } else {
    info = "Unspecified/Network error";
  }
  const appInsightsError: AppInsightsError = {
    category,
    info,
    type,
  };
  appInsights.trackEvent(
    EXCEPTION_ERROR_EVENT,
    createAppInsightsEvent(appInsightsError)
  );
};

export const parseMedicareNumber = (number: string) => {
  // Medicare format should be xxxx xxxxx x
  const numberArray = number.split("");
  const validate = number.match(/\d{4,10}/g);
  if (validate && validate[0]) {
    if (number.length > 4) {
      numberArray.splice(4, 0, " ");
    }

    if (number.length > 9) {
      numberArray.splice(10, 0, " ");
    }
  }
  return numberArray.join("");
};

export const convertDateFormat = (
  fieldDOB: Date | string | undefined,
  type?: "month" | "date"
) => {
  if (!fieldDOB) {
    return "";
  }
  // Expecting date to be DD / MM / YYY format
  const stringDate = fieldDOB
    .toString()
    .split("/")
    .reverse()
    .map((s) => s.trim())
    .join("/");

  return type === "month"
    ? dayjs(stringDate).format("YYYY-MM[T]HH:mm:ss[Z]")
    : dayjs(stringDate).format("YYYY-MM-DD[T]HH:mm:ss[Z]");
};

export const convertDateToReadable = (date: string, format?: "month") => {
  if (!dayjs(date).isValid()) {
    return "";
  }
  return format === "month"
    ? dayjs(date).format("MM / YYYY")
    : dayjs(date).format("DD / MM / YYYY");
};

export const loadPostback = /* istanbul ignore next */ (wisrId: string) => {
  // Postback URL Call
  const transactionId = localStorage.getItem("transactionCId");
  const postbackCheck = localStorage.getItem("postbackSent");

  if (transactionId !== null && postbackCheck === "true" && wisrId) {
    const subid = wisrId;
    const postbackUrl = "//au.omgt8.com/aff_goal";

    const axiosNoIntercept = axios.create();

    axiosNoIntercept
      .get(postbackUrl, {
        params: {
          a: "lsr",
          goal_id: 155,
          adv_sub: subid,
          transaction_id: transactionId,
        },
      })
      .then(() => {
        localStorage.removeItem("transactionCid");
        localStorage.removeItem("postbackSent");
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

export const validAddressId = (id: string) => {
  return id !== "" && id !== "00000000-0000-0000-0000-000000000000";
};

export const serverErrorMessages = (error: FormResponseError | undefined) => {
  if (
    error &&
    error.detail &&
    error.detail.includes(SERVER_ERROR_MESSAGE.SAMEDETAILS)
  ) {
    return FORM_ERROR_MESSAGE.SAMEDETAILS;
  }
  return FORM_ERROR_MESSAGE.DEFAULT;
};

export const getSameDetailsError = (error: string) => {
  return error === FORM_ERROR_MESSAGE.SAMEDETAILS
    ? SAME_DETAILS_MESSAGE
    : error;
};

export async function parseAddress(
  address: string
): Promise<AddressApiResponse> {
  return axios
    .get(`${CUSTOMER_API}/AddressLookup/ParseAddress?addressString=${address}`)
    .then((res) => ({
      UnitNumber: res.data.unitNumber,
      Number: res.data.number,
      Street: res.data.street,
      StreetType: res.data.streetType,
      Suburb: res.data.suburb,
      State: res.data.state,
      Postcode: res.data.postcode,
    }));
}

export const checkTotalMonthUnder3Years = (years: string) =>
  years !== "" && +years < 3;

export const genListOption = (option: string) => {
  const list: any[] = [];
  if (option === "years") {
    for (let i = 0; i < 11; i += 1) {
      list.push({
        label: `${i} ${i === 1 ? "year" : "years"}`,
        value: i.toString(),
      });
    }
    list.push({ label: "+10 years", value: "11" });
  }
  if (option === "months") {
    for (let i = 0; i < 12; i += 1) {
      list.push({
        label: `${i} ${i === 1 ? "month" : "months"}`,
        value: i.toString(),
      });
    }
  }
  if (option === "financial") {
    for (let i = 0; i < 11; i += 1) {
      list.push({ label: i.toString(), value: i.toString() });
    }
  }
  if (option === "references") {
    for (let i = 1; i < 10; i += 1) {
      list.push({ label: i.toString(), value: i.toString() });
    }
  }
  return list;
};

export const convertToListOption = (list: { [key: string]: string }) => {
  const listEmpty: ListOption[] = [];
  for (const item in list) {
    listEmpty.push({ label: list[item], value: item });
  }
  return listEmpty;
};

export const validateAddress = (address: string) => {
  return axios
    .get(
      `${CUSTOMER_API}/AddressLookup/validateAddress?addressString=${address}`
    )
    .then((resp) => resp.data);
};

export const autocompleteAddress = (value: string) => {
  return axios
    .get(`${CUSTOMER_API}/AddressLookup/autoComplete?searchString=${value}`)
    .then((resp) => resp.data);
};

export const addressToString = (address: CustomerAddress | undefined) => {
  if (!isDefined(address)) {
    return "";
  }
  const streetAddress = address.unit
    ? `${address.unit}/${address.streetNumber}`
    : address.streetNumber;

  // If address is still empty, assume its not valid
  if (!streetAddress) {
    return "";
  }
  return `${streetAddress} ${address.streetName || ""} ${
    address.streetType || ""
  }, ${address.suburb || ""} ${address.state || ""} ${
    address.postcode || ""
  }`.replace(" ,", ",");
};

export const setIdentificationValues = (
  identificationValue: "identityType1",
  formData: CreditUserProfile,
  dataToSend: CreditUserProfileUpdate
) => {
  // dataToSend has IdentificationValue already set so we will use that to ensure
  // that the ID type is correct
  if (dataToSend[identificationValue] === IdentityTypes.DRIVERS_LICENCE) {
    const licenceData = {
      driversLicense: formData.driversLicense,
      licenceState: formData.licenceState,
      licenceCardNumber: formData.licenceCardNumber,
    };
    return { ...dataToSend, ...licenceData };
  }

  if (dataToSend[identificationValue] === IdentityTypes.MEDICARE_CARD) {
    let expiryDate = "";
    if (formData.medicareColour === "green") {
      if (formData.medicareExpiryDateGreen) {
        const expiry = formData.medicareExpiryDateGreen.replace(/\s+/g, "");
        if (/^[\d]{2}\/[\d]{4}$/.test(expiry)) {
          expiryDate = convertDateFormat(expiry);
        } else {
          logError(
            "Medicare expiry parse error",
            "serverError",
            `Unable to parse medicare expiry date: ${expiry}`
          );
          expiryDate = expiry;
        }
      }
    } else {
      expiryDate = convertDateFormat(formData.medicareExpiryDate);
    }
    const medicareData = {
      medicareNumber: formData.medicareNumber?.replace(/\s+/g, "").trim() || "",
      medicareColour: formData.medicareColour,
      medicareExpiryDate: expiryDate,
      medicareReferenceNumber: formData.medicareReferenceNumber,
    };
    return { ...dataToSend, ...medicareData };
  }

  if (dataToSend[identificationValue] === IdentityTypes.PASSPORT) {
    const passportData = {
      passportNumber: formData.passportNumber,
      passportCountry: PASSPORT_COUNTRY,
    };
    return { ...dataToSend, ...passportData };
  }

  return { ...dataToSend };
};

export const handleAddressCallback = (
  addressString: string
): Promise<CustomerAddress> => {
  return parseAddress(addressString)
    .then((address) => ({
      unit: address.UnitNumber || "",
      streetNumber: address.Number || "",
      streetName: address.Street || "",
      streetType: address.StreetType || "",
      suburb: address.Suburb || "",
      state: address.State || "",
      postcode: address.Postcode || "",
      country: "AUS",
      typeOfAddress:
        CurrentResidentialAddress as typeof CurrentResidentialAddress,
      livingArrangements: "",
      startDate: "",
      endDate: "",
    }))
    .catch((e) => {
      logError("Unable to parse Address", "serverError", e);
      throw e;
    });
};

export const mapManualAddress = (
  customerFormData: CreditProfileForm,
  customerProfileData: CreditUserProfile | undefined
): CustomerAddress => {
  return {
    personAddressId: customerProfileData?.currentAddress?.personAddressId || "",
    unit: customerFormData.residentialUnitNumber || "",
    streetNumber: customerFormData.residentialStreetNumber || "",
    streetName: customerFormData.residentialStreetName || "",
    streetType: customerFormData.residentialStreetType || "",
    suburb: customerFormData.residentialSuburb || "",
    state: customerFormData.residentialState || "",
    postcode: customerFormData.residentialPostcode || "",
    country: "AUS",
    typeOfAddress: CurrentResidentialAddress,
    livingArrangements: customerFormData.residentialLivingSituation,
    startDate: movedInToDateString(customerFormData.residentialMovedIn),
    endDate: null,
  };
};

export const convertMobile = (mob: string) => {
  return isDefined(mob)
    ? mob.replace(/^(04\d{2})(\d{3})(\d{3})$/g, "$1 $2 $3")
    : "";
};

export const getMobileRegex = () => {
  return isDebugMode()
    ? /^(04|02)\d{2} \d{3} \d{3}$/g
    : /^04\d{2} \d{3} \d{3}$/g;
};

export const getVerificationRegex = () => {
  return /^\d{6}$/g;
};

export const formAddressAdapter = (address?: CustomerAddress | null) => ({
  livingArrangements: address?.livingArrangements?.toString() || "",
  startDate: address?.startDate || "",
  endDate: address?.endDate || null,
  singleLineFormat: address ? addressToString(address) : "",
});

export const setFormValues = (
  creditUser: CreditUserProfile | undefined,
  user: UserMetaData,
  setValue: UseFormTools<typeof CreditProfileFormDefaults>["setValue"]
) => {
  if (!creditUser) {
    return;
  }
  const addrCurrent = formAddressAdapter(creditUser.currentAddress);
  setValue("title", creditUser.title);
  setValue("firstName", creditUser.firstName);
  setValue("middleName", creditUser.middleName || "");
  setValue("lastName", creditUser.lastName);
  setValue("email", user.profile?.email || "");
  setValue("mobile", convertMobile(creditUser.mobile));
  setValue("dateOfBirth", convertDateToReadable(creditUser.dateOfBirth));
  setValue("residentialUnitNumber", creditUser.currentAddress?.unit || "");
  setValue(
    "residentialStreetNumber",
    creditUser.currentAddress?.streetNumber || ""
  );
  setValue(
    "residentialStreetName",
    creditUser.currentAddress?.streetName || ""
  );
  setValue("residentialState", creditUser.currentAddress?.state || "");
  setValue("residentialSuburb", creditUser.currentAddress?.suburb || "");
  setValue(
    "residentialStreetType",
    creditUser.currentAddress?.streetType || ""
  );
  setValue("residentialPostcode", creditUser.currentAddress?.postcode || "");
  setValue("residentialLivingSituation", addrCurrent.livingArrangements);
  const currentMovedInDate = dateToMovedIn(addrCurrent.startDate);
  setValue("residentialMovedIn", currentMovedInDate);
  setValue(
    "residentialYearsAt",
    calcFromDate(currentMovedInDate, "year")?.toString() ?? ""
  );
  setValue(
    "residentialMonthsAt",
    calcFromDate(currentMovedInDate, "month")?.toString() ?? ""
  );
  setValue("residentialAddress", addrCurrent.singleLineFormat);
  setValue(
    "numFinancialDependents",
    creditUser.numFinancialDependents.toString()
  );
  setValue("relationshipStatus", creditUser.maritalStatus);

  if (creditUser.lastUpdatedDocumentType) {
    setValue("identityType1", creditUser.lastUpdatedDocumentType);
  } else {
    if (creditUser.medicareNumber) {
      setValue("identityType1", IdentityTypes.MEDICARE_CARD);
    } else if (creditUser.driversLicense) {
      setValue("identityType1", IdentityTypes.DRIVERS_LICENCE);
    } else if (creditUser.passportNumber) {
      setValue("identityType1", IdentityTypes.PASSPORT);
    }
  }

  if (
    creditUser.medicareColour &&
    creditUser.medicareExpiryDate &&
    creditUser.medicareNumber &&
    creditUser.medicareReferenceNumber
  ) {
    setValue("medicareNumber", creditUser.medicareNumber);
    setValue("medicareColour", creditUser.medicareColour);
    setValue(
      "medicareExpiryDate",
      convertDateToReadable(creditUser.medicareExpiryDate)
    );
    setValue("medicareReferenceNumber", creditUser.medicareReferenceNumber);
    if (creditUser.medicareColour === "green") {
      setValue(
        "medicareExpiryDateGreen",
        convertDateToReadable(creditUser.medicareExpiryDate, "month")
      );
    }
  }
  if (creditUser.licenceState && creditUser.driversLicense) {
    setValue("driversLicense", creditUser.driversLicense);
    setValue("licenceState", creditUser.licenceState);
  }
  if (creditUser.licenceCardNumber) {
    setValue("licenceCardNumber", creditUser.licenceCardNumber);
  }
  if (creditUser.passportNumber) {
    setValue("passportNumber", creditUser.passportNumber);
  }
};

export const setFormDefaults = (
  setValue: UseFormTools<typeof CreditProfileFormDefaults>["setValue"]
) => {
  setValue("identityType1", IdentityTypes.DRIVERS_LICENCE);
  setValue("medicareColour", medicareTypes[0].value);
};

export const mapStreetTypes = (streetTypes: StreetType[]) => [
  ...mapStreets(streetTypes.filter((street) => street.common)),
  { value: "", label: "--------------" },
  ...mapStreets(streetTypes.filter((street) => !street.common)),
];

const mapStreets = (streetTypes: StreetType[]) =>
  streetTypes.map((street) => {
    return { value: street.code, label: street.description };
  });

export const mapOcrPrefill = async (
  ocrState: OcrState,
  user: UserMetaData,
  setValue: UseFormTools<typeof CreditProfileFormDefaults>["setValue"]
) => {
  const { mobile, customerData } = ocrState;
  if (!customerData) {
    return;
  }
  setValue("firstName", customerData.firstName || "");
  setValue("middleName", customerData.middleName || "");
  setValue("lastName", customerData.lastName || "");
  setValue("email", user.profile.email || "");
  setValue("mobile", convertMobile(mobile || ""));
  setValue(
    "dateOfBirth",
    convertDateToReadable(customerData.dateOfBirth || "")
  );
  if (customerData.cardType?.toLowerCase() === "driverslicense") {
    setValue("identityType1", IdentityTypes.DRIVERS_LICENCE);
    setValue("driversLicense", customerData.licenceNumber || "");
    setValue("licenceState", customerData.state || "");
  }
  if (customerData.cardType?.toLowerCase() === "passport") {
    setValue("identityType1", IdentityTypes.PASSPORT);
    setValue("passportNumber", customerData.passportNumber || "");
  }

  if (customerData.address) {
    setValue("residentialAddress", customerData.address);
    await handleAddressCallback(customerData.address).then((address) => {
      setValue("residentialUnitNumber", address.unit);
      setValue("residentialStreetNumber", address.streetNumber);
      setValue("residentialStreetName", address.streetName);
      setValue("residentialStreetType", address.streetType);
      setValue("residentialSuburb", address.suburb);
      setValue("residentialState", address.state);
      setValue("residentialPostcode", address.postcode);
    });
  }
};
