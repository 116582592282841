import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  RateEstimateTermsState,
  RateEstimateTermsStore,
} from "../../types/terms";
import { fetchRateEstimateTerms } from "./rate-estimate-terms.thunk";

const initialRateEstimateTermsState: RateEstimateTermsState = {
  status: FetchStatus.IDLE,
};

export const rateEstimateTermsSlice = createSlice({
  name: "rateEstimateTerms",
  initialState: initialRateEstimateTermsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRateEstimateTerms.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchRateEstimateTerms.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.terms = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "rate estimate terms get failed";
        }
      })
      .addCase(fetchRateEstimateTerms.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export const selectRateEstimateTermsState = (store: RateEstimateTermsStore) =>
  store.rateEstimateTermsState;

export default rateEstimateTermsSlice.reducer;
