import React from "react";
import { CreditUserProfile } from "../../user/credit/credit-user";
import { encodeObject } from "../../widgets/borrowing/overview/application/application-overview-borrowing-widget.utils";
import { analyticsClickEvent } from "../analytics/analytics.utils";
import { BRAND_WEBSITE } from "../url.consts";

interface ContactLinkProps {
  loanId?: number | null;
  user?: CreditUserProfile;
  location: string;
  page?: string;
}

export const ContactLink: React.FC<ContactLinkProps> = ({
  loanId,
  location,
  page,
  children,
  user,
}) => {
  const contactUsData = user
    ? {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        mobile: user.mobile,
        loanId,
      }
    : {};
  const pageName = page && { "Page name": page };
  return (
    <a
      href={`${BRAND_WEBSITE}/contact-us?prefill=${encodeObject(
        contactUsData
      )}`}
      target="_blank"
      rel="noopener nofollow"
      onClick={() => {
        analyticsClickEvent("Contact", {
          "Button location": location,
          ...pageName,
        });
      }}
    >
      {children}
    </a>
  );
};
