import { QuoteForm } from "./quote-form";
import { QuoteFormAction, QuoteFormSetActionType } from "./quote-form.actions";

export interface QuoteFormState {
  quoteForm?: QuoteForm;
}

export function quoteFormReducer(
  state: QuoteFormState = {},
  action: QuoteFormAction
) {
  switch (action.type) {
    case QuoteFormSetActionType:
      return {
        quoteForm: action.quoteForm,
      };

    default:
      return state;
  }
}
