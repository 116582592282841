import {
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { AxiosResponse } from "axios";
import { Epic, ofType } from "redux-observable";
import { concat, from, iif, Observable, of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import { Maybe } from "../../../generated/graphql-types";
import axios from "../../shared/axios.utils";
import { CREDIT_API } from "../../shared/url.consts";
import { AppAction } from "../../store";
import { ScoreProviders } from "../../types/credit";
import { CreditInsightsPageResponse } from "./credit-insights";
import {
  InsightsCreditPageGetActionType,
  insightsCreditPageGetSuccessAction,
} from "./credit-insights.actions";

export const GET_INSIGHTS_CREDIT_PAGE_LOADING =
  "GET_INSIGHTS_CREDIT_PAGE_LOADING";

export const getInsightsCreditPage: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(InsightsCreditPageGetActionType),
    concatMap((data) =>
      concat(
        of(loadingStartAction(GET_INSIGHTS_CREDIT_PAGE_LOADING)),
        iif(
          () => data.provider !== undefined,
          getScoreCreditBorrowing(data.provider).pipe(
            map((insights) =>
              insightsCreditPageGetSuccessAction(insights, data.provider)
            )
          ),
          of(
            loadingFailAction(
              GET_INSIGHTS_CREDIT_PAGE_LOADING,
              "Provider not found"
            )
          )
        ),
        of(loadingSuccessAction(GET_INSIGHTS_CREDIT_PAGE_LOADING))
      ).pipe(
        catchError((e) =>
          of(loadingFailAction(GET_INSIGHTS_CREDIT_PAGE_LOADING, e))
        )
      )
    )
  );

function getScoreCreditBorrowing(
  provider: Maybe<ScoreProviders> | undefined
): Observable<CreditInsightsPageResponse> {
  return from(
    axios.get<CreditInsightsPageResponse>(
      `${CREDIT_API}/Dashboard/Insights/${provider}`
    )
  ).pipe(map((res: AxiosResponse) => res.data));
}
