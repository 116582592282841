import { createAction, createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  LoanApplicationStatusState,
  LoanApplicationStatusStore,
} from "../../types/loan-application-status";
import { fetchLoanApplicationStatus } from "./loan-application-status.thunk";

const initialLoanApplicationStatusState: LoanApplicationStatusState = {
  status: FetchStatus.IDLE,
};

export const resetLoanApplicationStatusState = createAction(
  "loan-application-status/reset"
);

export const loanApplicationStatusSlice = createSlice({
  name: "loanApplicationStatus",
  initialState: initialLoanApplicationStatusState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLoanApplicationStatus.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchLoanApplicationStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.loanApplicationStatus = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "loan application status get failed";
        }
      })
      .addCase(fetchLoanApplicationStatus.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      })
      .addCase(resetLoanApplicationStatusState, (state) => {
        state.status = FetchStatus.IDLE;
        state.loanApplicationStatus = undefined;
      });
  },
});

export default loanApplicationStatusSlice.reducer;

export const selectLoanApplicationStatusState = (
  store: LoanApplicationStatusStore
) => store.loanApplicationStatusState;
