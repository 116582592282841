import {
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { AxiosError, AxiosResponse } from "axios";
import { navigate } from "gatsby";
import { Epic, ofType } from "redux-observable";
import { concat, from, Observable, of } from "rxjs";
import { catchError, concatMap, map, tap } from "rxjs/operators";
import { FormResponseError } from "../../types/credit-profile-form";
import { serverErrorMessages } from "../../forms/credit-profile/credit-profile-form.utils";
import {
  amplitudeSendUser,
  analyticsSubmitEvent,
} from "../../shared/analytics/analytics.utils";
import axios from "../../shared/axios.utils";
import { CREDIT_API } from "../../shared/url.consts";
import { AppAction } from "../../store";
import { CreditUserProfile, CreditUserProfileUpdate } from "./credit-user";
import {
  CreditUserActionTypes,
  creditUserGetSuccessAction,
  creditUserPostFailAction,
  creditUserPostSuccessAction,
  creditUserUpdateFailAction,
  creditUserUpdateSuccessAction,
} from "./credit-user.actions";

export const GETUSER_CREDIT_LOADING = "GETUSER_CREDIT_LOADING";
export const UPDATEUSER_CREDIT_LOADING = "UPDATEUSER_CREDIT_LOADING";
export const CREATEUSER_CREDIT_LOADING = "CREATEUSER_CREDIT_LOADING";

export const getCreditUser: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(CreditUserActionTypes.GET),
    concatMap(() =>
      concat(
        of(loadingStartAction(GETUSER_CREDIT_LOADING)),
        getCreditUserReponse().pipe(
          map((user) => creditUserGetSuccessAction(user))
        ),
        of(loadingSuccessAction(GETUSER_CREDIT_LOADING))
      ).pipe(
        catchError((e: AxiosError<FormResponseError>) =>
          concat(
            of(
              loadingFailAction(
                GETUSER_CREDIT_LOADING,
                e.response?.data.title || "Could not get credit profile data"
              )
            ),
            of(creditUserUpdateFailAction())
          )
        )
      )
    )
  );

export const saveCreditUser: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(CreditUserActionTypes.POST),
    concatMap((user) =>
      concat(
        of(loadingStartAction(CREATEUSER_CREDIT_LOADING)),
        saveCreditUserResponse(user.creditUser).pipe(
          map(() => creditUserPostSuccessAction()),
          tap(() => {
            const userData = {
              Title: user.creditUser.title,
              "Date of birth": user.creditUser.dateOfBirth,
              "Living situation":
                user.creditUser.currentAddress?.livingArrangements,
              "Relationship status": user.creditUser.maritalStatus,
              State: user.creditUser.currentAddress?.state,
            };
            amplitudeSendUser("setAmpUser", userData);
            analyticsSubmitEvent("Credit profile form");
            navigate("/credit");
          })
        ),
        of(loadingSuccessAction(CREATEUSER_CREDIT_LOADING))
      ).pipe(
        catchError((e: AxiosError<FormResponseError>) =>
          concat(
            of(
              loadingFailAction(
                CREATEUSER_CREDIT_LOADING,
                serverErrorMessages(e.response?.data)
              )
            ),
            of(creditUserPostFailAction())
          )
        )
      )
    )
  );

export const updateCreditUser: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(CreditUserActionTypes.UPDATE),
    concatMap((action) =>
      concat(
        of(loadingStartAction(UPDATEUSER_CREDIT_LOADING)),
        updateCreditUserResponse(action.creditUser).pipe(
          map(() => creditUserUpdateSuccessAction()),
          tap(() => {
            navigate("/credit");
          })
        ),
        of(loadingSuccessAction(UPDATEUSER_CREDIT_LOADING))
      ).pipe(
        catchError((e: AxiosError<FormResponseError>) =>
          concat(
            of(
              loadingFailAction(
                UPDATEUSER_CREDIT_LOADING,
                serverErrorMessages(e.response?.data)
              )
            ),
            of(creditUserUpdateFailAction())
          )
        )
      )
    )
  );

function getCreditUserReponse(): Observable<CreditUserProfile> {
  return from(
    axios.get<CreditUserProfile>(`${CREDIT_API}/CustomerAccount`)
  ).pipe(map((res) => res.data));
}

function saveCreditUserResponse(
  user: CreditUserProfileUpdate
): Observable<void> {
  return from(axios.post<void>(`${CREDIT_API}/CustomerProfile`, user)).pipe(
    map((res) => res.data)
  );
}

function updateCreditUserResponse(
  user: CreditUserProfileUpdate
): Observable<void> {
  return from(axios.put<void>(`${CREDIT_API}/CustomerAccount`, user)).pipe(
    map((res: AxiosResponse) => res.data)
  );
}
