import { ContentCard, FilteredContentCard } from "../types/content-card";

export const sortContentCards = (
  contentCards: ContentCard[]
): FilteredContentCard[] => {
  const dashboardContentCards: FilteredContentCard[] = contentCards.filter(
    (card): card is FilteredContentCard => card.extras?.client === "dashboard"
  );

  return [...dashboardContentCards]
    .sort((card) => {
      return card.pinned ? -1 : 1;
    })
    .filter((card) => card.title !== "");
};

export const shortenCopy = (
  copy: string | null | undefined,
  length: number
): string => {
  if (!copy) {
    return "";
  }

  const lastSpace = copy.lastIndexOf(" ", length);

  if (copy.length > length) {
    return copy.substring(0, lastSpace) + "...";
  } else {
    return copy;
  }
};
