import axios from "axios";
import {
  ApplicationLoginResponse,
  SendLoginCredentials,
} from "../../types/application-login";
import { DASH_API } from "../../shared/url.consts";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const sendLoginCredentials = async ({
  email,
  password,
}: SendLoginCredentials) => {
  return axios.post<ApplicationLoginResponse>(
    `${DASH_API}/loanApplication/authenticate`,
    {
      Email: email,
      Password: password,
    }
  );
};

export const sendLoginCredentialsResponse = async ({
  email,
  password,
}: SendLoginCredentials) => {
  try {
    const response = await sendLoginCredentials({ email, password });
    if (response.data) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `invalid response: ${response}`,
        AppInsightsErrorType.APPLICATION_LOGIN
      );
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error logging into application: ${err}`,
      AppInsightsErrorType.APPLICATION_LOGIN
    );
  }
};

export const fetchApplicationLogin = createAsyncThunk(
  "application-login/post",
  sendLoginCredentialsResponse
);
