import { GetDocumentsResponse } from "./documents-borrowing";

export type DocumentsBorrowingAction =
  | DocumentsBorrowingGetAction
  | DocumentsBorrowingGetSuccessAction;

export type DocumentLinkBorrowingAction =
  | DocumentLinkBorrowingGetAction
  | DocumentLinkBorrowingGetSuccessAction;

export const DocumentsBorrowingGetActionType = "documents/get" as const;
export type DocumentsBorrowingGetAction = ReturnType<
  typeof documentsBorrowingGetAction
>;
export function documentsBorrowingGetAction(loanId: number) {
  return {
    type: DocumentsBorrowingGetActionType,
    loanId,
  };
}
export const DocumentsBorrowingGetSuccessActionType =
  "documents/get/success" as const;
export type DocumentsBorrowingGetSuccessAction = ReturnType<
  typeof documentsBorrowingGetSuccessAction
>;
export function documentsBorrowingGetSuccessAction(
  documents: GetDocumentsResponse[],
  loanId: number
) {
  return {
    type: DocumentsBorrowingGetSuccessActionType,
    documents,
    loanId,
  };
}

export const DocumentLinkBorrowingGetActionType = "document/link/get" as const;
export type DocumentLinkBorrowingGetAction = ReturnType<
  typeof documentLinkBorrowingGetAction
>;

export function documentLinkBorrowingGetAction(
  loanId: number,
  documentId: number
) {
  return {
    type: DocumentLinkBorrowingGetActionType,
    loanId,
    documentId,
  };
}
export const DocumentLinkBorrowingGetSuccessActionType =
  "document/link/get/success" as const;
export type DocumentLinkBorrowingGetSuccessAction = ReturnType<
  typeof documentLinkBorrowingGetSuccessAction
>;
export function documentLinkBorrowingGetSuccessAction(
  documents: GetDocumentsResponse[]
) {
  return {
    type: DocumentLinkBorrowingGetSuccessActionType,
    documents,
  };
}
