import { SideNavigation, SideNavigationItem } from "@wisr/react-ui";
import { Link } from "gatsby";
import React from "react";
import { useGetUser } from "../../user/user.hooks";
import { activePages } from "../navigation.utils";
import localStyle from "./side-navigation.scss";

interface Props {
  activePage?: string;
}
export const SideNavigationComponent: React.FC<Props> = ({ activePage }) => {
  const user = useGetUser();
  const isActive = activePages(activePage);
  const headerNavigation: SideNavigationItem[] = [
    {
      heading: <Link to="/">Home</Link>,
      links: [],
      isActive: isActive.home,
    },

    {
      heading: (
        <Link
          to={
            user.loanCount === 1 && user.applicationCount === 0
              ? `/borrowing/loans?loanId=${user.loanId}`
              : "/borrowing"
          }
        >
          Loans
        </Link>
      ),
      links: [],
      isActive: isActive.loans,
      hidden: user.loanCount === 0 && user.applicationCount === 0,
    },
    {
      heading: <Link to="/credit">Credit Scores</Link>,
      links: [],
      isActive: isActive.credit,
    },
  ];

  return (
    <div className="navigation-wrapper">
      <style jsx>{localStyle}</style>
      <SideNavigation navigation={headerNavigation} contrast />
    </div>
  );
};
