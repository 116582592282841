import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  RateEstimateState,
  RateEstimateStore,
} from "../../types/rate-estimate";
import { fetchRateEstimate, submitRateEstimate } from "./rate-estimate.thunk";
import { ErrorMessage } from "../../types/loan-application";

const initialRateEstimateState: RateEstimateState = {
  getStatus: FetchStatus.IDLE,
  postStatus: FetchStatus.IDLE,
};

export const rateEstimateSlice = createSlice({
  name: "rateEstimate",
  initialState: initialRateEstimateState,
  reducers: {
    clearRateEstimateState: (state) => {
      return {
        ...state,
        getStatus: FetchStatus.IDLE,
        wisrApplicationId: undefined,
        rateEstimateResponse: undefined,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRateEstimate.pending, (state) => {
        state.getStatus = FetchStatus.LOADING;
      })
      .addCase(fetchRateEstimate.fulfilled, (state, action) => {
        if (action.payload) {
          state.getStatus = FetchStatus.SUCCESS;
          state.rateEstimateResponse = action.payload;
        } else {
          state.getStatus = FetchStatus.FAILED;
          state.error = "rate estimate get failed";
        }
      })
      .addCase(fetchRateEstimate.rejected, (state, action) => {
        state.getStatus = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      })
      .addCase(submitRateEstimate.pending, (state) => {
        state.postStatus = FetchStatus.LOADING;
      })
      .addCase(submitRateEstimate.fulfilled, (state, action) => {
        if (action.payload) {
          if (action.payload.errorFields) {
            state.postStatus = FetchStatus.FAILED;

            const reasons = (
              Object.values(action.payload.errorFields) as ErrorMessage[]
            )
              .filter((error) => {
                if (error.fieldName && error.errorMessage[0]) {
                  return true;
                }
                return false;
              })
              .map((error) => {
                return `${error.fieldName.split(".").slice(-1)[0]}: ${
                  error.errorMessage[0]
                }`;
              });

            state.error = reasons;
          } else if (action.payload.wisrApplicationId) {
            state.postStatus = FetchStatus.SUCCESS;
            state.wisrApplicationId = action.payload.wisrApplicationId;
          } else {
            state.postStatus = FetchStatus.FAILED;
            state.error = "rate estimate post failed";
          }
        } else {
          state.postStatus = FetchStatus.FAILED;
          state.error = "rate estimate post failed";
        }
      })
      .addCase(submitRateEstimate.rejected, (state, action) => {
        state.postStatus = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export default rateEstimateSlice.reducer;

export const { clearRateEstimateState } = rateEstimateSlice.actions;

export const selectRateEstimateState = (store: RateEstimateStore) =>
  store.rateEstimateState;
