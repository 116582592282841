import {
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { AxiosError, AxiosResponse } from "axios";
import { Epic, ofType } from "redux-observable";
import { concat, from, Observable, of } from "rxjs";
import { catchError, concatMap, map, withLatestFrom } from "rxjs/operators";
import { FormResponseError } from "../types/credit-profile-form";
import { serverErrorMessages } from "../forms/credit-profile/credit-profile-form.utils";
import axios from "../shared/axios.utils";
import { CREDIT_API } from "../shared/url.consts";
import { AppAction, AppStore } from "../store";
import { FinancialMood, FinancialMoodResponse } from "../types/financial-mood";
import {
  FinancialMoodActionTypes,
  financialMoodGetSuccessAction,
  financialMoodUpdateFailAction,
  financialMoodUpdateSuccessAction,
  GET_FINANCIAL_MOOD_LOADING,
  UPDATE_FINANCIAL_MOOD_LOADING,
} from "./financial-mood.actions";
import { EMPTY_FINANCIAL_MOOD_FORM_DATA } from "./financial-mood.constants";

export const getFinancialMood: Epic<AppAction, AppAction, AppStore> = (
  actions,
  state
) =>
  actions.pipe(
    ofType(FinancialMoodActionTypes.GET),
    withLatestFrom(state),
    concatMap(() =>
      concat(
        of(loadingStartAction(GET_FINANCIAL_MOOD_LOADING)),
        getFinancialMoodResponse().pipe(
          map((mood) => {
            return financialMoodGetSuccessAction({
              financialMoodResponse: mood,
              isModalOpen:
                mood.versionNumber === 0 &&
                state.value.financialMoodState.financialMoodResponse === null
                  ? true
                  : state.value.financialMoodState.isModalOpen,
            });
          })
        ),
        of(loadingSuccessAction(GET_FINANCIAL_MOOD_LOADING))
      ).pipe(
        catchError((e: AxiosError<FormResponseError>) =>
          concat(
            of(
              loadingFailAction(
                GET_FINANCIAL_MOOD_LOADING,
                e.response?.data.title || "Could not get financial mood data"
              )
            )
          )
        )
      )
    )
  );

export const updateFinancialMood: Epic<AppAction, AppAction, AppStore> = (
  actions,
  AppState
) =>
  actions.pipe(
    ofType(FinancialMoodActionTypes.UPDATE),
    withLatestFrom(AppState),
    concatMap(([action]) =>
      concat(
        of(loadingStartAction(UPDATE_FINANCIAL_MOOD_LOADING)),
        updateFinancialMoodResponse(action.financialMood).pipe(
          map((mood) => {
            return financialMoodUpdateSuccessAction(mood);
          })
        ),
        of(loadingSuccessAction(UPDATE_FINANCIAL_MOOD_LOADING))
      ).pipe(
        catchError((e: AxiosError<FormResponseError>) =>
          concat(
            of(
              loadingFailAction(
                UPDATE_FINANCIAL_MOOD_LOADING,
                serverErrorMessages(e.response?.data)
              )
            ),
            of(financialMoodUpdateFailAction())
          )
        )
      )
    )
  );

function getFinancialMoodResponse(): Observable<FinancialMoodResponse> {
  return from(
    axios.get<FinancialMoodResponse>(
      `${CREDIT_API}/CustomerAccount/FinancialProfile`
    )
  ).pipe(
    map((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 204) {
        return {
          financialMood: undefined as unknown as FinancialMood,
          moneyMoments: [],
          versionNumber: 0,
        };
      } else {
        return { ...EMPTY_FINANCIAL_MOOD_FORM_DATA, versionNumber: -1 };
      }
    })
  );
}

function updateFinancialMoodResponse(
  mood: FinancialMoodResponse
): Observable<FinancialMoodResponse> {
  return from(
    axios.put<void>(`${CREDIT_API}/CustomerAccount/FinancialProfile`, mood)
  ).pipe(map((res: AxiosResponse) => res.data));
}
