import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { DASH_API } from "../../shared/url.consts";
import { HomeProductsResponse } from "../../types/home-products";

export const returnHomeProducts = async () => {
  return axios.get<HomeProductsResponse>(`${DASH_API}/home/products`);
};

export const returnHomeProductsResponse = async () => {
  try {
    const response = await returnHomeProducts();
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.HOME_PRODUCTS
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching home products: ${err}`,
      AppInsightsErrorType.HOME_PRODUCTS
    );
  }
};

export const fetchHomeProducts = createAsyncThunk(
  "home-products/get",
  returnHomeProductsResponse
);
