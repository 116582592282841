import {
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { AxiosResponse } from "axios";
import { Epic, ofType } from "redux-observable";
import { concat, from, Observable, of } from "rxjs";
import { catchError, concatMap, map, tap } from "rxjs/operators";
import { analyticsLoadWidgetEvent } from "../../../shared/analytics/analytics.utils";
import axios from "../../../shared/axios.utils";
import { noopAction } from "../../../shared/common.actions";
import { LOANS_API } from "../../../shared/url.consts";
import { AppAction } from "../../../store";
import {
  DocumentLinkBorrowingGetActionType,
  documentLinkBorrowingGetSuccessAction,
  DocumentsBorrowingGetActionType,
  documentsBorrowingGetSuccessAction,
} from "./documents-borrowing-widget.actions";
import { GetDocumentsResponse } from "./documents-borrowing";

export const GETDOCUMENTS_BORROWING_LOADING = "GETDOCUMENTS_BORROWING_LOADING";

export const getDocumentsBorrowingWidget: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(DocumentsBorrowingGetActionType),
    concatMap((action) =>
      concat(
        of(loadingStartAction(GETDOCUMENTS_BORROWING_LOADING)),
        getDocumentsBorrowing(action.loanId).pipe(
          map((documents) =>
            documentsBorrowingGetSuccessAction(documents, action.loanId)
          ),
          tap(() => {
            analyticsLoadWidgetEvent("PL Docs");
          })
        ),
        of(loadingSuccessAction(GETDOCUMENTS_BORROWING_LOADING))
      ).pipe(
        catchError((e) =>
          of(loadingFailAction(GETDOCUMENTS_BORROWING_LOADING, e))
        )
      )
    )
  );

export const getDocumentLinkWidget: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(DocumentLinkBorrowingGetActionType),
    concatMap((action) =>
      concat(
        getDocumentLink(action.loanId, action.documentId).pipe(
          map((documents) => documentLinkBorrowingGetSuccessAction(documents)),
          catchError(() => of(noopAction()))
        )
      )
    )
  );

function getDocumentsBorrowing(
  loanId: number
): Observable<GetDocumentsResponse[]> {
  return from(
    axios.get<GetDocumentsResponse>(
      `${LOANS_API}/loans/${loanId}/contract/documents`
    )
  ).pipe(map((res: AxiosResponse) => res.data));
}

function getDocumentLink(
  loanId: number,
  documentId: number
): Observable<GetDocumentsResponse[]> {
  return from(
    axios.get<GetDocumentsResponse>(
      `${LOANS_API}/loans/${loanId}/contract/documents/${documentId}`
    )
  ).pipe(map((res: AxiosResponse) => res.data));
}
