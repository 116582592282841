import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  ApplicationTermsState,
  ApplicationTermsStore,
} from "../../types/terms";
import { fetchApplicationTerms } from "./application-terms.thunk";

const initialApplicationTermsState: ApplicationTermsState = {
  status: FetchStatus.IDLE,
};

export const applicationTermsSlice = createSlice({
  name: "applicationTerms",
  initialState: initialApplicationTermsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchApplicationTerms.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchApplicationTerms.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.terms = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "application terms get failed";
        }
      })
      .addCase(fetchApplicationTerms.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export const selectApplicationTermsState = (store: ApplicationTermsStore) =>
  store.applicationTermsState;

export default applicationTermsSlice.reducer;
