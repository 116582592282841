import { CreditInsightsState } from "./credit-insights";
import {
  InsightsCreditPageAction,
  InsightsCreditPageGetSuccessActionType,
} from "./credit-insights.actions";

export const insightsCreditPageReducer = (
  state: CreditInsightsState = {},
  action: InsightsCreditPageAction
): CreditInsightsState => {
  if (
    action.type === InsightsCreditPageGetSuccessActionType &&
    action.provider
  ) {
    return {
      ...state,
      [action.provider]: action.insights,
    };
  }
  return state;
};
