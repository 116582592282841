import { RequestPayoutData } from "./request-payout-form.format";

export type RequestPayoutAction =
  | RequestPayoutPostAction
  | RequestPayoutPostSuccessAction;

export const RequestPayoutPostActionType = "request/payout/post" as const;
export type RequestPayoutPostAction = ReturnType<
  typeof requestPayoutPostAction
>;
export function requestPayoutPostAction(data: RequestPayoutData) {
  return {
    type: RequestPayoutPostActionType,
    data,
  };
}
export const RequestPayoutPostSuccessActionType =
  "request/payout/success" as const;
export type RequestPayoutPostSuccessAction = ReturnType<
  typeof requestPayoutPostSuccessAction
>;
export function requestPayoutPostSuccessAction() {
  return {
    type: RequestPayoutPostSuccessActionType,
  };
}
