import { WisrProfile } from "./wisr-profile";
import {
  WisrProfileActions,
  WisrProfileGetSuccessActionType,
} from "./wisr-profile.actions";

export interface WisrProfileState {
  profile?: WisrProfile;
}

export const wisrProfileReducer = (
  state: WisrProfileState = {},
  actions: WisrProfileActions
) => {
  switch (actions.type) {
    case WisrProfileGetSuccessActionType:
      return {
        profile: actions.profile,
      };

    default:
      return state;
  }
};
