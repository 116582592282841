// todo: This file is a mess...
/* eslint-disable @typescript-eslint/no-use-before-define */
import { capitalise } from "@wisr/common";
import moment from "moment";

import Package from "../../package.json";
import { quoteAmplitudeHelper } from "../shared/analytics/analytics.utils";
import { Application, Frequency, SectionErrors, Status } from "./application";
import {
  PaymentFrequency,
  applicationFormDefaults,
} from "./application-form.constants";
import { ApplicationRequest, Mortgage } from "./application-request";
import {
  AddressBaseType,
  CreditCard,
  CreditScoreResponse,
  GetApplicationResponse,
  PersonalLoan,
} from "./get-application.response";
import { Debt, FinancialDebtId, getFieldKeys } from "./owing/owing.component";
import {
  CREDIT_CARD_VALUE,
  MORTGAGE_VALUE,
  PERSONAL_LOAN_VALUE,
} from "./multi-page-application-form/form-pages/your-debts/debt-list/debt-list.constants";
import { IncompleteLoanApplication } from "../types/personal-loan";

export const LIVING_SITUATION_MORTGAGE = "Buyer";

export const ID_TYPES = {
  DRIVERS_LICENCE: "Driver's License",
  MEDICARE: "Medicare card",
  PASSPORT: "Passport",
} as const;

export function mapToApplicationRequest(
  submittedFormData: typeof applicationFormDefaults,
  continueToken: string,
  applicationRef: string,
  RateCardVersion: string,
  RateCardVersionSecured: string,
  SkipValidations = true,
  submitApplication = false
): ApplicationRequest {
  return {
    ApplicationFormVersion: Package.version,
    SkipValidations,
    IsSubmitted: submitApplication,
    LoanStep: submitApplication ? "Confirmation" : "CompleteInformation",
    LoanId: +submittedFormData.loanId,
    LoanPurpose: submittedFormData.loanPurpose,
    Amount: +submittedFormData.loanAmount,
    LoanTerm: +submittedFormData.loanTerm,
    InterestRate: 0,
    ApplicationRef: applicationRef,
    ContinueToken: continueToken,
    debt_consolidation_details: getDebtsToConslidate(
      submittedFormData,
      !!RateCardVersionSecured
    ),
    Title: submittedFormData.title,
    FirstName: submittedFormData.firstName,
    MiddleName: submittedFormData.middleName,
    Surname: submittedFormData.lastName,
    MaritalStatus: submittedFormData.maritalStatus,
    DOB: parseDateForSubmit(submittedFormData.dateOfBirth),
    Email: submittedFormData.email,
    RateCardVersion,
    RateCardVersionSecured,
    application_details: {
      UserType: 5,
      personal_details: {
        marital_status: submittedFormData.maritalStatus,
        dependents: +submittedFormData.numberOfFinancialDependents,

        IdentityDocTypes: [
          submittedFormData.idenfiticationSource1,
          submittedFormData.idenfiticationSource2,
        ],
        license: submittedFormData.driverLicenceNumber,
        licenceState: submittedFormData.driversLicenceState,
        licenseDateOfExpiry: parseDateForSubmit(
          submittedFormData.driversLicenceExpiry
        ),
        licenceCardNumber: submittedFormData.driverLicenceCardNumber,
        passport: submittedFormData.passportNumber,
        passportCountry: submittedFormData.passportCountry,
        medicare: submittedFormData.medicareCardNumber,
        medicareMiddlenameOnCard: submittedFormData.medicareInitial,
        medicareCardColour: submittedFormData.medicareColour,
        medicareReferenceNumber: submittedFormData.medicareReferenceNumber,
        medicareDateOfExpiry: parseDateForSubmit(
          submittedFormData.medicareExpiry,
          true
        ),
      },
      contact_details: {
        home_area_code: "",
        home_phone: "",
        work_area_code: "",
        work_phone: "",
        mobile_phone: submittedFormData.mobile.replace(/ /g, ""),
      },
      current_address: {
        unit: submittedFormData.addressUnit,
        street_number: submittedFormData.addressStreetNumber,
        street_name: submittedFormData.addressStreetName,
        street_type: submittedFormData.addressStreetType,
        town: submittedFormData.addressSuburb,
        state: submittedFormData.addressState,
        postcode: submittedFormData.addressPostcode,
        country: 12,
        residency_status: submittedFormData.livingSituationAtAddress,
        TimeAtYears: +submittedFormData.yearsAtAddress,
        TimeAtMonths: +submittedFormData.monthsAtAddress,
        total_months: getTotalMonths(
          submittedFormData.yearsAtAddress,
          submittedFormData.monthsAtAddress
        ),
        landlord_mortgagor_name: submittedFormData.propertyOwnerManagerName,
        landlord_mortgagor_phone: flattenPhoneInput(
          submittedFormData.propertyOwnerManagerPhone
        ),
      },
      previous_address: {
        unit: submittedFormData.previousAddressUnit,
        street_number: submittedFormData.previousAddressStreetNumber,
        street_name: submittedFormData.previousAddressStreetName,
        street_type: submittedFormData.previousAddressStreetType,
        town: submittedFormData.previousAddressSuburb,
        state: submittedFormData.previousAddressState,
        postcode: submittedFormData.previousAddressPostcode,
        country: 12,
        residency_status: submittedFormData.livingSituationAtPreviousAddress,
        TimeAtYears: +submittedFormData.yearsAtPreviousAddress,
        TimeAtMonths: +submittedFormData.monthsAtPreviousAddress,
        total_months: getTotalMonths(
          submittedFormData.yearsAtPreviousAddress,
          submittedFormData.monthsAtPreviousAddress
        ),
      },
      current_employment: {
        occupation: submittedFormData.occupation,
        employment_status: submittedFormData.employmentStatus,
        employer: submittedFormData.employerName,
        area_code: getAreaCode(submittedFormData.employmentContactNumber),
        phone: getPhone(submittedFormData.employmentContactNumber),
        extension: "",
        total_months: getTotalMonths(
          submittedFormData.yearsAtEmployer,
          submittedFormData.monthsAtEmployer
        ),
        authorityToContactEmployer: false,
        employer_industry: submittedFormData.employmentIndustry,
      },
      previous_employment: {
        occupation: submittedFormData.previousOccupation,
        employment_status: submittedFormData.previousEmploymentStatus,
        employer: submittedFormData.previousEmployerName,
        area_code: getAreaCode(
          submittedFormData.previousEmploymentContactNumber
        ),
        phone: getPhone(submittedFormData.previousEmploymentContactNumber),
        extension: "",
        total_months: getTotalMonths(
          submittedFormData.yearsAtPreviousEmployer,
          submittedFormData.monthsAtPreviousEmployer
        ),
      },
      bank_account: {
        ...createBankAccountFields(submittedFormData),
      },
      financial_details: {
        has_debts:
          // Set has_debts field to match loan purpose when consolidation for consistency
          submittedFormData.loanPurpose === "Consolidation"
            ? true
            : toOptionalBoolean(submittedFormData.hasDebts),
        breakdown_expenses: true,
        ...createRentFields(
          submittedFormData.totalRentalAmount,
          submittedFormData.rentalAmountFrequency,
          submittedFormData.rentShared
        ),

        // Primary Income
        income_amount: +submittedFormData.incomeAmount,
        income_amount_description: submittedFormData.incomeSource,
        income_frequency: submittedFormData.incomeFrequency,

        other_income_amount_1: getOptionalNumber(
          submittedFormData.otherIncomeAmount_1
        ),
        other_income_amount_1_description: getOptionalString(
          submittedFormData.otherIncomeSource_1
        ),
        other_income_amount_1_frequency: getOptionalString(
          submittedFormData.otherIncomeFrequency_1
        ),

        other_income_amount_2: getOptionalNumber(
          submittedFormData.otherIncomeAmount_2
        ),
        other_income_amount_2_description: getOptionalString(
          submittedFormData.otherIncomeSource_2
        ),
        other_income_amount_2_frequency: getOptionalString(
          submittedFormData.otherIncomeFrequency_2
        ),

        other_income_amount_3: getOptionalNumber(
          submittedFormData.otherIncomeAmount_3
        ),
        other_income_amount_3_description: getOptionalString(
          submittedFormData.otherIncomeSource_3
        ),
        other_income_amount_3_frequency: getOptionalString(
          submittedFormData.otherIncomeFrequency_3
        ),

        partner_income: hasPartner(submittedFormData.maritalStatus)
          ? +submittedFormData.partnerIncomeAmount
          : 0,

        // expenses
        groceries: +submittedFormData.groceries,
        clothing: +submittedFormData.clothing,
        council_rates: +submittedFormData.council_rates,
        car_registration: +submittedFormData.car_registration,
        petrol: +submittedFormData.petrol,
        health_insurance: +submittedFormData.health_insurance,
        car_home_insurance: +submittedFormData.car_home_insurance,
        utilities: +submittedFormData.utilities,
        communication: +submittedFormData.communication,
        transportation: +submittedFormData.transportation,
        education: +submittedFormData.education,
        entertainment: +submittedFormData.entertainment,
        other_general_expenses: +submittedFormData.other_general_expenses,
        other_additional_expenses: +submittedFormData.other_additional_expenses,
        seconds_motor_vehicle: +submittedFormData.seconds_motor_vehicle,

        // debt
        ...createDebtFields(submittedFormData, !!RateCardVersionSecured),
      },
      investment_property: {
        ...createIvestmentPropertyFields(
          submittedFormData.ownsInvestmentProperty,
          submittedFormData.investmentPropertyMortgaged
        ),
      },
      SignificantPlannedChanges:
        submittedFormData.significantPlannedChanges === "Yes",
      SignificantPlannedChangeTypes:
        submittedFormData.significantPlannedChangeTypes,
      ...createConsentFields(submittedFormData.termsAndConditionsAgreement),
    },
    securedVehicleInformation: createSecurityFields(submittedFormData),
  };
}

export const createConsentFields = (termsAndConditionsAgreement: string[]) => {
  if (
    termsAndConditionsAgreement.some(
      (terms) =>
        terms ===
        "I have read, understood and agree to the terms and conditions above."
    )
  )
    return {
      AppConsent:
        "I consent to Wisr Finance Pty Ltd making a “hard” enquiry against my credit file for the purpose of assessing my application for credit and I understand that this may impact my credit score. For more information on “hard” enquiries, please see our FAQs.",
      AppCreditConsent:
        "I confirm that I am authorised to provide the personal details presented and I consent to my information being checked with the document issuer or official record holder via third party systems and services for the purpose of confirming my identity. I acknowledge that all applications are subject to Wisr Finance Pty Ltd lending criteria. I consent to the receipt of notices and other documents electronically. Show Electronic Communication Consent Details.",
      AppDeclaration:
        "I confirm I have the legal authority to submit this personal loan application on behalf of myself or my client and I declare all details I have provided in this application are true and correct.",
      AppPrivacyConsent:
        "I have read and agreed to the terms set out in the Privacy Consent Form.",
    };
};

export const createRentFields = (
  totalRentalAmount: string,
  rentalAmountFrequency: string,
  rentShared: string[]
) => {
  return {
    rent_amount_full: +totalRentalAmount,
    rent_frequency: rentalAmountFrequency as Frequency,
    rent_shared: rentShared.length > 0,
  };
};

const getDebtsFromFormData = (
  formData: Application,
  isSecuredRateType: boolean
) => {
  const credit_card_list: CreditCard[] = [];
  const personal_loan_list: PersonalLoan[] = [];
  const mortgage_list: Mortgage[] = [];

  // check for home mortgage
  const mortgage =
    formData["mortgageBalance"] &&
    formData.livingSituationAtAddress === LIVING_SITUATION_MORTGAGE
      ? createMortgage(
          formData["mortgageBalance"],
          formData["mortgageFinanceCompany"],
          formData["mortgageRepaymentAmount"],
          formData["mortgageRepaymentFrequency"],
          formData["hasCoBorrower"]
        )
      : undefined;

  if (mortgage) {
    mortgage_list.push(mortgage);
  }

  for (let i = 1; i <= 10; i++) {
    const {
      debtTypeKey,
      financeCompanyKey,
      balanceKey,
      repaymentAmountKey,
      repaymentFrequencyKey,
      isJointLoanKey,
      consolidateKey,
      investmentKey,
      cardLimitKey,
      closeCardKey,
    } = getFieldKeys(i as FinancialDebtId);
    if (formData[debtTypeKey]) {
      if (formData[debtTypeKey] === CREDIT_CARD_VALUE) {
        const card = createCreditCard(
          formData[financeCompanyKey],
          formData[cardLimitKey],
          formData[balanceKey],
          toFieldValue(formData[consolidateKey]),
          toFieldValue(formData[closeCardKey]),
          isSecuredRateType
        );

        credit_card_list.push(card);
      }

      if (formData[debtTypeKey] === PERSONAL_LOAN_VALUE) {
        const loan = createPersonalLoan(
          formData[financeCompanyKey],
          formData[repaymentAmountKey],
          formData[balanceKey],
          toFieldValue(formData[isJointLoanKey]),
          toFieldValue(formData[consolidateKey]),
          formData[repaymentFrequencyKey],
          isSecuredRateType
        );

        personal_loan_list.push(loan);
      }

      if (formData[debtTypeKey] === MORTGAGE_VALUE) {
        const mortgage = createMortgage(
          formData[balanceKey],
          formData[financeCompanyKey],
          formData[repaymentAmountKey],
          formData[repaymentFrequencyKey],
          toFieldValue(formData[isJointLoanKey]),
          toFieldValue(formData[investmentKey])
        );
        mortgage_list.push(mortgage);
      }
    }
  }

  return {
    credit_card_list,
    personal_loan_list,
    mortgage_list,
    mortgage,
  };
};

export const hasAddedDebts = (formData: Application): boolean => {
  return getDebtFields(formData).length > 0;
};

export const getDebtFields = (formData: Application): Debt[] => {
  const debts: Debt[] = [];

  for (let i = 1; i <= 10; i++) {
    const {
      debtTypeKey,
      financeCompanyKey,
      balanceKey,
      repaymentAmountKey,
      repaymentFrequencyKey,
      isJointLoanKey,
      consolidateKey,
      investmentKey,
      cardLimitKey,
      closeCardKey,
    } = getFieldKeys(i as FinancialDebtId);
    debts.push({
      type: formData[debtTypeKey],
      company: formData[financeCompanyKey],
      balance: formData[balanceKey],
      repaymentAmount: formData[repaymentAmountKey],
      frequency: formData[repaymentFrequencyKey],
      isJoint: formData[isJointLoanKey],
      consolidate: formData[consolidateKey],
      investment: formData[investmentKey],
      cardLimit: formData[cardLimitKey],
      closeCard: formData[closeCardKey],
    });
  }

  return debts.filter((debt) => debt.type !== "");
};

export const getDebtsToConslidate = (
  formData: Application,
  isSecuredRateType: boolean
) => {
  const { credit_card_list, personal_loan_list } = getDebtsFromFormData(
    formData,
    isSecuredRateType
  );

  return [
    ...credit_card_list
      .filter((card) => card.credit_card_consolidate)
      .map((card) => ({
        FinancialInstitution: card.credit_card_finance_company,
        ConsolidationType: CREDIT_CARD_VALUE,
        Amount: card.credit_card_balance,
        IntendToCloseAccount: card.credit_card_close,
      })),

    ...personal_loan_list
      .filter((loan) => loan.personal_loan_consolidate)
      .map((loan) => ({
        FinancialInstitution: loan.personal_loan_finance_company,
        ConsolidationType: PERSONAL_LOAN_VALUE,
        Amount: +loan.personal_loan_balance,
        IntendToCloseAccount: true,
      })),
  ];
};

export const createDebtFields = (
  formData: Application,
  isSecuredRateType: boolean
) => {
  const { credit_card_list, personal_loan_list, mortgage_list } =
    getDebtsFromFormData(formData, isSecuredRateType);

  const credit_card_limit = sumRepayments(
    credit_card_list,
    "credit_card_limit",
    undefined,
    (m) => !m.credit_card_close
  );
  const outstanding_mortgage_amount = sumRepayments(
    mortgage_list,
    "mortgage_balance"
  );
  const outstanding_personal_loan_amount = sumRepayments(
    personal_loan_list,
    "personal_loan_balance",
    undefined,
    (l) => !l.personal_loan_consolidate
  );

  return {
    credit_card_list,
    credit_cards: credit_card_list.length,
    credit_card_limit,

    mortgage_list,
    number_of_mortgages: mortgage_list.length,
    mortgage_frequency: Frequency.Annual,
    mortgage_joint_frequency: Frequency.Annual,
    mortgage_amount: sumRepayments(
      mortgage_list,
      "mortgage_repayment",
      "mortgage_repayment_frequency",
      (m) => !m.mortgage_shared && !m.investment_property
    ),
    mortgage_amount_joint: sumRepayments(
      mortgage_list,
      "mortgage_repayment",
      "mortgage_repayment_frequency",
      (m) => !!m.mortgage_shared && !m.investment_property
    ),
    outstanding_mortgage_amount,
    investment_mortgage_frequency: Frequency.Annual,
    investment_mortgage_joint_frequency: Frequency.Annual,
    investment_mortgage_amount: sumRepayments(
      mortgage_list,
      "mortgage_repayment",
      "mortgage_repayment_frequency",
      (m) => !m.mortgage_shared && !!m.investment_property
    ),
    investment_mortgage_amount_joint: sumRepayments(
      mortgage_list,
      "mortgage_repayment",
      "mortgage_repayment_frequency",
      (m) => !!m.mortgage_shared && !!m.investment_property
    ),

    personal_loan_list,
    number_of_personal_loans: personal_loan_list.length,
    other_loan_amount: sumRepayments(
      personal_loan_list,
      "personal_loan_repayment",
      "personal_loan_repayment_frequency",
      (l) => !l.personal_loan_shared && !l.personal_loan_consolidate
    ),
    other_loan_frequency: Frequency.Annual,
    other_loan_amount_joint: sumRepayments(
      personal_loan_list,
      "personal_loan_repayment",
      "personal_loan_repayment_frequency",
      (l) => l.personal_loan_shared && !l.personal_loan_consolidate
    ),
    other_loan_joint_frequency: Frequency.Annual,

    total_liabilities:
      outstanding_personal_loan_amount +
      outstanding_mortgage_amount +
      credit_card_limit,
  };
};
export const createMortgage = (
  mortgage_balance: string,
  mortgage_finance_company: string,
  mortgage_repayment: string,
  mortgage_repayment_frequency: string,
  mortgage_shared: string,
  investment_property?: string
): Mortgage => {
  return {
    mortgage_balance,
    mortgage_finance_company,
    mortgage_repayment,
    mortgage_repayment_frequency: mortgage_repayment_frequency as Frequency,
    mortgage_shared:
      mortgage_shared === "Yes" || mortgage_shared === "Joint loan",
    investment_property: investment_property === "Investment property",
  };
};

export const createCreditCard = (
  credit_card_finance_company: string,
  credit_card_limit: string,
  credit_card_balance: string,
  credit_card_consolidate: string,
  credit_card_close: string,
  isSecuredRateType: boolean
): CreditCard => {
  const consolidate =
    credit_card_consolidate === "Consolidate this card" && !isSecuredRateType;
  const close = credit_card_close === "Close this card";

  return {
    credit_card_finance_company,
    credit_card_limit: +credit_card_limit,
    credit_card_balance: +credit_card_balance,
    credit_card_consolidate: consolidate,
    credit_card_close: consolidate && close,
  };
};

export const createPersonalLoan = (
  personal_loan_finance_company: string,
  personal_loan_repayment: string,
  personal_loan_balance: string,
  personal_loan_shared: string,
  personal_loan_consolidate: string,
  personal_loan_repayment_frequency: string,
  isSecuredRateType: boolean
): PersonalLoan => {
  return {
    personal_loan_finance_company,
    personal_loan_repayment,
    personal_loan_balance,
    personal_loan_shared: personal_loan_shared === "Joint loan",
    personal_loan_consolidate:
      personal_loan_consolidate === "Consolidate this loan" &&
      !isSecuredRateType,
    personal_loan_repayment_frequency:
      personal_loan_repayment_frequency as Frequency,
  };
};

export const createSecurityFields = (formData: Application) => {
  return formData.loanPurpose.toLowerCase() === "car"
    ? {
        VehicleModelKnown: formData.assetFound === "Yes",
        VehicleAge: +formData.assetYear,
        EstimatedVehicleAge: formData.assetAgeEstimate,
        SaleType: formData.assetSeller,
        VehicleTypeDescription: formData.loanPurpose,
      }
    : undefined;
};

export const createBankAccountFields = (formData: Application) => {
  return {
    bank_name: formData.bankName,
    account_name: formData.accountName,
    bsb: formData.bsb,
    account_number: formData.accountNumber,
    joint_account: formData.jointAccount.some((v) => v === "Joint Account"),
    joint_account_name: formData.jointAccountName,
  };
};

export const createIvestmentPropertyFields = (
  owns: string,
  mortgage: string
) => {
  return {
    investment_property_mortgaged: toOptionalBoolean(mortgage),
    owns_investment_property: toOptionalBoolean(owns),
  };
};

export function toOptionalBoolean(
  value: string | null | undefined
): boolean | undefined {
  if (value) {
    if (value.toLowerCase() === "yes") {
      return true;
    }

    if (value.toLowerCase() === "no") {
      return false;
    }
  }

  return undefined;
}

export function toBooleanString(value: boolean | null | undefined): string {
  if (value === undefined || value === null) {
    return "";
  }

  return value ? "yes" : "no";
}

function getAnnualAmount(amount: number, frequency: string) {
  const frequencyEnum = frequency as PaymentFrequency;

  let multiplier = 1;

  switch (frequencyEnum) {
    case PaymentFrequency.MONTHLY:
      multiplier = 12;
      break;
    case PaymentFrequency.WEEKLY:
      multiplier = 52;
      break;
    case PaymentFrequency.FORTNIGHTLY:
      multiplier = 26;
      break;

    case PaymentFrequency.ANNUAL:
      multiplier = 1;
      break;
    default:
      multiplier = 1;
  }
  return amount * multiplier;
}

function getOptionalString(value: string) {
  return value || undefined;
}

function getOptionalNumber(amount: string) {
  return amount ? +amount : undefined;
}

function getAreaCode(fullNumber: string) {
  return flattenPhoneInput(fullNumber).substring(0, 2);
}

function getPhone(fullNumber: string) {
  return flattenPhoneInput(fullNumber).substring(2);
}

function getTotalMonths(years?: string, months?: string) {
  return parseInt(years || "0") * 12 + parseInt(months || "0");
}

export function hasPartner(maritalStatus: string) {
  return ["Married", "De Facto"].includes(maritalStatus);
}

const MINIMUM_CREDIT_SCORE_FOR_SKIP_CREDITSENSE = 99999;
export function mapToApplication(
  res: GetApplicationResponse,
  creditScore: CreditScoreResponse
): Application {
  return {
    userType: toFieldValue(res.ApplicationRequest.application_details.UserType),
    loanId: toFieldValue(res.ApplicationRequest.LoanId),
    loanPurpose: toFieldValue(res.ApplicationRequest.LoanPurpose),
    loanTerm: toFieldValue(res.ApplicationRequest.LoanTerm),
    loanAmount: toFieldValue(res.ApplicationRequest.Amount),
    title: toFieldValue(res.ApplicationRequest.Title),
    firstName: toFieldValue(res.ApplicationRequest.FirstName),
    middleName: toFieldValue(res.ApplicationRequest.MiddleName),
    lastName: toFieldValue(res.ApplicationRequest.Surname),
    dateOfBirth: formatDateForForm(res.ApplicationRequest.DOB),
    maritalStatus: toFieldValue(
      res.ApplicationRequest.application_details.personal_details.marital_status
    ),
    numberOfFinancialDependents: toFieldValue(
      res.ApplicationRequest.application_details.personal_details.dependents
    ),

    // Identity
    idenfiticationSource1: toFieldValue(
      res.ApplicationRequest.application_details.personal_details
        .IdentityDocTypes[0]
    ),
    idenfiticationSource2: toFieldValue(
      res.ApplicationRequest.application_details.personal_details
        .IdentityDocTypes[1]
    ),
    driverLicenceNumber: toFieldValue(
      res.ApplicationRequest.application_details.personal_details.license
    ),
    driversLicenceState: toFieldValue(
      res.ApplicationRequest.application_details.personal_details.licenceState
    ),
    driversLicenceExpiry: formatDateForForm(
      res.ApplicationRequest.application_details.personal_details
        .licenseDateOfExpiry
    ),
    driverLicenceCardNumber: toFieldValue(
      res.ApplicationRequest.application_details.personal_details
        .licenceCardNumber
    ),
    medicareCardNumber: toFieldValue(
      res.ApplicationRequest.application_details.personal_details.medicare
    ),
    medicareInitial: toFieldValue(
      res.ApplicationRequest.application_details.personal_details
        .medicareMiddlenameOnCard
    ),
    medicareColour: toFieldValue(
      res.ApplicationRequest.application_details.personal_details
        .medicareCardColour
    ),
    medicareReferenceNumber: toFieldValue(
      res.ApplicationRequest.application_details.personal_details
        .medicareReferenceNumber
    ),
    medicareExpiry: formatDateForForm(
      res.ApplicationRequest.application_details.personal_details
        .medicareDateOfExpiry,
      true
    ),
    passportNumber: toFieldValue(
      res.ApplicationRequest.application_details.personal_details.passport
    ),
    passportCountry: toFieldValue(
      res.ApplicationRequest.application_details.personal_details
        .passportCountry
    ),

    email: toFieldValue(res.ApplicationRequest.Email),
    mobile: formatPhoneInput(
      res.ApplicationRequest.application_details.contact_details.mobile_phone,
      4,
      8
    ),
    address: mapToAddress(
      res.ApplicationRequest.application_details.current_address
    ),
    addressUnit: toFieldValue(
      res.ApplicationRequest.application_details.current_address.unit
    ),
    addressStreetNumber: toFieldValue(
      res.ApplicationRequest.application_details.current_address.street_number
    ),
    addressStreetName: toFieldValue(
      res.ApplicationRequest.application_details.current_address.street_name
    ),
    addressStreetType: toFieldValue(
      res.ApplicationRequest.application_details.current_address.street_type
    ),
    addressSuburb: toFieldValue(
      res.ApplicationRequest.application_details.current_address.town
    ),
    addressState: toFieldValue(
      res.ApplicationRequest.application_details.current_address.state
    ),
    addressPostcode: toFieldValue(
      res.ApplicationRequest.application_details.current_address.postcode
    ),
    yearsAtAddress: getYearsAtAddress(
      res.ApplicationRequest.application_details.current_address.total_months
    ),
    monthsAtAddress: getMonthsAtAddress(
      res.ApplicationRequest.application_details.current_address.total_months
    ),
    livingSituationAtAddress: toFieldValue(
      res.ApplicationRequest.application_details.current_address
        .residency_status
    ),
    ...getMortgageDetails(
      res.ApplicationRequest.application_details.financial_details
        .mortgage_list,
      res.ApplicationRequest.application_details.current_address
        .residency_status
    ),
    totalRentalAmount: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .rent_amount_full
    ),
    rentalAmountFrequency: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .rent_frequency
    ),
    rentShared: res.ApplicationRequest.application_details.financial_details
      .rent_shared
      ? ["Total rental amount is shared?"]
      : [],
    propertyOwnerManagerName: toFieldValue(
      res.ApplicationRequest.application_details.current_address
        .landlord_mortgagor_name
    ),
    propertyOwnerManagerPhone: formatPhoneInput(
      res.ApplicationRequest.application_details.current_address
        .landlord_mortgagor_phone,
      4,
      8
    ),
    previousAddress: mapToAddress(
      res.ApplicationRequest.application_details.previous_address
    ),
    previousAddressUnit: toFieldValue(
      res.ApplicationRequest.application_details.previous_address.unit
    ),
    previousAddressStreetNumber: toFieldValue(
      res.ApplicationRequest.application_details.previous_address.street_number
    ),
    previousAddressStreetName: toFieldValue(
      res.ApplicationRequest.application_details.previous_address.street_name
    ),
    previousAddressStreetType: toFieldValue(
      res.ApplicationRequest.application_details.previous_address.street_type
    ),
    previousAddressSuburb: toFieldValue(
      res.ApplicationRequest.application_details.previous_address.town
    ),
    previousAddressState: toFieldValue(
      res.ApplicationRequest.application_details.previous_address.state
    ),
    previousAddressPostcode: toFieldValue(
      res.ApplicationRequest.application_details.previous_address.postcode
    ),
    yearsAtPreviousAddress: getYearsAtAddress(
      res.ApplicationRequest.application_details.previous_address.total_months
    ),
    monthsAtPreviousAddress: getMonthsAtAddress(
      res.ApplicationRequest.application_details.previous_address.total_months
    ),
    livingSituationAtPreviousAddress: toFieldValue(
      res.ApplicationRequest.application_details.previous_address
        .residency_status
    ),

    // Employment
    employmentStatus: toFieldValue(
      res.ApplicationRequest.application_details.current_employment
        .employment_status
    ),
    yearsAtEmployer: getYearsAtAddress(
      res.ApplicationRequest.application_details.current_employment.total_months
    ),
    monthsAtEmployer: getMonthsAtAddress(
      res.ApplicationRequest.application_details.current_employment.total_months
    ),
    employerName: toFieldValue(
      res.ApplicationRequest.application_details.current_employment.employer
    ),
    occupation: toFieldValue(
      res.ApplicationRequest.application_details.current_employment.occupation
    ),
    employmentContactNumber: formatPhoneInput(
      [
        res.ApplicationRequest.application_details.current_employment.area_code,
        res.ApplicationRequest.application_details.current_employment.phone,
      ].join(""),
      4,
      8
    ),
    employmentIndustry: toFieldValue(
      res.ApplicationRequest.application_details.current_employment
        .employer_industry
    ),

    // Previous employment
    previousEmploymentStatus: toFieldValue(
      res.ApplicationRequest.application_details.previous_employment
        .employment_status
    ),
    yearsAtPreviousEmployer: getYearsAtAddress(
      res.ApplicationRequest.application_details.previous_employment
        .total_months
    ),
    monthsAtPreviousEmployer: getMonthsAtAddress(
      res.ApplicationRequest.application_details.previous_employment
        .total_months
    ),
    previousEmployerName: toFieldValue(
      res.ApplicationRequest.application_details.previous_employment.employer
    ),
    previousOccupation: toFieldValue(
      res.ApplicationRequest.application_details.previous_employment.occupation
    ),
    previousEmploymentContactNumber: formatPhoneInput(
      [
        res.ApplicationRequest.application_details.previous_employment
          .area_code,
        res.ApplicationRequest.application_details.previous_employment.phone,
      ].join(""),
      4,
      8
    ),
    /** @todo this doesn't exist in current data */
    // previousEmploymentIndustry: "",

    // Primary income
    incomeSource: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .income_amount_description || "Salary & Wages"
    ),
    incomeAmount: toFieldValue(
      res.ApplicationRequest.application_details.financial_details.income_amount
    ),
    incomeFrequency: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .income_frequency
    ),

    // Other income
    otherIncomeAmount_1: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .other_income_amount_1 || ""
    ),
    otherIncomeSource_1: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .other_income_amount_1_description
    ),
    otherIncomeFrequency_1: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .other_income_amount_1_frequency
    ),
    otherIncomeAmount_2: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .other_income_amount_2 || ""
    ),
    otherIncomeSource_2: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .other_income_amount_2_description
    ),
    otherIncomeFrequency_2: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .other_income_amount_2_frequency
    ),
    otherIncomeAmount_3: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .other_income_amount_3 || ""
    ),
    otherIncomeSource_3: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .other_income_amount_3_description
    ),
    otherIncomeFrequency_3: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .other_income_amount_3_frequency
    ),

    partnerIncomeAmount: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .partner_income
    ),
    //living expenses
    groceries: toFieldValue(
      res.ApplicationRequest.application_details.financial_details.groceries
    ),
    clothing: toFieldValue(
      res.ApplicationRequest.application_details.financial_details.clothing
    ),
    council_rates: toFieldValue(
      res.ApplicationRequest.application_details.financial_details.council_rates
    ),
    car_registration: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .car_registration
    ),
    petrol: toFieldValue(
      res.ApplicationRequest.application_details.financial_details.petrol
    ),
    health_insurance: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .health_insurance
    ),
    car_home_insurance: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .car_home_insurance
    ),
    utilities: toFieldValue(
      res.ApplicationRequest.application_details.financial_details.utilities
    ),
    communication: toFieldValue(
      res.ApplicationRequest.application_details.financial_details.communication
    ),
    transportation: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .transportation
    ),
    education: toFieldValue(
      res.ApplicationRequest.application_details.financial_details.education
    ),
    entertainment: toFieldValue(
      res.ApplicationRequest.application_details.financial_details.entertainment
    ),
    other_general_expenses: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .other_general_expenses
    ),
    other_additional_expenses: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .other_additional_expenses
    ),
    seconds_motor_vehicle: toFieldValue(
      res.ApplicationRequest.application_details.financial_details
        .seconds_motor_vehicle
    ),

    // financial Debt
    hasDebts:
      // Initialize hasDebts field to match loan purpose when consolidation for consistency
      res.ApplicationRequest.LoanPurpose === "Consolidation"
        ? "yes"
        : toBooleanString(
            res.ApplicationRequest.application_details.financial_details
              .has_debts
          ),
    ...setFinancialDebtCheckBoxes(),
    ...convertFinancialDebtToFields(
      res.ApplicationRequest.application_details.financial_details
        .credit_card_list,
      res.ApplicationRequest.application_details.financial_details
        .personal_loan_list,
      res.ApplicationRequest.application_details.financial_details
        .mortgage_list,
      res.ApplicationRequest.application_details.current_address
        .residency_status
    ),

    // Credit Sense
    creditSenseResult: "",
    skipCreditSense: [],
    allowBypassCreditSense:
      res.ApplicationRequest.AllowBypassCreditSense ||
      creditScore.CreditScore >= MINIMUM_CREDIT_SCORE_FOR_SKIP_CREDITSENSE
        ? "true"
        : "",

    //secured vehicle

    assetFound: res.ApplicationRequest.securedVehicleInformation
      ?.VehicleModelKnown
      ? "Yes"
      : "No",
    assetYear: toFieldValue(
      res.ApplicationRequest.securedVehicleInformation?.VehicleAge
    ),
    assetSeller: res.ApplicationRequest.securedVehicleInformation?.SaleType,
    assetAgeEstimate:
      res.ApplicationRequest.securedVehicleInformation?.EstimatedVehicleAge,

    // investment property
    ownsInvestmentProperty: res.ApplicationRequest.application_details
      .investment_property.owns_investment_property
      ? "Yes"
      : "No",
    investmentPropertyMortgaged: res.ApplicationRequest.application_details
      .investment_property.investment_property_mortgaged
      ? "Yes"
      : "No",

    // bank details
    bankName: res.ApplicationRequest.application_details.bank_account.bank_name,
    accountName:
      res.ApplicationRequest.application_details.bank_account.account_name,
    bsb: res.ApplicationRequest.application_details.bank_account.bsb,
    accountNumber:
      res.ApplicationRequest.application_details.bank_account.account_number,
    jointAccount: res.ApplicationRequest.application_details.bank_account
      .joint_account
      ? ["Joint Account"]
      : ([] as string[]),
    jointAccountName:
      res.ApplicationRequest.application_details.bank_account
        .joint_account_name,
    significantPlannedChanges: res.ApplicationRequest.application_details
      .SignificantPlannedChanges
      ? "Yes"
      : "",
    significantPlannedChangeTypes:
      res.ApplicationRequest.application_details
        .SignificantPlannedChangeTypes ?? ([] as string[]),
    termsAndConditionsAgreement: [],
    requestSecuredRate: "",
    page: "",
    offerId: "",
    partnerCompanyId: "",
  };
}

const formatDateForForm = (dateString?: string | null, short = false) => {
  return dateString
    ? moment.utc(dateString).format(short ? "MM / YYYY" : "DD / MM / YYYY")
    : "";
};

const parseDateForSubmit = (dateString: string, short = false) => {
  const date = moment.utc(dateString, short ? "MM / YYYY" : "DD / MM / YYYY");
  return date.isValid() ? date.toISOString() : "";
};

const setFinancialDebtCheckBoxes = () => {
  const result: { [key: string]: string | string[] } = {};
  for (let i = 1; i <= 10; i++) {
    result[`financialDebtIsJointLoan_${i}`] = [] as string[];
    result[`financialDebtConsolidate_${i}`] = [] as string[];
    result[`financialDebtIsInvestment_${i}`] = [] as string[];
    result[`financialDebtCloseThisCard_${i}`] = [] as string[];
  }
  // todo: Removal of this any cast reveals a whole bunch of typing issues which need to be looked at
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return result as any;
};

const convertFinancialDebtToFields = (
  cardList: CreditCard[],
  personalList: PersonalLoan[],
  mortgageList: Mortgage[],
  livingSituation: string
) => {
  const result: { [key: string]: string | string[] } = {};
  let count = 1;
  cardList.forEach((card: CreditCard) => {
    result[`financialDebtType_${count}`] = CREDIT_CARD_VALUE;
    result[`financialDebtFinanceCompany_${count}`] =
      card.credit_card_finance_company;
    result[`financialDebtBalance_${count}`] = toFieldValue(
      card.credit_card_balance
    );
    result[`financialDebtRepaymentAmount_${count}`] = "";
    result[`financialDebtRepaymentFrequency_${count}`] = "";
    result[`financialDebtIsJointLoan_${count}`] = [] as string[];
    result[`financialDebtConsolidate_${count}`] = card.credit_card_consolidate
      ? ["Consolidate this card"]
      : ([] as string[]);
    result[`financialDebtIsInvestment_${count}`] = [] as string[];
    result[`financialDebtCreditCardLimit_${count}`] = toFieldValue(
      card.credit_card_limit
    );
    result[`financialDebtCloseThisCard_${count}`] = card.credit_card_close
      ? ["Close this card"]
      : ([] as string[]);
    count++;
  });

  personalList.forEach((loan: PersonalLoan) => {
    result[`financialDebtType_${count}`] = PERSONAL_LOAN_VALUE;
    result[`financialDebtFinanceCompany_${count}`] =
      loan.personal_loan_finance_company;
    result[`financialDebtBalance_${count}`] = toFieldValue(
      loan.personal_loan_balance
    );
    result[`financialDebtRepaymentAmount_${count}`] = toFieldValue(
      loan.personal_loan_repayment
    );
    result[`financialDebtRepaymentFrequency_${count}`] =
      loan.personal_loan_repayment_frequency;
    result[`financialDebtIsJointLoan_${count}`] = loan.personal_loan_shared
      ? ["Joint loan"]
      : ([] as string[]);
    result[`financialDebtConsolidate_${count}`] = loan.personal_loan_consolidate
      ? ["Consolidate this loan"]
      : ([] as string[]);
    result[`financialDebtIsInvestment_${count}`] = [] as string[];
    result[`financialDebtCreditCardLimit_${count}`] = "";
    result[`financialDebtCloseThisCard_${count}`] = [] as string[];
    count++;
  });

  mortgageList
    .filter(
      (mortgage: Mortgage, i) =>
        !isMortgageForPrimaryResidence(mortgage, i, livingSituation)
    )
    .forEach((mortgage: Mortgage) => {
      result[`financialDebtType_${count}`] = MORTGAGE_VALUE;
      result[`financialDebtFinanceCompany_${count}`] =
        mortgage.mortgage_finance_company;
      result[`financialDebtBalance_${count}`] = toFieldValue(
        mortgage.mortgage_balance
      );
      result[`financialDebtRepaymentAmount_${count}`] = toFieldValue(
        mortgage.mortgage_repayment
      );
      result[`financialDebtRepaymentFrequency_${count}`] =
        mortgage.mortgage_repayment_frequency;
      result[`financialDebtIsJointLoan_${count}`] = mortgage.mortgage_shared
        ? ["Joint loan"]
        : ([] as string[]);
      result[`financialDebtConsolidate_${count}`] = [] as string[];
      result[`financialDebtIsInvestment_${count}`] =
        mortgage.investment_property
          ? ["Investment property"]
          : ([] as string[]);
      result[`financialDebtCreditCardLimit_${count}`] = "";
      result[`financialDebtCloseThisCard_${count}`] = [] as string[];
      count++;
    });

  // Clear old debts
  for (let i = count; i <= 10; i++) {
    result[`financialDebtType_${i}`] = "";
    result[`financialDebtFinanceCompany_${i}`] = "";
    result[`financialDebtBalance_${i}`] = "";

    result[`financialDebtRepaymentAmount_${i}`] = "";
    result[`financialDebtRepaymentFrequency_${i}`] = "";
    result[`financialDebtIsJointLoan_${i}`] = [] as string[];
    result[`financialDebtConsolidate_${i}`] = [] as string[];
    result[`financialDebtIsInvestment_${i}`] = [] as string[];
    result[`financialDebtCreditCardLimit_${i}`] = "";
    result[`financialDebtCloseThisCard_${i}`] = [] as string[];
  }

  return result;
};

function flattenPhoneInput(value: string) {
  return value.replace(/ /g, "");
}
function formatPhoneInput(
  value: string | undefined | null,
  firstSplit: number,
  secondSplit: number
) {
  if (value) {
    value = flattenPhoneInput(value);
    value = value.slice(0, firstSplit) + " " + value.slice(firstSplit);
    value = value.slice(0, secondSplit) + " " + value.slice(secondSplit);
    return value.trim();
  }

  return "";
}

const getMonthsAtAddress = (totalMonths = 0): string => {
  return (totalMonths - Math.floor(totalMonths / 12) * 12).toString();
};

const getYearsAtAddress = (totalMonths = 0): string => {
  return Math.floor(totalMonths / 12).toString();
};

const isMortgageForPrimaryResidence = (
  mortgage: Mortgage,
  i: number,
  livingSituation: string
) => {
  // first item will be the mortgage for the applicant's home if their living statu is Mortgaged
  return (
    i === 0 &&
    !mortgage.investment_property &&
    livingSituation === LIVING_SITUATION_MORTGAGE
  );
};

const getMortgageDetails = (mortgages: Mortgage[], livingSituation: string) => {
  if (
    mortgages.length > 0 &&
    isMortgageForPrimaryResidence(mortgages[0], 0, livingSituation)
  ) {
    const currentAddress = mortgages[0];
    return {
      mortgageFinanceCompany: currentAddress.mortgage_finance_company,
      mortgageBalance: currentAddress.mortgage_balance.toString(),
      mortgageRepaymentAmount: currentAddress.mortgage_repayment,
      mortgageRepaymentFrequency: currentAddress.mortgage_repayment_frequency,
      hasCoBorrower: currentAddress.mortgage_shared ? "Yes" : "No",
    };
  } else {
    return {
      mortgageFinanceCompany: "",
      mortgageBalance: "",
      mortgageRepaymentAmount: "",
      mortgageRepaymentFrequency: "",
      hasCoBorrower: "",
    };
  }
};

const toFieldValue = (
  value: string | number | boolean | undefined | null | string[]
) => {
  if (Array.isArray(value)) {
    return value[0];
  }
  return value?.toString() || "";
};

export const checkForFormLevelErrors = (formData: Application) => {
  const formErrors: SectionErrors = {};

  formErrors["incomeError"] = getIncomeErrors(formData);
  formErrors["spendingError"] = getSpendingErrors(formData);
  formErrors["owingError"] = getOwingErrors(formData);

  return formErrors;
};

const getIncomeErrors = (formData: Application): undefined | Status[] => {
  const minimumIncome = 25000;
  const errorMsg = "Your total income must be at least $25,000 per year";

  const primaryIncome = getAnnualAmount(
    +formData.incomeAmount,
    formData.incomeFrequency
  );
  const otherIncome = getAnnualAmount(
    +formData.otherIncomeAmount_1,
    formData.otherIncomeFrequency_1
  );
  const otherIncome2 = getAnnualAmount(
    +formData.otherIncomeAmount_2,
    formData.otherIncomeFrequency_2
  );
  const otherIncome3 = getAnnualAmount(
    +formData.otherIncomeAmount_3,
    formData.otherIncomeFrequency_3
  );

  const totalIncome = primaryIncome + otherIncome + otherIncome2 + otherIncome3;

  if (totalIncome < minimumIncome) {
    return [{ kind: "error", message: errorMsg }];
  }

  return undefined;
};

const getSpendingErrors = (formData: Application): undefined | Status[] => {
  const minimumSpent = 0;
  const errorMsg = "Your total monthly living expenses must be greater than $0";

  const totalExpenses =
    +formData.groceries +
    +formData.clothing +
    +formData.council_rates +
    +formData.car_registration +
    +formData.petrol +
    +formData.car_home_insurance +
    +formData.utilities +
    +formData.communication +
    +formData.transportation +
    +formData.entertainment +
    +formData.other_general_expenses;

  if (totalExpenses <= minimumSpent) {
    return [{ kind: "error", message: errorMsg }];
  }

  return undefined;
};

export const checkForLoanOrCards = (formData: Application): Debt[] => {
  return getDebtFields(formData).filter(
    (debt) =>
      debt.type === PERSONAL_LOAN_VALUE || debt.type === CREDIT_CARD_VALUE
  );
};

const getOwingErrors = (formData: Application): undefined | Status[] => {
  const statuses: Status[] = [];
  if (
    formData.livingSituationAtAddress.toLowerCase() !== "buyer" &&
    formData.ownsInvestmentProperty === "Yes" &&
    formData.investmentPropertyMortgaged === "Yes" &&
    !declaredMortgage(formData)
  ) {
    statuses.push({
      kind: "error",
      message:
        "You must declare one mortgage as you have told us your investment property is mortgaged.",
    });
  }

  if (
    formData.loanPurpose.toLowerCase() === "consolidation" &&
    checkForLoanOrCards(formData).filter((debt) => debt.consolidate.length > 0)
      .length === 0
  ) {
    statuses.push({
      kind: "error",
      message:
        "Please ensure that at least one credit card or personal loan is being consolidated",
    });
  }

  if (
    formData.loanPurpose.toLowerCase() !== "consolidation" &&
    formData.hasDebts.toLowerCase() === "yes" &&
    !hasAddedDebts(formData)
  ) {
    statuses.push({
      kind: "error",
      message: "Please add all your debts",
    });
  }

  if (statuses.length > 0) {
    return statuses;
  }

  return undefined;
};

const declaredMortgage = (formData: Application): boolean => {
  let mortgages = 0;
  for (let i = 1; i <= 10; i++) {
    const key =
      `financialDebtType_${i}` as keyof typeof applicationFormDefaults;
    if (formData[key] === MORTGAGE_VALUE) {
      mortgages += 1;
    }
  }
  return mortgages > 0;
};

//analytics helpers
export type ApplicationAnalytics = {
  eventName: string;
  loanPurpose: string;
  status: string;
  loanId: string;
  statementPreference?: "digital" | "manual";
  equifax?: number;
  equifaxOneScore?: number;
  lapSource?: string;
  appFormVersion?: string;
};

export function sendLoanApplicationAmplitudeEvent({
  eventName,
  loanPurpose,
  status,
  loanId,
  statementPreference,
  equifaxOneScore,
  lapSource,
  appFormVersion,
}: ApplicationAnalytics) {
  const loanType = loanPurpose === "Car" ? "Secured" : "Unsecured";
  quoteAmplitudeHelper.sendEvent(
    eventName,
    {
      "Loan id": loanId,
      "Loan application status": status,
      "Loan purpose": loanPurpose,
      "Loan type": loanType,
      "Bank statement preference": statementPreference
        ? capitalise(statementPreference)
        : null,
      "LAP source": lapSource,
    },
    {
      "Loan application status": status,
      "Loan purpose": loanPurpose,
      "Loan type": loanType,
      "AS Equifax One Score": equifaxOneScore,
      "LAP source": lapSource,
      "Application form split tests": appFormVersion,
    }
  );
}

/* istanbul ignore next */
function sumRepayments<T>(
  list: T[],
  valueKey: keyof T,
  frequencyKey?: keyof T,
  filter?: (item: T) => boolean
) {
  return list
    .filter(filter ? filter : () => true)
    .reduce((total, currentItem) => {
      const value = currentItem[valueKey];
      const frequency = frequencyKey
        ? `${currentItem[frequencyKey]}`
        : Frequency.Annual;

      if (
        typeof value === "string" ||
        (typeof value === "number" && !isNaN(value))
      ) {
        return total + getAnnualAmount(+value, frequency.toLowerCase());
      }

      return total;
    }, 0);
}

export const mapToAddress = (address: AddressBaseType) => {
  const streetAddress = address.unit
    ? `${address.unit}/${address.street_number}`
    : address.street_number;

  // If address is still empty, assume its not valid
  if (!streetAddress) {
    return "";
  }
  const [streetName, streetType, town, state, postcode] = [
    address.street_name,
    address.street_type,
    address.town,
    address.state,
    address.postcode,
  ].map((name) => name ?? "");
  return `${streetAddress} ${streetName} ${streetType}, ${town} ${state} ${postcode}`.replace(
    " ,",
    ","
  );
};

export const getApplicationFromActive = (
  activeApplications: IncompleteLoanApplication[],
  loanId: string | null | (string | null)[]
) => {
  if (typeof loanId === "string") {
    return activeApplications.find(
      (activeApplication) => `${activeApplication.loanId}` === loanId
    );
  }

  return null;
};
