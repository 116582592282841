import {
  DropdownNavigation,
  FullscreenNavigation,
  Header,
  LogoWisr,
} from "@wisr/react-ui";
import { Link } from "gatsby";
import React, { useCallback } from "react";
import AuthService from "../../auth/login/login-auth.service";
import { analyticsClickEvent } from "../../shared/analytics/analytics.utils";
import { isNotEmpty } from "../../shared/common.utils";
import { ContactLink } from "../../shared/contact-link/contact-link";
import { useGetCreditUser } from "../../user/credit/credit-user.hooks";
import { useGetUser } from "../../user/user.hooks";
import { activePages } from "../navigation.utils";
import headStyle from "./header-navigation.scss";

interface HeaderProps {
  headerElement: HTMLElement;
  activePage?: string;
  minimal?: boolean;
}

export const HeaderNavigationComponent: React.FC<HeaderProps> = ({
  headerElement,
  activePage,
  minimal = false,
}) => {
  const user = useGetUser();
  const creditUser = useGetCreditUser();

  const redirectCallback = useCallback(() => {
    AuthService.signoutRedirect();
  }, []);

  const logoutButton = (
    <button
      onClick={() => {
        analyticsClickEvent("Log out", {
          "Button location": "Navigation",
        });
        redirectCallback();
      }}
    >
      Logout
    </button>
  );
  const contactLink = (
    <ContactLink location={"Navigation"} user={creditUser}>
      Contact
    </ContactLink>
  );
  const isActive = activePages(activePage);

  const homeLink: FullscreenNavigation = {
    link: <Link to="/">Home</Link>,
    active: isActive.home,
  };

  const creditScoresLink: FullscreenNavigation = {
    link: <Link to="/credit">Credit Scores</Link>,
    active: isActive.credit,
  };

  const loansLink: FullscreenNavigation = {
    link: (
      <Link
        to={
          // If user only has one loan go straight to it,
          user.loanCount === 1 && user.applicationCount === 0
            ? `/borrowing/loans?loanId=${user.loanId}`
            : "/borrowing"
        }
      >
        Loans
      </Link>
    ),
    active: isActive.loans,
    hidden: user.loanCount === 0,
  };

  const logoutLink = {
    link: logoutButton,
  };

  const contactMobLink = {
    link: contactLink,
  };

  const headerNavigation = [contactLink, logoutButton];

  const mobileNavigation: FullscreenNavigation[] = [
    homeLink,
    loansLink,
    creditScoresLink,
    contactMobLink,
    logoutLink,
  ];

  const displayName = isNotEmpty(user)
    ? user.info && user.info.firstName && user.info.lastName
      ? `${user.info.firstName} ${user.info.lastName}`
      : !!user.profile && !!user.profile.email
      ? user.profile.email
      : ""
    : "";

  return (
    <>
      <style jsx>{headStyle}</style>
      {minimal ? (
        <div>
          <Header
            logoNode={
              <Link to="/" className="logo">
                <LogoWisr />
              </Link>
            }
          />
        </div>
      ) : (
        <div>
          <Header
            logoNode={
              <Link to="/" className="logo">
                <LogoWisr />
              </Link>
            }
            nav={
              <FullscreenNavigation
                mobileOnly
                background="navy"
                navigation={mobileNavigation}
                attachElement={headerElement}
              />
            }
            content={
              <div className="tablet-view grid">
                <div className="user">
                  <div className="user-name">{displayName}</div>
                </div>
                <DropdownNavigation
                  menuAlign="right"
                  navigation={headerNavigation}
                  contrast
                />
              </div>
            }
          />
        </div>
      )}
    </>
  );
};
