import axios from "axios";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { DASH_API } from "../../shared/url.consts";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  SubmitPartnerReferral,
  SubmitPartnerReferralResponse,
} from "../../types/partner-referral";
import { redirect } from "../../shared/browser.utils";

export const postDrivaReferral = async ({
  application,
  wisrCustomerId,
}: SubmitPartnerReferral) => {
  return axios.post<SubmitPartnerReferralResponse>(
    `${DASH_API}/partnerreferral`,
    {
      application: application,
      wisrCustomerId: wisrCustomerId,
    }
  );
};

export const returnDrivaReferralResponse = async ({
  application,
  wisrCustomerId,
}: any) => {
  if (!wisrCustomerId) return;

  try {
    const response = await postDrivaReferral({
      application,
      wisrCustomerId,
    });
    if (response.status === 200) {
      redirect(response.data.partnerUrl);
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.ESTIMATE_REFERRAL
      );
      return response.data;
    }
  } catch (err: any) {
    sendAppInsightsServerError(
      `error submitting for driva referral: ${err}`,
      AppInsightsErrorType.ESTIMATE_REFERRAL
    );
  }
};

export const submitForPartnerReferral = createAsyncThunk(
  "partnerreferral/post",
  returnDrivaReferralResponse
);
