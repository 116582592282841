/* istanbul ignore file */
import { isBrowser } from "@wisr/common";
import { ClientId } from "../types/auth";

export const IDP = process.env.GATSBY_OIDC_IDP || "";
export const OIDC_REDIRECT =
  isBrowser() && window.location ? window.location.origin : undefined;
export const OIDC_SCOPE = process.env.GATSBY_OIDC_SCOPE_OIDC || "";

export const CLIENT_ID: { [key: string]: ClientId } = {
  DASHBOARD: "wisr.dashboard.app",
  APP: "wisr_roundupapp",
} as const;
