import {
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { AxiosResponse } from "axios";
import { Epic, ofType } from "redux-observable";
import { concat, from, Observable, of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import axios from "../../shared/axios.utils";
import { QUOTE_API } from "../../shared/url.consts";
import { AppAction } from "../../store";
import { QuotesGetActionType, quotesGetSuccessAction } from "./quotes.actions";

export const GETQUOTES_LOADING = "GETQUOTES_LOADING";

export const getQuotes: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(QuotesGetActionType),
    concatMap(() =>
      concat(
        of(loadingStartAction(GETQUOTES_LOADING)),
        getQuotesApi().pipe(map((steps) => quotesGetSuccessAction(steps))),
        of(loadingSuccessAction(GETQUOTES_LOADING))
      ).pipe(catchError((e) => of(loadingFailAction(GETQUOTES_LOADING, e))))
    )
  );

function getQuotesApi(): Observable<DashQuoteBFF.LoanQuoteDTO[]> {
  return from(
    axios.get<DashQuoteBFF.LoanQuoteDTO[]>(`${QUOTE_API}/quotes`)
  ).pipe(map((res: AxiosResponse) => res.data));
}
