import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { DASH_API } from "../../shared/url.consts";
import { BrokerDetailsResponse } from "../../types/broker-details";

export const returnBrokerDetails = async () => {
  return axios.get<BrokerDetailsResponse>(`${DASH_API}/loans/broker-details`);
};

export const returnBrokerDetailsResponse = async () => {
  try {
    const response = await returnBrokerDetails();
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.BROKER_DETAILS
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching broker details: ${err}`,
      AppInsightsErrorType.BROKER_DETAILS
    );
  }
};

export const fetchBrokerDetails = createAsyncThunk(
  "broker-details/get",
  returnBrokerDetailsResponse
);
