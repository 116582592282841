import { BorrowHistoryResponseState } from "./history-borrowing-widget";
import {
  HistoryBorrowingAction,
  HistoryBorrowingGetSuccessActionType,
} from "./history-borrowing-widget.actions";

export const historyBorrowingWidgetReducer = (
  state: BorrowHistoryResponseState = {},
  actions: HistoryBorrowingAction
) => {
  switch (actions.type) {
    case HistoryBorrowingGetSuccessActionType:
      return {
        ...state,
        [actions.loanId]: actions.history,
      };

    default:
      return state;
  }
};
