import { UserMetaData } from "./user";

export type UserAction =
  | UserGetAction
  | UserGetSuccessAction
  | UserStateExpiredAction
  | UserStateExpiringAction;

export type UserGetAction = ReturnType<typeof userGetAction>;
export type UserGetSuccessAction = ReturnType<typeof userGetSuccessAction>;
export type UserStateExpiredAction = ReturnType<typeof userStateExpiredAction>;
export type UserStateExpiringAction = ReturnType<
  typeof userStateExpiringAction
>;

export const UserGetActionType = "user/get" as const;
export function userGetAction() {
  return {
    type: UserGetActionType,
  };
}
export const UserGetSuccessActionType = "user/get/success" as const;
export function userGetSuccessAction(user: UserMetaData) {
  return {
    type: UserGetSuccessActionType,
    user,
  };
}

export const UserStateExpiredType = "user/state/expired" as const;
export function userStateExpiredAction() {
  return {
    type: UserStateExpiredType,
    expired: true,
  };
}

export const UserStateExpiringType = "user/state/expiring" as const;
export function userStateExpiringAction() {
  return {
    type: UserStateExpiringType,
    expired: false,
  };
}
