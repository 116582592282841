import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { DASH_API } from "../../shared/url.consts";
import { TermsResponse } from "../../types/terms";

export const returnApplicationTerms = async () => {
  return axios.get<TermsResponse>(`${DASH_API}/application/terms-consent`);
};

export const returnApplicationTermsResponse = async () => {
  try {
    const response = await returnApplicationTerms();
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.APPLICATION_TERMS
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching application terms: ${err}`,
      AppInsightsErrorType.APPLICATION_TERMS
    );
  }
};

export const fetchApplicationTerms = createAsyncThunk(
  "application-terms/get",
  returnApplicationTermsResponse
);
