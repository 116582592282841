import { OcrApiErrorResponse, OcrApiTransactionSuccess } from "../types/ocr";

export const OcrActionTypes = {
  GET: "user/ocr/get",
  GET_SUCCESS: "user/ocr/get/success",
  GET_FAILED: "user/ocr/get/fail",
  CREATE: "user/ocr/create",
  CREATE_SUCCESS: "user/ocr/create/success",
  CREATE_FAILED: "user/ocr/create/fail",
  CLEAR: "user/ocr/clear",
  INCREMENT_ATTEMPT: "user/ocr/poll",
  SAVE_TRANSACTION_ID: "user/ocr/save/id",
} as const;

export type OcrActions =
  | OcrCreateAction
  | OcrCreateSuccessAction
  | OcrCreateFailAction
  | OcrGetAction
  | OcrIncrementAttemptAction
  | OcrGetSuccessAction
  | OcrGetFailedAction
  | OcrClearStateAction
  | OcrSaveTransactionIdAction;

export type OcrCreateAction = ReturnType<typeof ocrCreateAction>;
export function ocrCreateAction(mobile: string, wisrCustomerId: string) {
  return {
    type: OcrActionTypes.CREATE,
    mobile,
    wisrCustomerId,
  };
}

export type OcrCreateSuccessAction = ReturnType<typeof ocrCreateSuccessAction>;
export function ocrCreateSuccessAction(
  ocrSuccess: OcrApiTransactionSuccess,
  mobile: string,
  wisrCustomerId: string
) {
  return {
    type: OcrActionTypes.CREATE_SUCCESS,
    transactionId: ocrSuccess.transactionId,
    mobile,
    wisrCustomerId,
  };
}

export type OcrCreateFailAction = ReturnType<typeof ocrCreateFailAction>;
export function ocrCreateFailAction(ocrError: OcrApiErrorResponse) {
  return {
    type: OcrActionTypes.CREATE_FAILED,
    error: ocrError.message,
  };
}

export type OcrGetAction = ReturnType<typeof ocrGetAction>;
export function ocrGetAction() {
  return {
    type: OcrActionTypes.GET,
  };
}

export type OcrIncrementAttemptAction = ReturnType<
  typeof ocrIncrementAttemptAction
>;
export function ocrIncrementAttemptAction() {
  return {
    type: OcrActionTypes.INCREMENT_ATTEMPT,
  };
}

export type OcrGetSuccessAction = ReturnType<typeof ocrGetSuccessAction>;
export function ocrGetSuccessAction(
  customerData: DashOcrLabs.DocumentItemSchemaConfirmedData,
  result: string | null
) {
  return {
    type: OcrActionTypes.GET_SUCCESS,
    customerData,
    result,
  };
}
export type OcrGetFailedAction = ReturnType<typeof ocrGetFailedAction>;
export function ocrGetFailedAction(ocrError: OcrApiErrorResponse) {
  return {
    type: OcrActionTypes.GET_FAILED,
    error: ocrError.message,
  };
}

export type OcrClearStateAction = ReturnType<typeof ocrClearStateAction>;
/* istanbul ignore next - not covered in our usual epic testing */
export function ocrClearStateAction() {
  return {
    type: OcrActionTypes.CLEAR,
  };
}

export type OcrSaveTransactionIdAction = ReturnType<
  typeof ocrSaveTransactionIdAction
>;
export function ocrSaveTransactionIdAction(transactionId: string) {
  return {
    type: OcrActionTypes.SAVE_TRANSACTION_ID,
    transactionId,
  };
}
