import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  RateEstimatePrefillState,
  RateEstimatePrefillStore,
} from "../../types/rate-estimate-prefill";
import { fetchRateEstimatePrefill } from "./rate-estimate-prefill.thunk";

const initialRateEstimatePrefillState: RateEstimatePrefillState = {
  status: FetchStatus.IDLE,
};

export const rateEstimatePrefillSlice = createSlice({
  name: "rateEstimatePrefill",
  initialState: initialRateEstimatePrefillState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRateEstimatePrefill.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchRateEstimatePrefill.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.rateEstimatePrefill = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "rate estimate prefill get failed";
        }
      })
      .addCase(fetchRateEstimatePrefill.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export const selectRateEstimatePrefillState = (
  store: RateEstimatePrefillStore
) => store.rateEstimatePrefillState;

export default rateEstimatePrefillSlice.reducer;
