import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  ApplicationLoginState,
  ApplicationLoginStore,
} from "../../types/application-login";
import { fetchApplicationLogin } from "./application-login.thunk";

const initialApplicationLoginState: ApplicationLoginState = {
  status: FetchStatus.IDLE,
  loginResponse: null,
};

export const applicationLoginSlice = createSlice({
  name: "applicationLogin",
  initialState: initialApplicationLoginState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchApplicationLogin.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchApplicationLogin.fulfilled, (state, action) => {
        if (action.payload?.tokenDetails) {
          state.status = FetchStatus.SUCCESS;
          state.loginResponse = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "Login error";
        }
      })
      .addCase(fetchApplicationLogin.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export default applicationLoginSlice.reducer;

export const selectApplicationLoginState = (store: ApplicationLoginStore) =>
  store.applicationLoginState;
