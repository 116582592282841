import { useSelector } from "react-redux";
import { AppStore } from "../../store";
import { CreditUserProfile, CreditUserStatusState } from "./credit-user";

export const useGetCreditUser = () => {
  return useSelector<AppStore, CreditUserProfile | undefined>(
    (store) => store.creditUserState
  );
};

export const useCreditUserStatus = () => {
  return useSelector<AppStore, CreditUserStatusState | undefined>(
    (store) => store.creditUserStatusState
  );
};
