import { QuoteForm } from "./quote-form";

export type QuoteFormAction = QuoteFormSetAction;

export type QuoteFormSetAction = ReturnType<typeof quoteFormSetAction>;
export const QuoteFormSetActionType = "quote-form/set" as const;
export function quoteFormSetAction(quoteForm: QuoteForm) {
  return {
    type: QuoteFormSetActionType,
    quoteForm,
  };
}
