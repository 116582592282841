import {
  FinancialMoodResponse,
  FinancialMoodState,
} from "../types/financial-mood";

export const GET_FINANCIAL_MOOD_LOADING = "GET_FINANCIAL_MOOD_LOADING";
export const UPDATE_FINANCIAL_MOOD_LOADING = "UPDATE_FINANCIAL_MOOD_LOADING";

export const FinancialMoodActionTypes = {
  GET: "user/financial-mood/get",
  GET_SUCCESS: "user/financial-mood/get/success",
  GET_FAILED: "user/financial-mood/get/fail",
  UPDATE: "user/financial-mood/update",
  UPDATE_SUCCESS: "user/financial-mood/update/success",
  UPDATE_FAILED: "user/financial-mood/update/fail",
  TOGGLE_MODAL: "TOGGLE_MODAL",
} as const;

export type FinancialMoodAction =
  | FinancialMoodGetAction
  | FinancialMoodGetSuccessAction
  | FinancialMoodUpdateAction
  | FinancialMoodUpdateSuccessAction
  | FinancialMoodUpdateFailAction
  | FinancialMoodToggleModalAction;

export type FinancialMoodGetAction = ReturnType<typeof financialMoodGetAction>;
export function financialMoodGetAction() {
  return {
    type: FinancialMoodActionTypes.GET,
  };
}

export type FinancialMoodGetSuccessAction = ReturnType<
  typeof financialMoodGetSuccessAction
>;
export function financialMoodGetSuccessAction(
  financialMood: FinancialMoodState
) {
  return {
    type: FinancialMoodActionTypes.GET_SUCCESS,
    financialMood,
  };
}

export type FinancialMoodUpdateAction = ReturnType<
  typeof financialMoodUpdateAction
>;
export function financialMoodUpdateAction(
  financialMood: FinancialMoodResponse
) {
  return {
    type: FinancialMoodActionTypes.UPDATE,
    financialMood,
  };
}

export type FinancialMoodUpdateSuccessAction = ReturnType<
  typeof financialMoodUpdateSuccessAction
>;
export function financialMoodUpdateSuccessAction(
  financialMood: FinancialMoodResponse
) {
  return {
    type: FinancialMoodActionTypes.UPDATE_SUCCESS,
    financialMood,
  };
}

export type FinancialMoodUpdateFailAction = ReturnType<
  typeof financialMoodUpdateFailAction
>;
export function financialMoodUpdateFailAction() {
  return {
    type: FinancialMoodActionTypes.UPDATE_FAILED,
  };
}

export type FinancialMoodToggleModalAction = ReturnType<
  typeof financialMoodToggleModalAction
>;
export function financialMoodToggleModalAction() {
  return {
    type: FinancialMoodActionTypes.TOGGLE_MODAL,
  };
}
