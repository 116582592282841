import {
  DocumentsBorrowingAction,
  DocumentsBorrowingGetSuccessActionType,
} from "./documents-borrowing-widget.actions";
import { DocumentsBorrowingState } from "./documents-borrowing";

export const documentsBorrowingWidgetReducer = (
  state: DocumentsBorrowingState = {},
  actions: DocumentsBorrowingAction
): DocumentsBorrowingState => {
  switch (actions.type) {
    case DocumentsBorrowingGetSuccessActionType:
      return {
        ...state,
        [actions.loanId]: actions.documents,
      };

    default:
      return state;
  }
};
