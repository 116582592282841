// This is being ignored as mocking window functions can be troublesome and it's literally one line of obvious code
/* istanbul ignore next */
export function redirect(url: string) {
  window.location.assign(url);
}

/* istanbul ignore next */
export function getCookie(key: string) {
  return document.cookie
    .split("; ")
    ?.find((row) => row.startsWith(key))
    ?.split("=")[1];
}

/* istanbul ignore next */
export function getGCLID(): string | undefined {
  try {
    const cookie = getCookie("_gcl_aw");
    return cookie?.split(".")[2];
  } catch (e) {
    console.log("No GoogleClickId found");
    return undefined;
  }
}
