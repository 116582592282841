import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { DASH_API } from "../../shared/url.consts";
import { LoanParametersResponse } from "../../types/loan-parameters";

export const returnResponse = async () => {
  return axios.get<LoanParametersResponse>(
    `${DASH_API}/loanApplication/parameters`
  );
};

export const payloadCreator = async () => {
  try {
    const response = await returnResponse();
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log(`error fetching loan parameters: ${err}`);
  }
};

export const fetchLoanParameters = createAsyncThunk(
  "loan-parameters/get",
  payloadCreator
);
