import { LoansHome } from "../../../types/personal-loan";

export type LoanBorrowingAction =
  | LoanBorrowingGetAction
  | LoanBorrowingGetSuccessAction;

export const LoanBorrowingGetActionType = "loan/get" as const;
export type LoanBorrowingGetAction = ReturnType<typeof loanBorrowingGetAction>;
export function loanBorrowingGetAction() {
  return {
    type: LoanBorrowingGetActionType,
  };
}
export const LoanBorrowingGetSuccessActionType = "loan/get/success" as const;
export type LoanBorrowingGetSuccessAction = ReturnType<
  typeof loanBorrowingGetSuccessAction
>;
export function loanBorrowingGetSuccessAction(loans: LoansHome) {
  return {
    type: LoanBorrowingGetSuccessActionType,
    loans,
  };
}
