import { isBrowser } from "@wisr/common";
import { Container, Wrapper } from "@wisr/react-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isWebviewCallHandlerDefined } from "../auth/auth.utils";
import { HeaderNavigationComponent } from "../navigation/header/header-navigation.component";
import { SideNavigationComponent } from "../navigation/side/side-navigation.component";
import { clientIdUpdateAction } from "../redux/client-id/client-id.actions";
import { useGetIsAppUser } from "../redux/client-id/client-id.hooks";
import { Banner } from "../shared/banner/banner";
import { isDebugMode } from "../shared/config.utils";
import { ErrorBoundary } from "../shared/error-boundary/error-boundary";
import { CLIENT_ID } from "../shared/oidc.consts";
import { BreadcrumbComponent } from "./breadcrumbs/breadcrumbs.component";
import { HelmetSeoComponent } from "./helmet-seo.component";
import { IframeContent } from "./iframe.component";
import globalStyle from "./layout.global.scss";
import localStyle from "./layout.scss";
import { VersionComponent } from "./version.component";

export const Layout: React.FC<{
  activePage?: string;
}> = ({ children, activePage }) => {
  const [domReady, setDomReady] = useState(false);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const { dashboardBanner } = useFlags(); // string
  const dispatch = useDispatch();
  const isAppUser = useGetIsAppUser();

  React.useEffect(() => {
    if (headerRef.current) {
      // Triggers re-render
      setDomReady(true);
    }
  }, [headerRef]);

  React.useEffect(() => {
    if (isWebviewCallHandlerDefined() && !isAppUser) {
      dispatch(clientIdUpdateAction(CLIENT_ID.APP));
    }
  }, [dispatch, isAppUser]);

  if (isBrowser() && window.self !== window.top) {
    return <IframeContent />;
  }

  return (
    <ErrorBoundary>
      <Wrapper>
        <style jsx>{globalStyle}</style>
        <style jsx>{localStyle}</style>
        <div
          className={`main-content ${
            activePage?.split("/").slice(-2, -1)[0]
          }-page`}
        >
          <HelmetSeoComponent
            title="Dashboard"
            description="The onestop dashboard for all your Wisr services"
          />
          {dashboardBanner && <Banner banner="info">{dashboardBanner}</Banner>}
          <div className="header" ref={headerRef}>
            {domReady && headerRef.current && !isAppUser && (
              <HeaderNavigationComponent
                activePage={activePage}
                headerElement={headerRef.current}
              />
            )}
          </div>

          <div className="body">
            <div className="spacer"></div>
            <Container>
              <div className="container">
                {!isAppUser && (
                  <SideNavigationComponent activePage={activePage || ""} />
                )}
                <div className="content">
                  {!isAppUser && (
                    <BreadcrumbComponent activePage={activePage || ""} />
                  )}

                  {children}
                </div>
              </div>
            </Container>
            <div className="spacer"></div>
          </div>
        </div>
        {isDebugMode() && <VersionComponent />}
      </Wrapper>
    </ErrorBoundary>
  );
};
