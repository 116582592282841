import {
  loadingFailAction,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { AxiosResponse } from "axios";
import { Epic, ofType } from "redux-observable";
import { concat, from, Observable, of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import axios from "../../../shared/axios.utils";
import { noopAction } from "../../../shared/common.actions";
import { getCreditScoresConnection } from "../../../shared/signalr/signalr.utils";
import { DASH_API } from "../../../shared/url.consts";
import { AppAction, AppStore } from "../../../store";
import {
  CreditScoreResponse,
  CreditScoresHome,
} from "../../../types/score-credit-widget";
import {
  ScoreCreditGetActionType,
  scoreCreditGetSuccessAction,
  scoreCreditHomeGetSuccessAction,
} from "./score-credit-widget.actions";

export const GETSCORE_CREDIT_LOADING = "GETSCORE_CREDIT_LOADING";

export const getScoreCreditWidget: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(ScoreCreditGetActionType),
    concatMap(() =>
      concat(
        of(loadingStartAction(GETSCORE_CREDIT_LOADING)),
        getScoreCreditBorrowing().pipe(
          map((creditScores) => {
            return scoreCreditHomeGetSuccessAction(creditScores);
          })
        ),
        of(loadingSuccessAction(GETSCORE_CREDIT_LOADING))
      ).pipe(
        catchError((e) => of(loadingFailAction(GETSCORE_CREDIT_LOADING, e)))
      )
    )
  );

export function getScoreCreditBorrowing(): Observable<CreditScoresHome> {
  return from(
    axios.get<CreditScoresHome>(`${DASH_API}/creditscores/home`)
  ).pipe(map((res: AxiosResponse) => res.data));
}

export const getScoreCreditWidgetStream: Epic<
  AppAction,
  AppAction,
  AppStore
> = (actions) =>
  actions.pipe(
    ofType(ScoreCreditGetActionType),
    concatMap(() =>
      concat(
        streamScores().pipe(
          map((message) => {
            return message
              ? scoreCreditGetSuccessAction(message)
              : noopAction();
          })
        )
      )
    )
  );

export function streamScores() {
  return new Observable<CreditScoreResponse>((subscriber) => {
    const connection = getCreditScoresConnection(0);
    if (connection) {
      connection.on(
        "ReceiveCustomerProfileData",
        (message: CreditScoreResponse) => {
          subscriber.next(message);
        }
      );
    }
  });
}
