import { ContentCard } from "../types/content-card";

export const UPDATE_CONTENT_CARDS = "UPDATE_CONTENT_CARDS";
export type ContentCardsAction = ReturnType<typeof updateContentCards>;

export function updateContentCards(contentCards: ContentCard[]) {
  return {
    type: UPDATE_CONTENT_CARDS,
    contentCards,
  };
}
