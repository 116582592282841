/* istanbul ignore file */

import { IdentityTypes } from "../../forms/credit-profile/credit-profile-form.options";
import {
  CurrentResidentialAddress,
  CustomerAddress,
  ProfileUpdateRequest,
} from "../user";

export interface CreditUserProfileResponse {
  customerProfile: CreditUserProfile;
  hasProfile: boolean;
  isQuestionnaireCompleted: boolean;
  partnerReferrer?: string;
  versionNumber: number;
}

export interface CreditUserProfile {
  versionNumber: number;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  mobile: string;
  dateOfBirth: string;
  residentialAddress: string;
  identityType1: string;
  lastUpdatedDocumentType?: IdentityTypes;
  maritalStatus: string;
  numFinancialDependents: number;
  currentAddress?: CustomerAddress;
  medicareNumber?: string;
  medicareColour?: string;
  medicareExpiryDate?: string;
  medicareExpiryDateGreen?: string;
  medicareReferenceNumber?: string;
  passportNumber?: string;
  passportCountry?: string;
  driversLicense?: string;
  licenceState?: string;
  licenceCardNumber?: string;
}

export const CreditUserDefaults: CreditUserProfile = {
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  mobile: "",
  dateOfBirth: "",
  residentialAddress: "",
  identityType1: IdentityTypes.DRIVERS_LICENCE,
  lastUpdatedDocumentType: IdentityTypes.DRIVERS_LICENCE,
  medicareNumber: "",
  medicareColour: "",
  medicareExpiryDate: "",
  medicareReferenceNumber: "",
  passportNumber: "",
  passportCountry: "",
  driversLicense: "",
  licenceState: "",
  licenceCardNumber: "",
  numFinancialDependents: 0,
  maritalStatus: "",
  currentAddress: {
    personAddressId: "",
    unit: "",
    streetNumber: "",
    streetName: "",
    streetType: "",
    suburb: "",
    state: "",
    postcode: "",
    country: "AUS",
    typeOfAddress: CurrentResidentialAddress,
    livingArrangements: "",
    startDate: "",
    endDate: null,
  },
  versionNumber: 0,
};

export type CreditUserProfileUpdate = CreditUserProfile & ProfileUpdateRequest;

export interface CreditUserStatusState {
  status: CreditUserStatus | null;
}

export enum CreditUserStatus {
  SUCCESS = "Success",
  FAILED = "Failed",
}
