import { QuotesActions, QuotesGetSuccessActionType } from "./quotes.actions";

export interface QuotesState {
  quotes?: DashQuoteBFF.LoanQuoteDTO[];
}

export const quotesReducer = (
  state: QuotesState = {},
  actions: QuotesActions
) => {
  switch (actions.type) {
    case QuotesGetSuccessActionType:
      return {
        quotes: actions.quotes,
      };

    default:
      return state;
  }
};
