/* istanbul ignore file */
import {
  LoadingAction,
  loadingReducer,
  LoadingState,
  LoadingStore,
} from "@wisr/common";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { ContentCardsAction } from "./content-card/content-card.actions";
import { contentCardReducer } from "./content-card/content-card.reducer";
import { FinancialMoodAction } from "./financial-mood/financial-mood.actions";
import {
  getFinancialMood,
  updateFinancialMood,
} from "./financial-mood/financial-mood.epic";
import { financialMoodReducer } from "./financial-mood/financial-mood.reducer";
import { RequestPayoutAction } from "./forms/request-payout/request-payout-form.actions";
import { postRequestPayoutForm } from "./forms/request-payout/request-payout-form.epic";
import { CreditInsightsState } from "./insights/credit/credit-insights";
import { InsightsCreditPageAction } from "./insights/credit/credit-insights.actions";
import { getInsightsCreditPage } from "./insights/credit/credit-insights.epic";
import { insightsCreditPageReducer } from "./insights/credit/credit-insights.reducer";
import { OcrActions } from "./ocr/ocr.actions";
import {
  createOcrTransaction,
  getOcrTransaction,
  ocrIncrementAttempt,
} from "./ocr/ocr.epic";
import { ocrReducer } from "./ocr/ocr.reducer";
import { ClientIdAction } from "./redux/client-id/client-id.actions";
import { clientIdReducer } from "./redux/client-id/client-id.reducer";
import { CreditProfileActions } from "./redux/credit-profile/credit-profile.actions";
import { getCreditProfile } from "./redux/credit-profile/credit-profile.epic";
import {
  creditProfileReducer,
  CreditProfileState,
} from "./redux/credit-profile/credit-profile.reducer";
import { QuotesActions } from "./redux/quotes/quotes.actions";
import { getQuotes } from "./redux/quotes/quotes.epic";
import { quotesReducer, QuotesState } from "./redux/quotes/quotes.reducer";
import { WisrProfileActions } from "./redux/wisr-profile/wisr-profile.actions";
import { getWisrProfile } from "./redux/wisr-profile/wisr-profile.epic";
import {
  wisrProfileReducer,
  WisrProfileState,
} from "./redux/wisr-profile/wisr-profile.reducer";
import { NoopAction } from "./shared/common.actions";
import { ClientIdState } from "./types/auth";
import { ContentCardState } from "./types/content-card";
import { FinancialMoodState } from "./types/financial-mood";
import { OcrState } from "./types/ocr";
import {
  CreditUserProfile,
  CreditUserStatusState,
} from "./user/credit/credit-user";
import {
  CreditUserAction,
  CreditUserStatusAction,
} from "./user/credit/credit-user.actions";
import {
  getCreditUser,
  saveCreditUser,
  updateCreditUser,
} from "./user/credit/credit-user.epic";
import {
  creditUserReducer,
  creditUserStatusReducer,
} from "./user/credit/credit-user.reducer";
import { UserMetaData } from "./user/user";
import { UserAction } from "./user/user.actions";
import { getUserSuccess } from "./user/user.epic";
import { userReducer } from "./user/user.reducer";
import { DocumentsBorrowingState } from "./widgets/borrowing/documents/documents-borrowing";
import {
  DocumentLinkBorrowingAction,
  DocumentsBorrowingAction,
} from "./widgets/borrowing/documents/documents-borrowing-widget.actions";
import {
  getDocumentLinkWidget,
  getDocumentsBorrowingWidget,
} from "./widgets/borrowing/documents/documents-borrowing-widget.epic";
import { documentsBorrowingWidgetReducer } from "./widgets/borrowing/documents/documents-borrowing-widget.reducer";
import { BorrowHistoryResponseState } from "./widgets/borrowing/history/history-borrowing-widget";
import { HistoryBorrowingAction } from "./widgets/borrowing/history/history-borrowing-widget.actions";
import { getHistoryBorrowingWidget } from "./widgets/borrowing/history/history-borrowing-widget.epic";
import { historyBorrowingWidgetReducer } from "./widgets/borrowing/history/history-borrowing-widget.reducer";
import { LoanBorrowingAction } from "./widgets/borrowing/personal-loan/personal-loan-borrowing-widget.actions";
import { getLoanBorrowingWidget } from "./widgets/borrowing/personal-loan/personal-loan-borrowing-widget.epic";
import { loanBorrowingWidgetReducer } from "./widgets/borrowing/personal-loan/personal-loan-borrowing-widget.reducer";
import { PersonalLoansState } from "./types/personal-loan";
import { RepaymentsBorrowingState } from "./widgets/borrowing/repayments/repayments-borrowing-widget";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import applicationHeaderReducer from "./redux/application-header/application-header.slice";
import mobileVerificationCodeReducer from "./forms/credit-profile/mobile-verification/verification-code/verification-code.slice";
import mobileVerificationNumberReducer from "./forms/credit-profile/mobile-verification/verification-number/verification-number.slice";
import loanParametersReducer from "./loans/parameters/loan-parameters.slice";
import customerLoanParametersReducer from "./redux/customer-loan-parameters/customer-loan-parameters.slice";
import loanOfferReducer from "./redux/loan-offer/loan-offer.slice";
import rateOfferReducer from "./redux/rate-offer/rate-offer.slice";
import applicationLoginReducer from "./application/login/application-login.slice";
import loanApplicationReducer from "./redux/loan-application/loan-application.slice";
import loanApplicationStatusReducer from "./redux/loan-application-status/loan-application-status.slice";
import estimateAcceptReducer from "./redux/estimate-accept/estimate-accept.slice";
import rateEstimateReducer from "./redux/rate-estimate/rate-estimate.slice";
import { LoanOfferState } from "./types/loan-offer";
import { LoanParametersState } from "./types/loan-parameters";
import {
  MobileVerificationCodeState,
  MobileVerificationNumberState,
} from "./types/mobile-verification";
import { PersonalisedRateEstimateState } from "./types/personalised-rate-estimate";
import { RateOfferState } from "./types/rate-offer";
import { ScoreCreditWidgetState } from "./types/score-credit-widget";
import personalisedRateEstimateReducer from "./widgets/borrowing/rate-estimate/personalised/personalised-rate-estimate.slice";
import { ScoreCreditAction } from "./widgets/credit/score/score-credit-widget.actions";
import {
  getScoreCreditWidget,
  getScoreCreditWidgetStream,
} from "./widgets/credit/score/score-credit-widget.epic";
import { scoreCreditWidgetReducer } from "./widgets/credit/score/score-credit-widget.reducer";
import { getAggregatorEpic } from "./aggregator/aggregator.epic";
import {
  getApplicationEpic,
  getApplicationContinueTokenEpic,
  saveApplicationEpic,
  submitApplicationEpic,
  clearApplicationEpic,
} from "./application/application-form.epic";
import { applicationFormReducer } from "./application/application-form.reducer";
import {
  aggregatorReducer,
  AggregatorState,
} from "./aggregator/aggregator.reducer";
import { AggregatorAction } from "./aggregator/aggregator.actions";
import { ApplicationFormAction } from "./application/application-form.actions";
import { ApplicationHeaderState } from "./types/application-header";
import { ApplicationFormState } from "./types/application-from";
import { ApplicationLoginState } from "./types/application-login";
import { LoanApplicationState } from "./types/loan-application";
import { EstimateAcceptState } from "./types/estimate-accept";
import { RateEstimateState } from "./types/rate-estimate";
import { LoanApplicationStatusState } from "./types/loan-application-status";
import { PartnerReferralState } from "./types/partner-referral";
import partnerReferralReducer from "./redux/partner-referral/partner-referral.slice";
import { HomeProductsState } from "./types/home-products";
import homeProductsReducer from "./redux/home-products/home-products.slice";
import { ApplicationTermsState, RateEstimateTermsState } from "./types/terms";
import applicationTermsReducer from "./redux/application-terms/application-terms.slice";
import rateEstimateTermsReducer from "./redux/rate-estimate-terms/rate-estimate-terms.slice";
import { quoteFormReducer, QuoteFormState } from "./quote/quote-form.reducer";
import { QuoteFormAction } from "./quote/quote-form.actions";
import { RateEstimatePrefillState } from "./types/rate-estimate-prefill";
import rateEstimatePrefillReducer from "./redux/rate-estimate-prefill/rate-estimate-prefill.slice";
import { BrokerDetailsState } from "./types/broker-details";
import brokerDetailsReducer from "./redux/broker-details/broker-details.slice";

const epics = combineEpics(
  getDocumentsBorrowingWidget,
  getDocumentLinkWidget,
  getHistoryBorrowingWidget,
  getScoreCreditWidget,
  getScoreCreditWidgetStream,
  getLoanBorrowingWidget,
  getInsightsCreditPage,
  getFinancialMood,
  updateFinancialMood,
  getCreditUser,
  saveCreditUser,
  updateCreditUser,
  postRequestPayoutForm,
  getUserSuccess,
  getQuotes,
  getCreditProfile,
  getWisrProfile,
  getOcrTransaction,
  createOcrTransaction,
  ocrIncrementAttempt,
  getAggregatorEpic,
  getApplicationEpic,
  getApplicationContinueTokenEpic,
  saveApplicationEpic,
  submitApplicationEpic,
  clearApplicationEpic
);
const epicMiddleware = createEpicMiddleware<
  AppAction,
  AppAction,
  AppStore,
  any
>();

const middleWares: any[] = [epicMiddleware];

export const reducers = combineReducers({
  documentsBorrowingWidgetState: documentsBorrowingWidgetReducer,
  historyBorrowingWidgetState: historyBorrowingWidgetReducer,
  loanBorrowingWidgetState: loanBorrowingWidgetReducer,
  scoreCreditWidgetState: scoreCreditWidgetReducer,
  creditUserState: creditUserReducer,
  creditUserStatusState: creditUserStatusReducer,
  insightsCreditPageState: insightsCreditPageReducer,
  userState: userReducer,
  contentCardState: contentCardReducer,
  loadingState: loadingReducer,
  quotesState: quotesReducer,
  wisrProfileState: wisrProfileReducer,
  creditProfileState: creditProfileReducer,
  financialMoodState: financialMoodReducer,
  ocrState: ocrReducer,
  clientIdState: clientIdReducer,
  personalisedRateEstimateState: personalisedRateEstimateReducer,
  mobileVerificationNumberState: mobileVerificationNumberReducer,
  mobileVerificationCodeState: mobileVerificationCodeReducer,
  rateOfferState: rateOfferReducer,
  loanOfferState: loanOfferReducer,
  loanParametersState: loanParametersReducer,
  customerLoanParametersState: customerLoanParametersReducer,
  aggregatorState: aggregatorReducer,
  applicationHeaderState: applicationHeaderReducer,
  applicationFormState: applicationFormReducer,
  applicationLoginState: applicationLoginReducer,
  loanApplicationState: loanApplicationReducer,
  loanApplicationStatusState: loanApplicationStatusReducer,
  estimateAcceptState: estimateAcceptReducer,
  rateEstimateState: rateEstimateReducer,
  partnerReferralState: partnerReferralReducer,
  homeProductsState: homeProductsReducer,
  applicationTermsState: applicationTermsReducer,
  rateEstimateTermsState: rateEstimateTermsReducer,
  quoteFormState: quoteFormReducer,
  rateEstimatePrefillState: rateEstimatePrefillReducer,
  brokerDetailsState: brokerDetailsReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleWares),
});

epicMiddleware.run(epics);

// Enable this rule once you add any reducers
export interface AppStore extends LoadingStore {
  documentsBorrowingWidgetState: DocumentsBorrowingState;
  repaymentsBorrowingWidgetState: RepaymentsBorrowingState;
  historyBorrowingWidgetState: BorrowHistoryResponseState;
  loanBorrowingWidgetState: PersonalLoansState;
  scoreCreditWidgetState: ScoreCreditWidgetState;
  creditUserState: CreditUserProfile;
  creditUserStatusState: CreditUserStatusState;
  insightsCreditPageState: CreditInsightsState;
  userState: UserMetaData;
  contentCardState: ContentCardState;
  loadingState: LoadingState;
  quotesState: QuotesState;
  wisrProfileState: WisrProfileState;
  creditProfileState: CreditProfileState;
  financialMoodState: FinancialMoodState;
  ocrState: OcrState;
  clientIdState: ClientIdState;
  personalisedRateEstimateState: PersonalisedRateEstimateState;
  mobileVerificationNumberState: MobileVerificationNumberState;
  mobileVerificationCodeState: MobileVerificationCodeState;
  rateOfferState: RateOfferState;
  loanOfferState: LoanOfferState;
  loanParametersState: LoanParametersState;
  customerLoanParametersState: LoanParametersState;
  aggregatorState: AggregatorState;
  applicationHeaderState: ApplicationHeaderState;
  applicationFormState: ApplicationFormState;
  applicationLoginState: ApplicationLoginState;
  loanApplicationState: LoanApplicationState;
  loanApplicationStatusState: LoanApplicationStatusState;
  estimateAcceptState: EstimateAcceptState;
  rateEstimateState: RateEstimateState;
  partnerReferralState: PartnerReferralState;
  homeProductsState: HomeProductsState;
  applicationTermsState: ApplicationTermsState;
  rateEstimateTermsState: RateEstimateTermsState;
  quoteFormState: QuoteFormState;
  rateEstimatePrefillState: RateEstimatePrefillState;
  brokerDetailsState: BrokerDetailsState;
}

export type AppAction =
  | LoadingAction
  | NoopAction
  | DocumentsBorrowingAction
  | DocumentLinkBorrowingAction
  | HistoryBorrowingAction
  | ScoreCreditAction
  | LoanBorrowingAction
  | FinancialMoodAction
  | CreditUserAction
  | CreditUserStatusAction
  | InsightsCreditPageAction
  | RequestPayoutAction
  | UserAction
  | ContentCardsAction
  | QuotesActions
  | WisrProfileActions
  | CreditProfileActions
  | OcrActions
  | ClientIdAction
  | ApplicationFormAction
  | AggregatorAction
  | QuoteFormAction;
