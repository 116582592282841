import React from "react";
import { BRAND_WEBSITE } from "../shared/url.consts";
import { OcrState } from "../types/ocr";

export const GET_OCR_LOADING = "GET_OCR_LOADING";
export const POLL_OCR_LOADING = "POLL_OCR_LOADING";
export const CREATE_OCR_LOADING = "CREATE_OCR_LOADING";

export const EMPTY_OCR_STATE: OcrState = {
  transactionAttemptCount: 0,
  result: null,
};

export enum OcrFeatureFlagStates {
  WISR = "wisr",
  ON = "on",
  OFF = "off",
}

export const OCR_START_TEXT: React.ReactElement = (
  <div className="content">
    <p>
      We have two-step security to ensure that your credit score information is
      kept safe.
    </p>
    <p>
      Step 1: We&apos;ll check that it&apos;s you through an ID and face
      verification process on your mobile device
    </p>
    <p>
      Step 2: You&apos;ll check that your pre-populated personal information is
      correct
    </p>
    <p>
      To start, enter your mobile number and we&apos;ll send an SMS to your
      mobile with instructions. Have your Australian driver&apos;s licence or
      Australian passport ready to go.
    </p>
  </div>
);

export const OCR_TERMS_CONDITIONS: React.ReactElement = (
  <div className="form-free-text">
    <p>
      <strong>By clicking &apos;Start ID check&apos;:</strong>
    </p>
    <ul>
      <li>
        I acknowledge and accept Wisr&apos;s{" "}
        <a
          href={`${BRAND_WEBSITE}/terms-of-use`}
          target="_blank"
          rel="nofollow noopener"
          id="profile_link_terms_use"
        >
          Terms of Use{" "}
        </a>
        and{" "}
        <a
          href={`${BRAND_WEBSITE}/privacy-policy`}
          target="_blank"
          rel="nofollow noopener"
          id="profile_link_privacy_policy"
        >
          Privacy Policy
        </a>
        ,
      </li>
      <li>
        I confirm that I am authorised to provide the personal details presented
        and I consent to my information being checked with the document issuer
        or official record holder via third party systems and services for the
        purpose of confirming my identity.
      </li>
    </ul>
  </div>
);
