import React, { ReactNode } from "react";
import loginAuthService from "../login/login-auth.service";

import { withLDProvider } from "launchdarkly-react-client-sdk";
import { getConfig } from "../../shared/config.utils";
import {
  getLdUser,
  getUser,
  isLoggedIn,
  signInRedirect,
  signinSilent,
  storeUser,
} from "../auth.utils";
import { navigate } from "gatsby";
import { useDispatch } from "react-redux";
import { userGetSuccessAction } from "../../user/user.actions";
import { GatsbyPageProps } from "../../types/gatsby";
import { isBrowser } from "@wisr/common";

interface ApplicationFormComponentProps extends GatsbyPageProps {
  children?: ReactNode;
}

export const ApplicationAuthComponent: React.FC<
  ApplicationFormComponentProps
> = ({ children, location }) => {
  const dispatch = useDispatch();
  const fixedPath = location?.pathname.replace(/\/$/, "");

  React.useEffect(() => {
    if (isBrowser() && fixedPath !== "/login") {
      isLoggedIn()
        .then((user) => {
          if (!user) {
            const url = new URL(window.location.href);
            const searchParams = new URLSearchParams(url.search);
            const refreshToken = searchParams.get("token");
            const authTime = searchParams.get("authtime");
            const customerId = searchParams.get("customerid");
            const loanId =
              searchParams.get("loanid") ?? searchParams.get("loanId");

            if (loanId) {
              window.sessionStorage.setItem("loanId", loanId);
            }

            if (
              refreshToken != null &&
              authTime != null &&
              customerId != null
            ) {
              storeUser(refreshToken, authTime, customerId, "refresh_token");
              signinSilent()
                .then(() => {
                  loginAuthService.getUser()?.then(() => {
                    navigate("/login");
                  });
                })
                .catch(() => {
                  signInRedirect();
                });
            } else {
              const unauthenticatedPages = [
                "/application",
                "/application/login",
                "/application/create-account",
              ];
              if (
                fixedPath !== undefined &&
                !unauthenticatedPages.includes(fixedPath)
              ) {
                navigate("/application/login");
              }
            }
          } else {
            const curUser = getUser();
            if (curUser?.info) {
              dispatch(userGetSuccessAction(curUser));
            }
          }
        })
        .catch(() => signInRedirect());
    }
  }, [dispatch, fixedPath]);

  return <>{children}</>;
};

const { APP_FORM_LAUNCHDARKLY_CLIENTID } = getConfig();

export const ApplicationFormAuthWithLD = withLDProvider({
  clientSideID: APP_FORM_LAUNCHDARKLY_CLIENTID || "",
  user: getLdUser(),
})(ApplicationAuthComponent);
