import dayjs from "dayjs";
import {
  HistoryPayment,
  LOAN_TRANSACTIONS_CODES,
} from "./history-borrowing-widget";
import { defaultHistoryPayment } from "./history-borrowing-widget.constants";

export const totalAmountReducer = (
  acc: HistoryPayment,
  curr: HistoryPayment
) => {
  // Add up just the values we need

  const isCreditedOrDishonour =
    curr.transactionCode !== LOAN_TRANSACTIONS_CODES.FEE_REVERSAL &&
    (curr.creditAmount > 0 ||
      curr.transactionCode === LOAN_TRANSACTIONS_CODES.DISHONOUR);

  if (!isCreditedOrDishonour) {
    // Set first valid effective date, otherwise it would be empty
    return {
      ...acc,
      effectiveDate: acc.effectiveDate || curr.effectiveDate,
    };
  } else
    return {
      ...acc,
      effectiveDate: curr.effectiveDate,
      debitAmount: acc.debitAmount + curr.debitAmount,
      creditAmount: acc.creditAmount + curr.creditAmount,
    };
};

export const monthlyRepaymentsReducer = (repayments: HistoryPayment[]) => {
  // Group up all the arrays by repayment month
  return repayments.reduce((storage, item) => {
    const objKey: string = dayjs(item.effectiveDate)
      .format("MM-YYYY")
      .toString();
    if (
      storage[objKey] &&
      dayjs(storage[objKey][0].effectiveDate).format("MM-YYYY").toString() ===
        objKey
    ) {
      storage[objKey].push(item);
    } else {
      storage[objKey] = [item];
    }
    return storage;
  }, {} as { [key: string]: HistoryPayment[] });
};
export const repaymentHistoryMonthlyData = (repayments: HistoryPayment[]) => {
  const repaymentsArray: HistoryPayment[] = [];
  // Group the repayments by month
  const monthlyRepayments = monthlyRepaymentsReducer(repayments);

  // Reduce the monthly repayments into one, combining the amount
  Object.keys(monthlyRepayments).forEach((item) => {
    const monthlyTotal = monthlyRepayments[item].reduce(
      totalAmountReducer,
      defaultHistoryPayment
    );
    repaymentsArray.push(monthlyTotal);
  });
  return repaymentsArray;
};

export const sortHistoryRepayments = (
  history: HistoryPayment[],
  order?: "ascending" | "descending"
): HistoryPayment[] => {
  // Group up all the arrays by repayment day
  return history.sort((a, b) => {
    if (dayjs(a.effectiveDate).isBefore(dayjs(b.effectiveDate))) {
      return order === "ascending" ? -1 : 1;
    } else if (dayjs(a.effectiveDate).isAfter(dayjs(b.effectiveDate))) {
      return order === "ascending" ? 1 : -1;
    } else {
      return 0;
    }
  });
};
