import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CREDIT_API } from "../../../../shared/url.consts";
import { PersonalisedRateEstimateResponse } from "../../../../types/personalised-rate-estimate";

export const returnResponse = async () => {
  return axios.get<PersonalisedRateEstimateResponse>(
    `${CREDIT_API}/CustomerAccount/RateEstimate/Personalisation`
  );
};

export const payloadCreator = async () => {
  try {
    const response = await returnResponse();
    if (response.status === 200) {
      return response.data.moneyMoments;
    }
  } catch (err) {
    console.log(`error fetching score impacts: ${err}`);
  }
};

export const fetchPersonalisedRateEstimate = createAsyncThunk(
  "personalised-rate-estimate/get",
  payloadCreator
);
