import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { attachStore } from "./src/attach-store";
import { AuthWithLD } from "./src/auth/auth.component";
import { ApplicationFormAuthWithLD } from "./src/auth/application/application-auth.component";
import { isDebugMode } from "./src/shared/config.utils";
import { reactPlugin, appInsights } from "./src/shared/insights/app-insights";

appInsights.loadAppInsights();
export const wrapPageElement = ({ element, props }) => {
  appInsights.trackPageView();
  const isApplication = element.key == "/application/*";

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {isApplication ? (
        <ApplicationFormAuthWithLD {...props}>
          {element}
        </ApplicationFormAuthWithLD>
      ) : (
        <AuthWithLD {...props}>{element}</AuthWithLD>
      )}
    </AppInsightsContext.Provider>
  );
};

export const wrapRootElement = attachStore;

export const registerServiceWorker = () => true;

export const onClientEntry = () => {
  if (isDebugMode()) {
    registerServiceWorker();
  }
};
