import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { GATEWAY_API } from "../../shared/url.consts";
import { LoanOffer } from "../../types/loan-offer";

export const returnLoanOffer = async (quoteId: string) => {
  return axios.get<LoanOffer>(`${GATEWAY_API}/loan-offer?quoteId=${quoteId}`);
};

export const returnLoanOfferResponse = async (quoteId: string) => {
  try {
    const response = await returnLoanOffer(quoteId);
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.LOAN_OFFER
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching loan offer: ${err}`,
      AppInsightsErrorType.LOAN_OFFER
    );
  }
};

export const fetchLoanOffer = createAsyncThunk(
  "loan-offer/get",
  returnLoanOfferResponse
);
