import { AmplitudeHelper, AsyncLib, isBrowser } from "@wisr/common";
import {
  AmplitudeSendUserEvents,
  AnalyticsClickEvents,
  AnalyticsCustomEventNames,
  AnalyticsLoadCreditWidgets,
  AnalyticsLoadLoanWidgets,
  AnalyticsLoadWidgets,
  AnalyticsSubmitFormTypes,
  AnalyticsViewCreditPages,
  AnalyticsViewEventPages,
  AppInsightsError,
  LoanEventData,
  Referrer,
  ScoresEventData,
  SingleScoreEventData,
} from "../../types/analytics";
import { UserMetaData } from "../../user/user";

import { appInsights } from "../insights/app-insights";
import {
  EXCEPTION_ERROR_EVENT,
  SERVER_ERROR,
} from "../insights/app-insights.consts";
import { createAppInsightsEvent } from "../insights/app-insights.utils";
import {
  AMPLITUDE_APPLICATION,
  EVENT,
  GTM_TRACKER_ID,
  QUOTE_APPLICATION,
} from "./analytics.consts";

/* istanbul ignore next */
const scope: Window = isBrowser() ? window : ({} as any);
export const amplitudeHelper = new AmplitudeHelper(AMPLITUDE_APPLICATION);
export const quoteAmplitudeHelper = new AmplitudeHelper(QUOTE_APPLICATION);
export const datalayerHelper = new AsyncLib("dataLayer", scope);
export const brazeHelper = new AsyncLib("dataLayer", scope);
export const googleAnalyticsHelper = new AsyncLib("ga", scope, {
  customTest: (ga) => !!ga.getAll,
});

export const gtmLoaded = () => {
  return isBrowser() && typeof window.dataLayer !== "undefined";
};

export const brazeLoaded = () => {
  return (
    isBrowser() &&
    typeof window.appboy !== "undefined" &&
    typeof window.appboy.getCachedContentCards !== undefined
  );
};

export const gaLoaded = () => {
  return (
    isBrowser() &&
    typeof window.ga !== "undefined" &&
    typeof window.ga.getAll !== "undefined"
  );
};

const getGTMId = () => {
  if (window.google_tag_manager) {
    const idArrays = Object.keys(window.google_tag_manager).filter(
      (key) => key.indexOf("GTM") >= 0
    );
    if (idArrays.length > 0) {
      const gtmId = idArrays[0];
      return window.google_tag_manager[gtmId];
    }
  }
  return undefined;
};

const clearGTMUserProps = () => {
  const gtmID = getGTMId();
  if (gtmID) {
    gtmID.dataLayer.set("userProperties", undefined);
  }
};

const clearGTMEventProps = () => {
  const gtmID = getGTMId();
  if (gtmID) {
    gtmID.dataLayer.set("eventProperties", undefined);
  }
};

const sentenceCase = (camelCase: string) => {
  const sentence = camelCase.replace(/([A-Z])/g, " $1").toLowerCase();
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const brazeSend = (brazeData: any) => {
  if (gtmLoaded() && brazeLoaded()) {
    gtmSendUser("setUserProperties", brazeData);
  } else {
    appInsights.trackEvent(
      EXCEPTION_ERROR_EVENT,
      createAppInsightsEvent(GTMError)
    );
  }
};

export const userInit = (user: UserMetaData | null) => {
  if (user && gtmLoaded() && isBrowser()) {
    datalayerHelper.get().then((dataLayer) => {
      dataLayer.push({ userID: user.profile.sub });
      dataLayer.push({ event: "setUser" });
    });
  }
};

export const gtmSendEvent = (
  event: AnalyticsCustomEventNames,
  eventData: any = {}
) => {
  if (isBrowser()) {
    return datalayerHelper.get().then((dataLayer) => {
      dataLayer.push({
        event: event,
        eventProperties: {
          ...eventData,
          Application: AMPLITUDE_APPLICATION,
        },
      });
      clearGTMEventProps();
    });
  }
  return Promise.resolve();
};
export const gtmSendUser = (
  event: AmplitudeSendUserEvents,
  userData: any = {}
) => {
  if (isBrowser()) {
    return datalayerHelper.get().then((dataLayer) => {
      dataLayer.push({ event: event, userProperties: userData });
      clearGTMUserProps();
    });
  }
  return Promise.resolve();
};

export const amplitudeSendEvent = (
  event: AnalyticsCustomEventNames,
  eventData: any = {}
) => {
  if (isBrowser()) {
    amplitudeSendUser("setUserApplication", {
      userApplication: AMPLITUDE_APPLICATION,
    });
    if (gtmLoaded()) {
      gtmSendEvent(event, eventData);
    } else {
      amplitudeHelper.sendEvent(sentenceCase(event), eventData);
    }
  } else {
    appInsights.trackEvent(
      EXCEPTION_ERROR_EVENT,
      createAppInsightsEvent(GTMError)
    );
  }
};

export const amplitudeSendUser = (
  event: AmplitudeSendUserEvents,
  userData = {}
) => {
  if (isBrowser()) {
    if (gtmLoaded()) {
      gtmSendUser(event, userData);
    } else {
      amplitudeHelper.sendEvent(sentenceCase(event), userData);
    }
  } else {
    appInsights.trackEvent(
      EXCEPTION_ERROR_EVENT,
      createAppInsightsEvent(GTMError)
    );
  }
};

export const getReferrer: any = () => {
  const referrer = {} as Referrer;
  const storageReferrer = window.localStorage.getItem("transactionReferrer");
  if (storageReferrer != null) {
    const transactionReferrer = JSON.parse(storageReferrer);
    if (transactionReferrer.expire > new Date()) {
      referrer.referrer = transactionReferrer.referrer;
    } else {
      localStorage.removeItem("transactionReferrer");
    }
  }
  return referrer;
};

export const generateGoogleAnalytics = () => {
  if (gaLoaded()) {
    return googleAnalyticsHelper.get().then((ga) => {
      for (const tracker of ga.getAll()) {
        if (tracker.get("trackingId") === GTM_TRACKER_ID) {
          const googleAnalytics = tracker.get("linkerParam").split("=");
          return googleAnalytics[1];
        }
      }
    });
  }
  return "";
};

export const analyticsCustomEvent = (
  eventName: AnalyticsCustomEventNames,
  eventData?: any,
  userData?: any
) => {
  userData && amplitudeSendUser("setUserProperties", userData);
  amplitudeSendEvent(eventName, eventData);
};

export const analyticsLoadWidgetEvent = (
  widgetName: AnalyticsLoadWidgets,
  data?: any
) => {
  amplitudeSendEvent(EVENT.WIDGETLOAD, {
    "Widget name": widgetName,
    ...data,
  });
};

export const analyticsLoadCreditWidgetEvent = (
  widgetName: AnalyticsLoadCreditWidgets,
  scoreEventData?: ScoresEventData | SingleScoreEventData
) => {
  amplitudeSendEvent(EVENT.LOADCREDITWIDGET, {
    "Widget name": widgetName,
    ...scoreEventData,
  });
};

export const analyticsLoadLoanWidgetEvent = (
  widgetName: AnalyticsLoadLoanWidgets,
  loanEventData?: LoanEventData
) => {
  amplitudeSendEvent(EVENT.LOADLOANWIDGET, {
    "Widget name": widgetName,
    ...loanEventData,
  });
};

export const analyticsViewPageEvent = (
  pageName: AnalyticsViewEventPages,
  data?: any
) => {
  amplitudeSendEvent(EVENT.VIEWPAGE, {
    "Page name": pageName,
    ...data,
  });
};

export const analyticsClickEvent = (
  clickEvent: AnalyticsClickEvents,
  data?: any
) => {
  amplitudeSendEvent(EVENT.CLICKBUTTON, {
    "Button name": clickEvent,
    ...data,
  });
};

export const analyticsSubmitEvent = (
  formName: AnalyticsSubmitFormTypes,
  data?: any
) => {
  amplitudeSendEvent(EVENT.SUBMITFORM, {
    "Form type": formName,
    ...data,
  });
};
export const analyticsViewCreditPageEvent = (
  pageName: AnalyticsViewCreditPages,
  data?: any
) => {
  amplitudeSendEvent(EVENT.VIEWCREDITSCORES, {
    "Page name": pageName,
    ...data,
  });
};

export const GTMError: AppInsightsError = {
  category: SERVER_ERROR,
  info: "GTM not loaded error",
  type: "apiError",
};

export function sendGtmEvent<E extends { [key: string]: unknown }>(event: E) {
  if (isBrowser()) {
    return datalayerHelper.get().then((datalayer) => {
      datalayer.push(event);
    });
  }
  return Promise.resolve();
}

export function gtmConversion(
  userType: string,
  loanId: number,
  aggregatorId: string | null,
  loanAmount: number,
  loanReason: string
) {
  return sendGtmEvent({
    event: "GTMAppSubmitted",
    loanId: loanId,
    userType: userType,
    aggregatorId: aggregatorId,
    loanAmount: loanAmount,
    loanReason: loanReason,
  });
}

export function gtmEcommerce(
  loanId: number,
  aggregatorName: string | null,
  loanAmount: number,
  loanReason: string,
  loanType: string
) {
  return sendGtmEvent({
    event: "GTMAppFormTransaction",
    eventCategory: "ecommerce",
    eventAction: "purchase",
    ecommerce: {
      purchase: {
        actionField: {
          id: loanId, //transaction ID - mandatory
          affiliation: aggregatorName ? "wisr - " + aggregatorName : "wisr",
          revenue: loanAmount, //total including tax and shipping
          tax: 0,
          shipping: 0,
        },
        products: [
          {
            id: loanId, //transaction ID - mandatory
            name: loanReason,
            category: loanType,
            price: loanAmount,
            quantity: 1,
          },
        ],
      },
    },
  });
}

export function sendGaEvent(
  category: string,
  action: string,
  label?: string,
  value?: number
) {
  if (isBrowser()) {
    return googleAnalyticsHelper.get().then((ga) => {
      const tracker = ga.getAll()[0];
      if (tracker) {
        const trackerName = tracker.get("name");
        ga(`${trackerName}.send`, "event", category, action, label, value);
      }
    });
  }
  return Promise.resolve();
}
