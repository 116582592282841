import { CLIENT_ID } from "../../shared/oidc.consts";
import { ClientIdState } from "../../types/auth";
import { ClientIdActionTypes, ClientIdUpdateAction } from "./client-id.actions";

export const clientIdReducer = (
  state: ClientIdState = { clientId: CLIENT_ID.DASHBOARD },
  action: ClientIdUpdateAction
): ClientIdState => {
  switch (action.type) {
    case ClientIdActionTypes.UPDATE:
      return { clientId: action.clientId };

    default:
      return state;
  }
};
