/* istanbul ignore file */

import dayjs from "dayjs";
import Cookies from "js-cookie";
import { WISR_DOMAIN } from "../shared/url.consts";
import { UserMetaData } from "../user/user";
import AuthService from "./login/login-auth.service";
import { User, Profile } from "oidc-client";
import { OIDC_SCOPE } from "../shared/oidc.consts";

export const isLoggedIn = async () => {
  const user = await AuthService.getUser();

  return !!user;
};

export const signInRedirect = async () => {
  const pathname = window.location.pathname.endsWith("/")
    ? window.location.pathname.slice(0, -1)
    : window.location.pathname;
  await AuthService.signinRedirect(pathname);
};

export const signinSilent = async () => {
  const pathname = window.location.pathname.endsWith("/")
    ? window.location.pathname.slice(0, -1)
    : window.location.pathname;
  await AuthService.signinSilent(pathname);
};

export const getUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) {
    return JSON.parse(user) as UserMetaData;
  }
  return undefined;
};

export const getLdUser = () => {
  if (getUser())
    return {
      key: getUser()?.profile.sub,
      email: getUser()?.profile.email,
      anonymous: false,
    };
  else {
    return undefined;
  }
};

export const registerRedirect = async () => {
  await AuthService.registerRedirect();
};

export const openForgotPasswordPage = async () => {
  await AuthService.openForgotPasswordPage();
};

export const setCookie = () => {
  // Note cookies wont be set on localhost
  Cookies.set("wisrLogin", "true", {
    expires: dayjs().add(1, "hour").toDate(),
    domain: WISR_DOMAIN,
    secure: true,
  });
};

export const storeUser = async (
  refreshToken: string | null,
  authTime: string,
  customerId: string,
  tokenType: "refresh_token" | "Bearer",
  accessToken?: string
) => {
  const authTimeAsInt = parseInt(authTime);
  const emptyUser = new User({
    id_token: "",
    session_state: "",
    access_token: accessToken ?? "",
    refresh_token: refreshToken ?? "",
    token_type: tokenType,
    scope: OIDC_SCOPE,
    profile: {
      auth_time: authTimeAsInt, // CHKPT
      sub: customerId,
    } as Profile,
    expires_at: authTimeAsInt, //add time here??
    state: null,
  });

  await AuthService.storeUser(emptyUser);
};

export const isWebviewCallHandlerDefined = () => {
  return (
    typeof window.flutter_inappwebview !== "undefined" &&
    typeof window.flutter_inappwebview.callHandler !== "undefined"
  );
};
