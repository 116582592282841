import Axios from "axios";
import { Observable } from "rxjs";
import { from } from "rxjs";
import { map } from "rxjs/operators";

import { PACE_API } from "../shared/url.consts";
import { mapToApplication } from "./application.utils";
import { ApplicationRequest } from "./application-request";
import { SaveApplicationResponse } from "./application-save-response";
import {
  CreditScoreResponse,
  GetApplicationResponse,
  GetContinueTokenResponse,
} from "./get-application.response";

export function getApplicationByToken(token: string) {
  return from(getApplicationByTokenPromise(token)).pipe(
    map(([applicationResponse, creditScore]) => ({
      application: mapToApplication(applicationResponse, creditScore),
      applicationRef: applicationResponse.ApplicationRequest.ApplicationRef,
      quoteRefId: applicationResponse.ApplicationRequest.QuoteRefId,
      creditScore: creditScore.CreditScore,
      rateCardVersion: applicationResponse.ApplicationRequest.RateCardVersion,
      rateCardVersionSecured:
        applicationResponse.ApplicationRequest.RateCardVersionSecured,
    }))
  );
}

async function getApplicationByTokenPromise(token: string) {
  const application = (
    await Axios.get<GetApplicationResponse>(
      `${PACE_API}/api/brokers/application/token?token=${token}`
    )
  ).data;

  const creditScore = (
    await Axios.post<CreditScoreResponse>(
      `${PACE_API}/api/brokers/creditscore`,
      {
        loanId: application.ApplicationRequest.LoanId,
      }
    )
  ).data;

  return [application, creditScore] as const;
}

export function getContinueToken(
  token: string
): Observable<{ continueToken: string }> {
  return from(
    Axios.get<GetContinueTokenResponse>(
      `${PACE_API}/api/brokers/application/logintoken?token=${token}`
    )
  ).pipe(
    map((res) => ({
      continueToken: res.data.ContinueToken,
    }))
  );
}

export function saveApplication(
  applicationRequest: ApplicationRequest
): Observable<SaveApplicationResponse> {
  console.debug("Attempt to save loan application", {
    loanId: applicationRequest.LoanId,
    isSubmitted: applicationRequest.IsSubmitted,
  });
  return from(
    Axios.post<SaveApplicationResponse>(
      `${PACE_API}/api/brokers/application`,
      applicationRequest,
      {
        headers: { "content-type": "application/json" },
      }
    ).then((res) => res.data)
  );
}
