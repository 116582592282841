type IncomeSource =
  | "otherIncomeSource_1"
  | "otherIncomeSource_2"
  | "otherIncomeSource_3";

type IncomeAmount =
  | "otherIncomeAmount_1"
  | "otherIncomeAmount_2"
  | "otherIncomeAmount_3";

type IncomeFrequency =
  | "otherIncomeFrequency_1"
  | "otherIncomeFrequency_2"
  | "otherIncomeFrequency_3";

export interface OtherIncomeListItem {
  id: number;
  active: boolean;
  source: IncomeSource;
  amount: IncomeAmount;
  frequency: IncomeFrequency;
}

export const otherIncomeInitialList: OtherIncomeListItem[] = [
  {
    id: 1,
    active: true,
    source: "otherIncomeSource_1",
    amount: "otherIncomeAmount_1",
    frequency: "otherIncomeFrequency_1",
  },
  {
    id: 2,
    active: false,
    source: "otherIncomeSource_2",
    amount: "otherIncomeAmount_2",
    frequency: "otherIncomeFrequency_2",
  },
  {
    id: 3,
    active: false,
    source: "otherIncomeSource_3",
    amount: "otherIncomeAmount_3",
    frequency: "otherIncomeFrequency_3",
  },
];

export const incomeSourceOptions = [
  {
    label: "Rental income",
    value: "rental-income",
  },
  {
    label: "Self-employed business income",
    value: "self-employed-income",
  },
  {
    label: "Government benefits",
    value: "government-benefits",
  },
  {
    label: "Investment income - Interest",
    value: "investments-interest",
  },
  {
    label: "Investment income - Dividends",
    value: "investments-dividends",
  },
];
