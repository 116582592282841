import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppInsightsErrorType } from "../../shared/insights/app-insights.consts";
import { sendAppInsightsServerError } from "../../shared/insights/app-insights.utils";
import { DASH_API } from "../../shared/url.consts";
import { SaveLoanApplication } from "../../types/loan-application";

export const returnLoanApplication = async (wisrApplicationId: string) => {
  return axios.get<DashAPI.LoanApplicationModel>(
    `${DASH_API}/loanApplication/${wisrApplicationId}`
  );
};

export const returnLoanApplicationResponse = async (
  wisrApplicationId: string
) => {
  try {
    const response = await returnLoanApplication(wisrApplicationId);
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.GET_LOAN_APPLICATION
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error fetching loan application: ${err}`,
      AppInsightsErrorType.GET_LOAN_APPLICATION
    );
  }
};

export const fetchLoanApplication = createAsyncThunk(
  "loan-application/get",
  returnLoanApplicationResponse
);

export const putLoanApplication = async ({
  wisrApplicationId,
  loanApplication,
}: SaveLoanApplication) => {
  return axios.put<any>(
    `${DASH_API}/loanApplication/${wisrApplicationId}`,
    loanApplication,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const returnPutLoanApplicationResponse = async ({
  wisrApplicationId,
  loanApplication,
}: SaveLoanApplication) => {
  if (!wisrApplicationId) return;

  try {
    const response = await putLoanApplication({
      wisrApplicationId,
      loanApplication,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.PUT_LOAN_APPLICATION
      );
      return;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error saving loan application: ${err}`,
      AppInsightsErrorType.PUT_LOAN_APPLICATION
    );
  }
};

export const saveLoanApplication = createAsyncThunk(
  "loan-application/put",
  returnPutLoanApplicationResponse
);

export const postLoanApplication = async ({
  wisrApplicationId,
  loanApplication,
}: SaveLoanApplication) => {
  return axios.post<any>(
    `${DASH_API}/loanApplication/${wisrApplicationId}`,
    loanApplication,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const returnPostLoanApplicationResponse = async ({
  wisrApplicationId,
  loanApplication,
}: SaveLoanApplication) => {
  if (!wisrApplicationId) return;

  try {
    const response = await postLoanApplication({
      wisrApplicationId,
      loanApplication,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      sendAppInsightsServerError(
        `non 200 response received: ${response}`,
        AppInsightsErrorType.POST_LOAN_APPLICATION
      );
      return response.data;
    }
  } catch (err: any) {
    sendAppInsightsServerError(
      `error submitting loan application: ${err}`,
      AppInsightsErrorType.POST_LOAN_APPLICATION
    );
    if (err.response?.data?.errorFields) return err.response.data;
  }
};

export const submitLoanApplication = createAsyncThunk(
  "loan-application/post",
  returnPostLoanApplicationResponse
);
