import {
  ApplicationFormAction,
  ApplicationClearStateActionType,
  ApplicationGetContinueTokenSuccessActionType,
  ApplicationGetSuccessActionType,
  ApplicationUpdateNSRActionType,
} from "./application-form.actions";
import { applicationFormDefaults } from "./application-form.constants";
import { isBrowser } from "@wisr/common";

/** @todo this defaut value would probably be better placed */
/* istanbul ignore next */
const continueToken = isBrowser()
  ? window.sessionStorage.getItem("ContinueToken")
  : null;
const initialState: ApplicationFormState = {
  continueToken,
  rateCardVersion: "",
  rateCardVersionSecured: "",
};
export const applicationFormReducer = (
  state = initialState,
  action: ApplicationFormAction
): ApplicationFormState => {
  switch (action.type) {
    case ApplicationGetSuccessActionType:
      return {
        ...state,
        application: action.application,
        applicationRef: action.applicationRef,
        quoteRefId: action.quoteRefId,
        creditScore: action.creditScore,
        rateCardVersion: action.rateCardVersion,
        rateCardVersionSecured: action.rateCardVersionSecured,
      };

    case ApplicationGetContinueTokenSuccessActionType:
      return {
        ...state,
        continueToken: action.continueToken,
      };

    case ApplicationClearStateActionType:
      return {
        ...state,
        application: undefined,
        continueToken: null,
        applicationRef: undefined,
      };

    case ApplicationUpdateNSRActionType:
      return {
        ...state,
        nsrOutcome: action.nsrOutcome,
        submissionContinueToken: action.submissionContinueToken,
      };

    default:
      return state;
  }
};

export interface ApplicationFormState {
  application?: typeof applicationFormDefaults;
  continueToken: string | null;
  applicationRef?: string;
  quoteRefId?: string;
  nsrOutcome?: string;
  submissionContinueToken?: string;
  creditScore?: number;
  rateCardVersion: string;
  rateCardVersionSecured: string;
}
