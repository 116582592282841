import { Epic, ofType } from "redux-observable";
import { map, tap } from "rxjs/operators";
import { setCookie } from "../auth/auth.utils";
import { userInit } from "../shared/analytics/analytics.utils";
import { noopAction } from "../shared/common.actions";
import { AppAction } from "../store";
import { UserGetSuccessActionType } from "./user.actions";

export const getUserSuccess: Epic<AppAction> = (actions) =>
  actions.pipe(
    ofType(UserGetSuccessActionType),
    tap((user) => {
      userInit(user.user);
      setCookie();
      sessionStorage.setItem("user", JSON.stringify(user.user));
    }),
    map(noopAction)
  );
