import { isBrowser } from "@wisr/common";
const DESKTOP_WIDTH = 992;
const TABLET_WIDTH = 768;

const windowWidth = () => (isBrowser() && window.innerWidth) || DESKTOP_WIDTH;

export const isBrowserMobile = () => {
  return windowWidth() < TABLET_WIDTH;
};

export const isBrowserTablet = () => {
  return windowWidth() >= TABLET_WIDTH && windowWidth() < DESKTOP_WIDTH;
};

export const isBrowserDesktop = () => {
  return windowWidth() >= DESKTOP_WIDTH;
};
