import {
  CreditUserProfile,
  CreditUserProfileUpdate,
  CreditUserStatus,
} from "./credit-user";

export const CreditUserActionTypes = {
  GET: "user/credit/get",
  GET_SUCCESS: "user/credit/get/success",
  GET_FAILED: "user/credit/get/fail",
  POST: "user/credit/post",
  UPDATE: "user/credit/update",
} as const;

export type CreditUserAction =
  | CreditUserGetAction
  | CreditUserGetSuccessAction
  | CreditUserPostAction
  | CreditUserUpdateAction;

export enum CreditUserStatusActionTypes {
  POST_SUCCESS = "user/credit/post/success",
  POST_FAILED = "user/credit/post/fail",
  UPDATE_SUCCESS = "user/credit/update/success",
  UPDATE_FAILED = "user/credit/update/fail",
  CLEAR = "user/credit/status/clear",
}

export type CreditUserStatusAction =
  | CreditUserPostSuccessAction
  | CreditUserUpdateSuccessAction
  | CreditUserPostFailAction
  | CreditUserUpdateFailAction
  | CreditUserClearStatusAction;

export type CreditUserGetAction = ReturnType<typeof creditUserGetAction>;
export function creditUserGetAction() {
  return {
    type: CreditUserActionTypes.GET,
  };
}

export const CreditUserGetSuccessActionType =
  "user/credit/get/success" as const;

export type CreditUserGetSuccessAction = ReturnType<
  typeof creditUserGetSuccessAction
>;
export function creditUserGetSuccessAction(creditUser: CreditUserProfile) {
  return {
    type: CreditUserActionTypes.GET_SUCCESS,
    creditUser,
  };
}

export type CreditUserPostAction = ReturnType<typeof creditUserPostAction>;
export function creditUserPostAction(creditUser: CreditUserProfileUpdate) {
  return {
    type: CreditUserActionTypes.POST,
    creditUser,
  };
}

export type CreditUserPostSuccessAction = ReturnType<
  typeof creditUserPostSuccessAction
>;
export function creditUserPostSuccessAction() {
  return {
    type: CreditUserStatusActionTypes.POST_SUCCESS,
    status: CreditUserStatus.SUCCESS,
  };
}

export type CreditUserPostFailAction = ReturnType<
  typeof creditUserPostFailAction
>;
export function creditUserPostFailAction() {
  return {
    type: CreditUserStatusActionTypes.POST_FAILED,
    status: CreditUserStatus.FAILED,
  };
}

export type CreditUserUpdateAction = ReturnType<typeof creditUserUpdateAction>;
export function creditUserUpdateAction(creditUser: CreditUserProfileUpdate) {
  return {
    type: CreditUserActionTypes.UPDATE,
    creditUser,
  };
}

export type CreditUserUpdateSuccessAction = ReturnType<
  typeof creditUserUpdateSuccessAction
>;
export function creditUserUpdateSuccessAction() {
  return {
    type: CreditUserStatusActionTypes.UPDATE_SUCCESS,
    status: CreditUserStatus.SUCCESS,
  };
}

export type CreditUserUpdateFailAction = ReturnType<
  typeof creditUserUpdateFailAction
>;
export function creditUserUpdateFailAction() {
  return {
    type: CreditUserStatusActionTypes.UPDATE_FAILED,
    status: CreditUserStatus.FAILED,
  };
}

export type CreditUserClearStatusAction = ReturnType<
  typeof creditUserClearStatusAction
>;
export function creditUserClearStatusAction() {
  return {
    type: CreditUserStatusActionTypes.CLEAR,
    status: null,
  };
}
