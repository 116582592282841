import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CREDIT_API } from "../../../../shared/url.consts";
import { SendVerificationCode } from "../../../../types/mobile-verification";
import { sendAppInsightsServerError } from "../../../../shared/insights/app-insights.utils";
import { AppInsightsErrorType } from "../../../../shared/insights/app-insights.consts";

export const sendVerificationCode = async ({
  verificationCode,
  mobile,
}: SendVerificationCode) => {
  return axios.post<boolean>(
    `${CREDIT_API}/CustomerAccount/VerifyCustomerMobile`,
    {
      verificationCode: verificationCode,
      mobileNumber: mobile,
    }
  );
};

export const sendVerificationCodeResponse = async ({
  verificationCode,
  mobile,
}: SendVerificationCode) => {
  try {
    const response = await sendVerificationCode({ verificationCode, mobile });
    if (response.data === true) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    sendAppInsightsServerError(
      `error sending verification code: ${err}`,
      AppInsightsErrorType.MOBILE_VERIFICATION
    );
  }
};

export const fetchMobileVerificationCode = createAsyncThunk(
  "mobile-verification-code/post",
  sendVerificationCodeResponse
);
