import { UserMetaData } from "./user";
import {
  UserAction,
  UserGetSuccessActionType,
  UserStateExpiredType,
  UserStateExpiringType,
} from "./user.actions";

export const userReducer = (
  state: UserMetaData = {} as UserMetaData,
  action: UserAction
): UserMetaData | any => {
  switch (action.type) {
    case UserGetSuccessActionType:
      return action.user;
    case UserStateExpiredType:
    case UserStateExpiringType:
      return {
        ...state,
        expired: action.expired,
      };

    default:
      return state;
  }
};
