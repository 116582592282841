import { WisrProfile } from "./wisr-profile";

export type WisrProfileActions =
  | WisrProfileGetAction
  | WisrProfileGetSuccessAction;

export const WisrProfileGetActionType = "profile/wisr/get" as const;
export type WisrProfileGetAction = ReturnType<typeof wisrProfileGetAction>;
export function wisrProfileGetAction() {
  return {
    type: WisrProfileGetActionType,
  };
}
export const WisrProfileGetSuccessActionType =
  "profile/wisr/get/success" as const;
export type WisrProfileGetSuccessAction = ReturnType<
  typeof wisrProfileGetSuccessAction
>;
export function wisrProfileGetSuccessAction(profile: WisrProfile) {
  return {
    type: WisrProfileGetSuccessActionType,
    profile,
  };
}
