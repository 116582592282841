export const initialIdentityList = {
  idenfiticationSource1: "",
  idenfiticationSource2: "",
  driverLicenceNumber: "",
  driverLicenceCardNumber: "",
  driversLicenceState: "",
  driversLicenceExpiry: "",
  medicareCardNumber: "",
  medicareInitial: "",
  medicareColour: "",
  medicareReferenceNumber: "",
  medicareExpiry: "",
  passportNumber: "",
  passportCountry: "",
};

export const defaultIncomeSourceValue = "Salary & Wages";

export const incomeSourceOptionsUpdated = [
  { value: "rental-income", label: "Rental Income" },
  {
    value: "self-employed-business-income",
    label: "Self Employed Business Income",
  },
  {
    value: "government-benefits",
    label: "Government Benefits",
  },
  {
    value: "investments-interest",
    label: "Investment Income - Interest",
  },
  {
    value: "investments-dividends",
    label: "Investment Income - Dividends",
  },
];

export const initialOtherIncomeList = {
  hasOtherIncomes: "",
  otherIncomeSource_1: "",
  otherIncomeAmount_1: "",
  otherIncomeFrequency_1: "",
  otherIncomeSource_2: "",
  otherIncomeAmount_2: "",
  otherIncomeFrequency_2: "",
  otherIncomeSource_3: "",
  otherIncomeAmount_3: "",
  otherIncomeFrequency_3: "",
};

export const initialDebtList = {
  hasDebts: "",
  financialDebtType_1: "",
  financialDebtFinanceCompany_1: "",
  financialDebtBalance_1: "",
  financialDebtRepaymentAmount_1: "",
  financialDebtRepaymentFrequency_1: "",
  financialDebtIsJointLoan_1: [] as string[],
  financialDebtConsolidate_1: [] as string[],
  financialDebtIsInvestment_1: [] as string[],
  financialDebtCreditCardLimit_1: "",
  financialDebtCloseThisCard_1: [] as string[],

  financialDebtType_2: "",
  financialDebtFinanceCompany_2: "",
  financialDebtBalance_2: "",
  financialDebtRepaymentAmount_2: "",
  financialDebtRepaymentFrequency_2: "",
  financialDebtIsJointLoan_2: [] as string[],
  financialDebtConsolidate_2: [] as string[],
  financialDebtIsInvestment_2: [] as string[],
  financialDebtCreditCardLimit_2: "",
  financialDebtCloseThisCard_2: [] as string[],

  financialDebtType_3: "",
  financialDebtFinanceCompany_3: "",
  financialDebtBalance_3: "",
  financialDebtRepaymentAmount_3: "",
  financialDebtRepaymentFrequency_3: "",
  financialDebtIsJointLoan_3: [] as string[],
  financialDebtConsolidate_3: [] as string[],
  financialDebtIsInvestment_3: [] as string[],
  financialDebtCreditCardLimit_3: "",
  financialDebtCloseThisCard_3: [] as string[],

  financialDebtType_4: "",
  financialDebtFinanceCompany_4: "",
  financialDebtBalance_4: "",
  financialDebtRepaymentAmount_4: "",
  financialDebtRepaymentFrequency_4: "",
  financialDebtIsJointLoan_4: [] as string[],
  financialDebtConsolidate_4: [] as string[],
  financialDebtIsInvestment_4: [] as string[],
  financialDebtCreditCardLimit_4: "",
  financialDebtCloseThisCard_4: [] as string[],

  financialDebtType_5: "",
  financialDebtFinanceCompany_5: "",
  financialDebtBalance_5: "",
  financialDebtRepaymentAmount_5: "",
  financialDebtRepaymentFrequency_5: "",
  financialDebtIsJointLoan_5: [] as string[],
  financialDebtConsolidate_5: [] as string[],
  financialDebtIsInvestment_5: [] as string[],
  financialDebtCreditCardLimit_5: "",
  financialDebtCloseThisCard_5: [] as string[],

  financialDebtType_6: "",
  financialDebtFinanceCompany_6: "",
  financialDebtBalance_6: "",
  financialDebtRepaymentAmount_6: "",
  financialDebtRepaymentFrequency_6: "",
  financialDebtIsJointLoan_6: [] as string[],
  financialDebtConsolidate_6: [] as string[],
  financialDebtIsInvestment_6: [] as string[],
  financialDebtCreditCardLimit_6: "",
  financialDebtCloseThisCard_6: [] as string[],

  financialDebtType_7: "",
  financialDebtFinanceCompany_7: "",
  financialDebtBalance_7: "",
  financialDebtRepaymentAmount_7: "",
  financialDebtRepaymentFrequency_7: "",
  financialDebtIsJointLoan_7: [] as string[],
  financialDebtConsolidate_7: [] as string[],
  financialDebtIsInvestment_7: [] as string[],
  financialDebtCreditCardLimit_7: "",
  financialDebtCloseThisCard_7: [] as string[],

  financialDebtType_8: "",
  financialDebtFinanceCompany_8: "",
  financialDebtBalance_8: "",
  financialDebtRepaymentAmount_8: "",
  financialDebtRepaymentFrequency_8: "",
  financialDebtIsJointLoan_8: [] as string[],
  financialDebtConsolidate_8: [] as string[],
  financialDebtIsInvestment_8: [] as string[],
  financialDebtCreditCardLimit_8: "",
  financialDebtCloseThisCard_8: [] as string[],

  financialDebtType_9: "",
  financialDebtFinanceCompany_9: "",
  financialDebtBalance_9: "",
  financialDebtRepaymentAmount_9: "",
  financialDebtRepaymentFrequency_9: "",
  financialDebtIsJointLoan_9: [] as string[],
  financialDebtConsolidate_9: [] as string[],
  financialDebtIsInvestment_9: [] as string[],
  financialDebtCreditCardLimit_9: "",
  financialDebtCloseThisCard_9: [] as string[],

  financialDebtType_10: "",
  financialDebtFinanceCompany_10: "",
  financialDebtBalance_10: "",
  financialDebtRepaymentAmount_10: "",
  financialDebtRepaymentFrequency_10: "",
  financialDebtIsJointLoan_10: [] as string[],
  financialDebtConsolidate_10: [] as string[],
  financialDebtIsInvestment_10: [] as string[],
  financialDebtCreditCardLimit_10: "",
  financialDebtCloseThisCard_10: [] as string[],
};

export const significantChangeValues = [
  {
    value: "expenseIncreaseShortTerm",
    formValue: "short_term_increase_in_expenses",
  },
  {
    value: "expenseIncreaseLongTerm",
    formValue: "long_term_increase_in_expenses",
  },
  {
    value: "incomeDecreaseShortTerm",
    formValue: "short_term_decrease_in_income",
  },
  {
    value: "incomeDecreaseLongTerm",
    formValue: "long_term_decrease_in_income",
  },
];

export const validLoanTerms = ["3", "5", "7"];

export const CONSOLIDATION_PURPOSES = [
  "consolidation",
  "consolidation-debtbustr",
];
