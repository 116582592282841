import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  BrokerDetailsState,
  BrokerDetailsStore,
} from "../../types/broker-details";
import { fetchBrokerDetails } from "./broker-details.thunk";

const initialBrokerDetailsState: BrokerDetailsState = {
  status: FetchStatus.IDLE,
};

export const brokerDetailsSlice = createSlice({
  name: "brokerDetails",
  initialState: initialBrokerDetailsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBrokerDetails.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchBrokerDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.details = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "broker details get failed";
        }
      })
      .addCase(fetchBrokerDetails.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export const selectBrokerDetailsState = (store: BrokerDetailsStore) =>
  store.brokerDetailsState;

export default brokerDetailsSlice.reducer;
