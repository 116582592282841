type DebtType =
  | "financialDebtType_1"
  | "financialDebtType_2"
  | "financialDebtType_3"
  | "financialDebtType_4"
  | "financialDebtType_5"
  | "financialDebtType_6"
  | "financialDebtType_7"
  | "financialDebtType_8"
  | "financialDebtType_9"
  | "financialDebtType_10";

type FinanceCompany =
  | "financialDebtFinanceCompany_1"
  | "financialDebtFinanceCompany_2"
  | "financialDebtFinanceCompany_3"
  | "financialDebtFinanceCompany_4"
  | "financialDebtFinanceCompany_5"
  | "financialDebtFinanceCompany_6"
  | "financialDebtFinanceCompany_7"
  | "financialDebtFinanceCompany_8"
  | "financialDebtFinanceCompany_9"
  | "financialDebtFinanceCompany_10";

type Balance =
  | "financialDebtBalance_1"
  | "financialDebtBalance_2"
  | "financialDebtBalance_3"
  | "financialDebtBalance_4"
  | "financialDebtBalance_5"
  | "financialDebtBalance_6"
  | "financialDebtBalance_7"
  | "financialDebtBalance_8"
  | "financialDebtBalance_9"
  | "financialDebtBalance_10";

type RepaymentAmount =
  | "financialDebtRepaymentAmount_1"
  | "financialDebtRepaymentAmount_2"
  | "financialDebtRepaymentAmount_3"
  | "financialDebtRepaymentAmount_4"
  | "financialDebtRepaymentAmount_5"
  | "financialDebtRepaymentAmount_6"
  | "financialDebtRepaymentAmount_7"
  | "financialDebtRepaymentAmount_8"
  | "financialDebtRepaymentAmount_9"
  | "financialDebtRepaymentAmount_10";

type RepaymentFrequency =
  | "financialDebtRepaymentFrequency_1"
  | "financialDebtRepaymentFrequency_2"
  | "financialDebtRepaymentFrequency_3"
  | "financialDebtRepaymentFrequency_4"
  | "financialDebtRepaymentFrequency_5"
  | "financialDebtRepaymentFrequency_6"
  | "financialDebtRepaymentFrequency_7"
  | "financialDebtRepaymentFrequency_8"
  | "financialDebtRepaymentFrequency_9"
  | "financialDebtRepaymentFrequency_10";

type IsJointLoan =
  | "financialDebtIsJointLoan_1"
  | "financialDebtIsJointLoan_2"
  | "financialDebtIsJointLoan_3"
  | "financialDebtIsJointLoan_4"
  | "financialDebtIsJointLoan_5"
  | "financialDebtIsJointLoan_6"
  | "financialDebtIsJointLoan_7"
  | "financialDebtIsJointLoan_8"
  | "financialDebtIsJointLoan_9"
  | "financialDebtIsJointLoan_10";

type DebtConsolidate =
  | "financialDebtConsolidate_1"
  | "financialDebtConsolidate_2"
  | "financialDebtConsolidate_3"
  | "financialDebtConsolidate_4"
  | "financialDebtConsolidate_5"
  | "financialDebtConsolidate_6"
  | "financialDebtConsolidate_7"
  | "financialDebtConsolidate_8"
  | "financialDebtConsolidate_9"
  | "financialDebtConsolidate_10";

type DebtIsInvestment =
  | "financialDebtIsInvestment_1"
  | "financialDebtIsInvestment_2"
  | "financialDebtIsInvestment_3"
  | "financialDebtIsInvestment_4"
  | "financialDebtIsInvestment_5"
  | "financialDebtIsInvestment_6"
  | "financialDebtIsInvestment_7"
  | "financialDebtIsInvestment_8"
  | "financialDebtIsInvestment_9"
  | "financialDebtIsInvestment_10";

type CreditCardLimit =
  | "financialDebtCreditCardLimit_1"
  | "financialDebtCreditCardLimit_2"
  | "financialDebtCreditCardLimit_3"
  | "financialDebtCreditCardLimit_4"
  | "financialDebtCreditCardLimit_5"
  | "financialDebtCreditCardLimit_6"
  | "financialDebtCreditCardLimit_7"
  | "financialDebtCreditCardLimit_8"
  | "financialDebtCreditCardLimit_9"
  | "financialDebtCreditCardLimit_10";

type CloseThisCard =
  | "financialDebtCloseThisCard_1"
  | "financialDebtCloseThisCard_2"
  | "financialDebtCloseThisCard_3"
  | "financialDebtCloseThisCard_4"
  | "financialDebtCloseThisCard_5"
  | "financialDebtCloseThisCard_6"
  | "financialDebtCloseThisCard_7"
  | "financialDebtCloseThisCard_8"
  | "financialDebtCloseThisCard_9"
  | "financialDebtCloseThisCard_10";

export interface DebtListItem {
  id: number;
  active: boolean;
  type: DebtType;
  financeCompany: FinanceCompany;
  balance: Balance;
  repaymentAmount: RepaymentAmount;
  repaymentFrequency: RepaymentFrequency;
  creditCardLimit: CreditCardLimit;
  isJointLoan: IsJointLoan;
  consolidate: DebtConsolidate;
  isInvestment: DebtIsInvestment;
  closeThisCard: CloseThisCard;
  selectChanged: boolean;
}
export const CREDIT_CARD_VALUE = "credit-card";
export const PERSONAL_LOAN_VALUE = "personal-loan";
export const MORTGAGE_VALUE = "mortgage";

export const debtTypeOptions = [
  { label: "Credit card / overdraft / store card", value: CREDIT_CARD_VALUE },
  { label: "Personal loan", value: PERSONAL_LOAN_VALUE },
  { label: "Mortgage", value: MORTGAGE_VALUE },
];

export const debtInitialList: DebtListItem[] = [
  {
    id: 1,
    active: true,
    type: `financialDebtType_1`,
    financeCompany: `financialDebtFinanceCompany_1`,
    balance: `financialDebtBalance_1`,
    repaymentAmount: `financialDebtRepaymentAmount_1`,
    repaymentFrequency: `financialDebtRepaymentFrequency_1`,
    creditCardLimit: "financialDebtCreditCardLimit_1",
    isJointLoan: "financialDebtIsJointLoan_1",
    consolidate: "financialDebtConsolidate_1",
    isInvestment: "financialDebtIsInvestment_1",
    closeThisCard: "financialDebtCloseThisCard_1",
    selectChanged: true,
  },
  {
    id: 2,
    active: false,
    type: `financialDebtType_2`,
    financeCompany: `financialDebtFinanceCompany_2`,
    balance: `financialDebtBalance_2`,
    repaymentAmount: `financialDebtRepaymentAmount_2`,
    repaymentFrequency: `financialDebtRepaymentFrequency_2`,
    creditCardLimit: "financialDebtCreditCardLimit_2",
    isJointLoan: "financialDebtIsJointLoan_2",
    consolidate: "financialDebtConsolidate_2",
    isInvestment: "financialDebtIsInvestment_2",
    closeThisCard: "financialDebtCloseThisCard_2",
    selectChanged: true,
  },
  {
    id: 3,
    active: false,
    type: `financialDebtType_3`,
    financeCompany: `financialDebtFinanceCompany_3`,
    balance: `financialDebtBalance_3`,
    repaymentAmount: `financialDebtRepaymentAmount_3`,
    repaymentFrequency: `financialDebtRepaymentFrequency_3`,
    isJointLoan: "financialDebtIsJointLoan_3",
    consolidate: "financialDebtConsolidate_3",
    isInvestment: "financialDebtIsInvestment_3",
    creditCardLimit: "financialDebtCreditCardLimit_3",
    closeThisCard: "financialDebtCloseThisCard_3",
    selectChanged: true,
  },
  {
    id: 4,
    active: false,
    type: `financialDebtType_4`,
    financeCompany: `financialDebtFinanceCompany_4`,
    balance: `financialDebtBalance_4`,
    repaymentAmount: `financialDebtRepaymentAmount_4`,
    repaymentFrequency: `financialDebtRepaymentFrequency_4`,
    isJointLoan: "financialDebtIsJointLoan_4",
    consolidate: "financialDebtConsolidate_4",
    isInvestment: "financialDebtIsInvestment_4",
    creditCardLimit: "financialDebtCreditCardLimit_4",
    closeThisCard: "financialDebtCloseThisCard_4",
    selectChanged: true,
  },
  {
    id: 5,
    active: false,
    type: `financialDebtType_5`,
    financeCompany: `financialDebtFinanceCompany_5`,
    balance: `financialDebtBalance_5`,
    repaymentAmount: `financialDebtRepaymentAmount_5`,
    repaymentFrequency: `financialDebtRepaymentFrequency_5`,
    isJointLoan: "financialDebtIsJointLoan_5",
    consolidate: "financialDebtConsolidate_5",
    isInvestment: "financialDebtIsInvestment_5",
    creditCardLimit: "financialDebtCreditCardLimit_5",
    closeThisCard: "financialDebtCloseThisCard_5",
    selectChanged: true,
  },
  {
    id: 6,
    active: false,
    type: `financialDebtType_6`,
    financeCompany: `financialDebtFinanceCompany_6`,
    balance: `financialDebtBalance_6`,
    repaymentAmount: `financialDebtRepaymentAmount_6`,
    repaymentFrequency: `financialDebtRepaymentFrequency_6`,
    isJointLoan: "financialDebtIsJointLoan_6",
    consolidate: "financialDebtConsolidate_6",
    isInvestment: "financialDebtIsInvestment_6",
    creditCardLimit: "financialDebtCreditCardLimit_6",
    closeThisCard: "financialDebtCloseThisCard_6",
    selectChanged: true,
  },
  {
    id: 7,
    active: false,
    type: `financialDebtType_7`,
    financeCompany: `financialDebtFinanceCompany_7`,
    balance: `financialDebtBalance_7`,
    repaymentAmount: `financialDebtRepaymentAmount_7`,
    repaymentFrequency: `financialDebtRepaymentFrequency_7`,
    isJointLoan: "financialDebtIsJointLoan_7",
    consolidate: "financialDebtConsolidate_7",
    isInvestment: "financialDebtIsInvestment_7",
    creditCardLimit: "financialDebtCreditCardLimit_7",
    closeThisCard: "financialDebtCloseThisCard_7",
    selectChanged: true,
  },
  {
    id: 8,
    active: false,
    type: `financialDebtType_8`,
    financeCompany: `financialDebtFinanceCompany_8`,
    balance: `financialDebtBalance_8`,
    repaymentAmount: `financialDebtRepaymentAmount_8`,
    repaymentFrequency: `financialDebtRepaymentFrequency_8`,
    isJointLoan: "financialDebtIsJointLoan_8",
    consolidate: "financialDebtConsolidate_8",
    isInvestment: "financialDebtIsInvestment_8",
    creditCardLimit: "financialDebtCreditCardLimit_8",
    closeThisCard: "financialDebtCloseThisCard_8",
    selectChanged: true,
  },
  {
    id: 9,
    active: false,
    type: `financialDebtType_9`,
    financeCompany: `financialDebtFinanceCompany_9`,
    balance: `financialDebtBalance_9`,
    repaymentAmount: `financialDebtRepaymentAmount_9`,
    repaymentFrequency: `financialDebtRepaymentFrequency_9`,
    isJointLoan: "financialDebtIsJointLoan_9",
    consolidate: "financialDebtConsolidate_9",
    isInvestment: "financialDebtIsInvestment_9",
    creditCardLimit: "financialDebtCreditCardLimit_9",
    closeThisCard: "financialDebtCloseThisCard_9",
    selectChanged: true,
  },
  {
    id: 10,
    active: false,
    type: `financialDebtType_10`,
    financeCompany: `financialDebtFinanceCompany_10`,
    balance: `financialDebtBalance_10`,
    repaymentAmount: `financialDebtRepaymentAmount_10`,
    repaymentFrequency: `financialDebtRepaymentFrequency_10`,
    isJointLoan: "financialDebtIsJointLoan_10",
    consolidate: "financialDebtConsolidate_10",
    isInvestment: "financialDebtIsInvestment_10",
    creditCardLimit: "financialDebtCreditCardLimit_10",
    closeThisCard: "financialDebtCloseThisCard_10",
    selectChanged: true,
  },
];
