import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../shared/redux.consts";
import {
  PartnerReferralState,
  PartnerReferralStore,
} from "../../types/partner-referral";
import { submitForPartnerReferral } from "./partner-referral-widget.thunk";

const initialPartnerReferralState: PartnerReferralState = {
  status: FetchStatus.IDLE,
};

export const partnerReferralSlice = createSlice({
  name: "partnerReferral",
  initialState: initialPartnerReferralState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(submitForPartnerReferral.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(submitForPartnerReferral.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.partnerReferralResponse = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "estimate referral post failed";
        }
      })
      .addCase(submitForPartnerReferral.rejected, (state, action) => {
        if (action.error?.message !== undefined) {
          state.status = FetchStatus.FAILED;
          state.error = action.error.message;
        }
      });
  },
});

export default partnerReferralSlice.reducer;

export const selectpartnerReferralState = (store: PartnerReferralStore) =>
  store.partnerReferralState;
