import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../../../shared/redux.consts";
import {
  InitialState,
  PersonalisedRateEstimateStore,
} from "../../../../types/personalised-rate-estimate";
import { fetchPersonalisedRateEstimate } from "./personalised-rate-estimate-widget.thunk";

const initialState: InitialState = {
  personalisedRateEstimateCopyList: [],
  status: FetchStatus.IDLE,
  error: "",
};

export const personalisedRateEstimateSlice = createSlice({
  name: "personalisedRateEstimate",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPersonalisedRateEstimate.pending, (state) => {
        state.status = FetchStatus.LOADING;
      })
      .addCase(fetchPersonalisedRateEstimate.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = FetchStatus.SUCCESS;
          state.personalisedRateEstimateCopyList = action.payload;
        } else {
          state.status = FetchStatus.FAILED;
          state.error = "data is undefined";
        }
      })
      .addCase(fetchPersonalisedRateEstimate.rejected, (state, action) => {
        state.status = FetchStatus.FAILED;
        if (action.error?.message !== undefined) {
          state.error = action.error.message;
        }
      });
  },
});

export default personalisedRateEstimateSlice.reducer;

export const selectPersonalisedRateEstimate = (
  store: PersonalisedRateEstimateStore
) => store.personalisedRateEstimateState.personalisedRateEstimateCopyList;

export const selectPersonalisedRateEstimateStatus = (
  store: PersonalisedRateEstimateStore
) => store.personalisedRateEstimateState.status;
