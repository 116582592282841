import { useSelector } from "react-redux";
import { AppStore } from "../../store";
import { ClientIdState } from "../../types/auth";

export const useGetClientId = () => {
  return useSelector<AppStore, ClientIdState>((store) => store.clientIdState);
};

export const useGetIsAppUser = () => {
  return useSelector<AppStore, boolean>(
    (store) => store.clientIdState.clientId === "wisr_roundupapp"
  );
};
